import { GradientText, NewBubble } from "@/comps/bubble"
import Button from "@/comps/button"
import { DEFORUM_STORAGE_KEY } from "@/comps/editors/text-to-image"
import Image, { CustomThemedResource } from "@/comps/image"
import {
	appStoreConstant,
	playStoreConstant,
} from "@/utils/branch"
import { devices } from "@/utils/breakpoints"
import { assetUrl } from "@/utils/cdn"
import useAuth from "@/utils/client-auth"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import { useRouter } from "next/router"
import { GalleryEntryType } from "pages/ai-gallery"
import { backgroundGradient } from "pages/models"
import { useEffect, useState } from "react"
import { useEditorParamsContext } from "sections/editor/editor"
import {
	GalleryPopup,
	GalleryPopupProps,
} from "sections/gallery/gallery-popup"
import styled, { css, keyframes } from "styled-components"
import { z } from "zod"
import Runner from "../platforms/runner"
import QR from "./qr"

const StyledPlatformContainer = styled.a`
	&:hover {
		background: linear-gradient(
			60deg,
			rgba(134, 54, 248, 1),
			rgba(240, 32, 179, 1),
			rgba(248, 71, 94, 1),
			rgba(255, 148, 33, 1)
		);
	}

	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;

	background: linear-gradient(
		60deg,
		var(--color-separator),
		var(--color-separator)
	);
	padding: 1.5px;
	border-radius: 8px;
`

export interface CoverProps {
	columns: (
		| {
				data: GalleryEntryType
				type: "gallery"
		  }
		| {
				data: TemplateItem
				type: "template"
		  }
	)[][]
}

export default function NewCover(props: CoverProps) {
	const { columns } = props

	const [galleryPopupState, setGalleryPopupState] =
		useState<GalleryPopupProps | null>(null)

	useEffect(() => {
		if (galleryPopupState !== null) {
			document.body.style.overflow = "hidden"
		} else {
			document.body.style.overflow = "unset"
		}
	}, [galleryPopupState])

	return (
		<div className="my-[26px] flex h-full w-full flex-col items-center gap-[42px] tablet:my-[50px]">
			<div className=" relative flex w-full flex-col items-center px-[16px]">
				<div className="z-10 flex flex-col items-center px-[16px] desktop:px-[69px]">
					<NewBubble
						text="#1 Ai Video Maker"
						uppercase
						wideLetter
						shaddow
					/>
					<div className="mt-6 flex flex-row flex-wrap items-center justify-center">
						<span className="text-[24px] font-800 text-blue-900 desktop:text-[40px]">
							{"AI-Powered Videos"}
						</span>
						{/* Space between texts */}
						<span
							dangerouslySetInnerHTML={{
								__html: "&nbsp;&nbsp;",
							}}
						/>
						<video
							autoPlay
							loop
							muted
							playsInline
							src={assetUrl("/home/home_small.mp4")}
							className="pointer-events-none h-[38px] w-[68px] select-none rounded-[10px] object-cover desktop:h-[54px] desktop:w-[87px]"
						/>
						{/* Space between texts */}
						<span
							dangerouslySetInnerHTML={{
								__html: "&nbsp;&nbsp;",
							}}
						/>
						<GradientText
							text="with no limits,"
							className="select-none text-[24px] font-800 desktop:text-[40px]"
						/>
						{/* Space between texts */}
						<span
							dangerouslySetInnerHTML={{
								__html: "&nbsp;",
							}}
						/>
						<span className="text-[24px] font-800 text-blue-900 desktop:text-[40px]">
							{"Just Creativity"}
						</span>
					</div>

					<h3 className="mt-2 text-[18px] font-400 text-blue-800 desktop:text-[22px]">
						{"From zero to video hero, thanks to AI"}
					</h3>
				</div>

				<Button
					href="/login"
					text="Get started for free"
					className="mt-10 flex h-[56px] !w-[256px] !max-w-none items-center justify-center rounded-lg"
					primary
				/>

				<div className="z-10 mt-10 flex flex-row gap-9">
					<CustomThemedResource
						source="/home/best-of-platform"
						format="svg"
						alt="ratings icon"
						className=" w-[146px] tablet:w-[180px]"
					/>

					<CustomThemedResource
						source="/home/best-for-downloads"
						format="svg"
						alt="downloads icon"
						className=" w-[146px] tablet:w-[180px]"
					/>
				</div>
				<div className="relative z-10 mt-11 flex items-center gap-[9.5px] tablet:gap-[13.5px]">
					<StyledPlatformContainer
						href="/login"
						target="_blank"
						rel="noopener noreferrer">
						<div className="rounded-[7px] bg-color-cell">
							<CustomThemedResource
								source="/home/web"
								format="svg"
								className="h-[31px] rounded-[7px] bg-color-cell tablet:h-[45px]"
							/>
						</div>
					</StyledPlatformContainer>
					<div
						className={clsx(
							"group relative flex items-center gap-[9.5px] tablet:gap-[13.5px]",
						)}>
						<StyledPlatformContainer
							href={appStoreConstant}
							target="_blank"
							rel="noopener noreferrer">
							<div className="rounded-[7px] bg-color-cell hover:bg-color-cell">
								<CustomThemedResource
									source={"/home/app-store"}
									format="svg"
									className="h-[31px] tablet:h-[45px]"
								/>
							</div>
						</StyledPlatformContainer>

						<div
							className={clsx(
								"absolute left-1/2 z-50 w-[134px] -translate-x-1/2 border border-color-separator bg-color-surface-cell p-[6px] group-hover:opacity-100",
								"-translate-y-2 rounded-[10px] opacity-0 transition-all duration-500 group-hover:translate-y-4",
								"pointer-events-none top-full hidden group-hover:pointer-events-auto tablet:block desktop:block",
							)}>
							<Image
								src={assetUrl("/home/qrcode.webp")}
								width={360}
								height={360}
							/>
						</div>
					</div>

					<div
						className={clsx(
							"group relative flex items-center gap-[9.5px] tablet:gap-[13.5px]",
						)}>
						<StyledPlatformContainer
							href={playStoreConstant}
							target="_blank"
							rel="noopener noreferrer">
							<div className="rounded-[7px] bg-color-cell hover:bg-color-cell">
								<CustomThemedResource
									source={"/home/android"}
									format="svg"
									className="h-[31px] tablet:h-[45px]"
								/>
							</div>
						</StyledPlatformContainer>

						<div
							className={clsx(
								"absolute left-1/2 z-50 w-[134px] -translate-x-1/2 border border-color-separator bg-color-surface-cell p-[6px] group-hover:opacity-100",
								"-translate-y-2 rounded-[10px] opacity-0 transition-all duration-500 group-hover:translate-y-4",
								"pointer-events-none top-full hidden group-hover:pointer-events-auto tablet:block desktop:block",
							)}>
							<Image
								src={assetUrl("/home/qrcode.webp")}
								width={360}
								height={360}
							/>
						</div>
					</div>
				</div>

				<div className="relative flex w-screen items-center justify-center">
					<div className="pointer-events-none absolute -top-[15%] left-0 w-full select-none overflow-x-clip tablet:-top-[40%] desktop:-top-[60%]">
						<CustomThemedResource
							source="/home/cover-new"
							alt="cover background image"
							format="webp"
							className="h-[300px] w-full scale-[2.5] object-contain opacity-80 tablet:h-[500px] tablet:scale-150 desktop:h-[900px] desktop:scale-150 desktop:opacity-50 desktop:backdrop-blur-3xl"
						/>
					</div>
					<GridColumns
						setGalleryPopupState={setGalleryPopupState}
						columns={columns}
						shouldPlay={!Boolean(galleryPopupState)}
					/>
				</div>
			</div>

			<div className="flex w-full justify-center px-[16px]">
				<Button
					text={"Discover templates"}
					href="/video-templates"
					primary
					className="flex h-[56px] !w-[256px] !max-w-none items-center justify-center rounded-lg !p-0"
				/>
			</div>

			{galleryPopupState !== null && (
				<GalleryPopup {...galleryPopupState} />
			)}
		</div>
	)
}

export function Cover(props: CoverProps) {
	const { columns } = props

	const { t } = useTranslation("common")
	const [galleryPopupState, setGalleryPopupState] =
		useState<GalleryPopupProps | null>(null)
	const router = useRouter()

	useEffect(() => {
		if (galleryPopupState !== null) {
			document.body.style.overflow = "hidden"
		} else {
			document.body.style.overflow = "unset"
		}
	}, [galleryPopupState])

	return (
		<div className="flex h-full w-full flex-col items-center gap-[42px]">
			<div className="relative flex h-[930px] w-full bg-[inherit] px-[16px] tablet:h-[900px] desktop:h-[1050px]">
				<div
					className={clsx(
						backgroundGradient,
						"absolute left-0 top-0 z-0 h-[462px] w-full rounded-b-[16px] tablet:h-[531px] desktop:h-[736px]",
					)}></div>

				<div className="absolute left-0 z-10 flex w-full flex-col items-center">
					<div className="flex flex-col items-center gap-[22px] px-[16px] pt-[26px] tablet:pt-[50px] desktop:px-[69px]">
						<h1
							className={clsx(
								"rounded-[38px] px-[15px] py-[6px] text-[10px] font-600 text-[#9F54B2]",
								"-mb-[10px] bg-gradient-to-r from-[#9f54b2]/30 to-[#9e77c5]/30",
							)}>
							{t("txt_header_seo")}
						</h1>
						<h2 className="w-[335px] text-center text-[24px] font-800 text-blue-900 tablet:w-full tablet:text-[40px] desktop:text-[48px]">
							{t("txt_header_losung")}
						</h2>
						<p
							className={clsx(
								"max-w-[494px] text-center text-[16px] font-400 text-blue-700",
								"tablet:text-[22px] tablet:font-500",
							)}>
							{t("txt_header_desk")}
						</p>
					</div>

					<div className="flex flex-col items-center gap-[26px] pt-[26px]">
						<CustomThemedResource
							source="/home/best-for-platform"
							format="svg"
							alt="best for ai icon"
						/>
						<div className="flex items-center gap-[9.5px] tablet:gap-[13.5px]">
							<div className="rounded-[7px] bg-color-cell">
								<CustomThemedResource
									source="/icons/home/cover/web-new"
									format="svg"
									className="h-[31px] tablet:h-[45px]"
								/>
							</div>
							<div
								className={clsx(
									"group relative flex items-center gap-[9.5px] tablet:gap-[13.5px]",
								)}>
								<div
									className="rounded-[7px] bg-color-cell hover:bg-color-separator desktop:hover:bg-color-cell"
									onClick={() => {
										if (window.innerWidth >= 768) {
											return
										}
										router.push(appStoreConstant)
									}}>
									<CustomThemedResource
										source={"/icons/home/cover/app-store"}
										format="svg"
										className="h-[31px] tablet:h-[45px]"
									/>
								</div>
								<div
									className="rounded-[7px] bg-color-cell hover:bg-color-separator desktop:hover:bg-color-cell"
									onClick={() => {
										if (window.innerWidth >= 768) {
											return
										}
										router.push(appStoreConstant)
									}}>
									<CustomThemedResource
										source={"/icons/home/cover/google-play"}
										format="svg"
										className="h-[31px] tablet:h-[45px]"
									/>
								</div>
								<div
									className={clsx(
										"absolute left-[80px] z-40 -translate-x-1/2 group-hover:opacity-100",
										"-translate-y-2 opacity-0 transition-all duration-500 group-hover:translate-y-4",
										"pointer-events-none top-full hidden group-hover:pointer-events-auto tablet:block desktop:block",
									)}>
									<QR />
								</div>
							</div>
						</div>
					</div>

					<GridColumns
						setGalleryPopupState={setGalleryPopupState}
						columns={columns}
						shouldPlay={!Boolean(galleryPopupState)}
					/>
				</div>
			</div>

			<div className="flex w-full justify-center px-[16px]">
				<Button
					text={t("txt_discover_more")}
					href="/ai-gallery"
					primary
					className="w-full max-w-[495px] desktop:w-[243px]"
				/>
			</div>

			<Runner />

			{galleryPopupState !== null && (
				<GalleryPopup {...galleryPopupState} />
			)}
		</div>
	)
}

interface GridColumnsProps {
	setGalleryPopupState: (
		v: GalleryPopupProps | null,
	) => void
	columns: (
		| {
				data: GalleryEntryType
				type: "gallery"
		  }
		| {
				data: TemplateItem
				type: "template"
		  }
	)[][]
	shouldPlay: boolean
}

function GridColumns(props: GridColumnsProps) {
	const { setGalleryPopupState, columns, shouldPlay } =
		props
	const editor = useEditorParamsContext()
	const { userInfo } = useAuth()

	async function handleRetry(
		promptType: string | null,
		text: string | null,
	) {
		if (userInfo.isAnonymous) {
			return
		}

		editor.openPage({
			tool: "text_to_image",
			id: DEFORUM_STORAGE_KEY,
			promptType,
			text: text ?? "",
			location: "remote",
			url: null,
		})
	}

	function galleryPopupSeter(id: number) {
		columns.filter((column) => {
			column.filter((single) => {
				if (
					single.type === "gallery" &&
					single.data.id === id
				) {
					const { data } = single
					setGalleryPopupState({
						imageOrVideo: data.object_urls.map(
							(image) => image.object,
						),
						previewImage: data.object_urls[0].thumb,
						id: id,
						tool: "text_to_image",
						createdAt: 1,
						prompt: data.prompt ?? "",
						onClose: () => {
							setGalleryPopupState(null)
						},
						showFor: false,
						notShallow: true,
						privacy: "private",
						customPrompt: data.prompt ?? "",
						promptName: "",
						handleRetry: () =>
							handleRetry(data.style, data.prompt),
						closeLink: `/`,
						username: data.owner.username,
						followers: data.owner.followers,
						profile_pic: data.owner.profile_pic?.original,
						ownerId: data.owner.uid,
						likes: single.data.likes,
						views: single.data.views,
						showRelated: true,
					})
				}
			})
		})
	}

	return (
		<div
			className={clsx(
				"mt-[36px] h-[555px] overflow-hidden rounded-[18px]",
				"relative w-[341px] tablet:h-[460px] tablet:w-[728px] desktop:h-[630px] desktop:w-[1176px]",
				"border border-color-separator bg-color-cell",
			)}>
			<div
				className={clsx(
					"absolute grid grid-cols-2 gap-[17px] px-[16px] tablet:hidden",
				)}>
				{columns.slice(0, 2).map((column, index) => {
					return (
						<RenderColumn
							key={index}
							column={column}
							galleyPopupSeter={galleryPopupSeter}
							shouldPlay={shouldPlay}
							columnIndex={index + 1}
						/>
					)
				})}
			</div>
			<div
				className={clsx(
					"absolute hidden desktop:grid desktop:grid-cols-5 desktop:gap-[28px] desktop:px-[44px]",
				)}>
				{columns.map((column, index) => {
					return (
						<RenderColumn
							key={index}
							column={column}
							galleyPopupSeter={galleryPopupSeter}
							shouldPlay={shouldPlay}
							columnIndex={index + 1}
						/>
					)
				})}
			</div>
			<div
				className={clsx(
					"absolute hidden gap-[17px] px-[16px] tablet:grid tablet:grid-cols-4 desktop:hidden",
				)}>
				{columns.slice(0, 4).map((column, index) => {
					return (
						<RenderColumn
							key={index}
							column={column}
							galleyPopupSeter={galleryPopupSeter}
							shouldPlay={shouldPlay}
							columnIndex={index + 1}
						/>
					)
				})}
			</div>
		</div>
	)
}

interface ColumnProps {
	column: (
		| { type: "gallery"; data: GalleryEntryType }
		| { type: "template"; data: TemplateItem }
	)[]
	galleyPopupSeter: (id: number) => void
	shouldPlay: boolean
	columnIndex: number
}

const createRunnerAnim = (distance: number) => keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-${distance}px);
  }
`

const COLUMN_LENGTH = 5

const runnerAnim = createRunnerAnim(
	COLUMN_LENGTH * 224 + COLUMN_LENGTH * 12,
)
const runnerAnimTablet = createRunnerAnim(
	COLUMN_LENGTH * 238 + COLUMN_LENGTH * 12,
)
const runnerAnimDesktop = createRunnerAnim(
	COLUMN_LENGTH * 299 + COLUMN_LENGTH * 16,
)

const ColumnStyleDiv = styled.div<{
	shouldPlay: boolean
	columnIndex: number
}>`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
	@media ${devices.desktop} {
		gap: 16px;
	}

	${(props) =>
		props.shouldPlay
			? css`
					animation: ${runnerAnim}
						${15 * (props.columnIndex * 3)}s linear -0s infinite
						normal forwards;

					@media ${devices.tablet} {
						animation: ${runnerAnimTablet}
							${30 + 15 * (props.columnIndex % 3)}s linear -0s
							infinite normal forwards;
					}

					@media ${devices.desktop} {
						animation: ${runnerAnimDesktop}
							${(30 + 5 * (props.columnIndex % 3)) * 1.5}s
							linear -0s infinite normal forwards;
					}
			  `
			: css`
					animation-play-state: paused;
			  `}

	&:hover {
		animation-play-state: paused;
	}
`

function RenderColumn(props: ColumnProps) {
	const {
		column,
		galleyPopupSeter,
		shouldPlay,
		columnIndex,
	} = props

	return (
		<ColumnStyleDiv
			shouldPlay={shouldPlay}
			columnIndex={columnIndex}>
			{column.map((single) => {
				switch (single.type) {
					case "gallery":
						return (
							<button
								key={single.data.id}
								className={clsx(
									"flex rounded-[8px] transition-transform duration-300",
									"bg-color-skeleton hover:scale-105 hover:rounded-[4px]",
								)}
								onClick={() =>
									galleyPopupSeter(single.data.id)
								}>
								<img
									src={single.data.object_urls[0].thumb}
									className={clsx(
										"h-[224px] w-[146px] tablet:h-[238px] tablet:w-[165px]",
										"desktop:h-[299px] desktop:w-[195px]",
										"rounded-[7px] object-cover",
									)}
									alt={`Image-${single.data.id}`}
								/>
							</button>
						)
					case "template":
						return (
							<Link
								key={`${single.data.id}-duplicate`}
								href={`/template/${single.data.id}`}>
								<a
									className={clsx(
										"flex rounded-[8px] bg-color-skeleton transition-transform",
										"duration-300 hover:scale-105 hover:rounded-[4px]",
									)}>
									<video
										src={assetUrl(
											`/template-videos/templates/${single.data.id}.mp4#t=0.001`,
										)}
										playsInline
										poster={single.data.image}
										loop
										autoPlay={shouldPlay}
										preload="none"
										muted
										className={clsx(
											"h-[224px] w-[146px] tablet:h-[238px] tablet:w-[165px]",
											"desktop:h-[299px] desktop:w-[195px]",
											"rounded-[7px] object-cover",
										)}
									/>
								</a>
							</Link>
						)
				}
			})}
			{column.map((single) => {
				switch (single.type) {
					case "gallery":
						return (
							<button
								className={clsx(
									"flex rounded-[8px] transition-transform duration-300",
									"bg-color-skeleton hover:scale-105 hover:rounded-[4px]",
								)}
								onClick={() =>
									galleyPopupSeter(single.data.id)
								}
								key={`${single.data.id}-duplicate`}>
								<img
									src={single.data.object_urls[0].thumb}
									className={clsx(
										"h-[224px] w-[146px] tablet:h-[238px] tablet:w-[165px]",
										"desktop:h-[299px] desktop:w-[195px]",
										"rounded-[7px] object-cover",
									)}
									alt={`Image-${single.data.id}`}
								/>
							</button>
						)
					case "template":
						return (
							<Link
								key={`${single.data.id}-duplicate`}
								href={`/template/${single.data.id}`}>
								<a
									className={clsx(
										"flex rounded-[8px] transition-transform",
										"bg-color-skeleton duration-300 hover:scale-105 hover:rounded-[4px]",
									)}>
									<video
										src={assetUrl(
											`/template-videos/templates/${single.data.id}.mp4#t=0.001`,
										)}
										playsInline
										loop
										poster={single.data.image}
										autoPlay={shouldPlay}
										preload="none"
										muted
										className={clsx(
											"h-[224px] w-[146px] tablet:h-[238px] tablet:w-[165px]",
											"desktop:h-[299px] desktop:w-[195px]",
											"rounded-[7px] object-cover",
										)}
									/>
								</a>
							</Link>
						)
				}
			})}
		</ColumnStyleDiv>
	)
}

export const templateItemSchema = z.object({
	id: z.string(),
	name: z.string(),
	description: z.string(),
	video: z.string(),
	image: z.string(),
})

export type TemplateItem = z.infer<
	typeof templateItemSchema
>
