import { Components } from "@mdx-js/react/lib"
import {
	FunctionComponent,
	ReactNode,
	useEffect,
	useState,
} from "react"

import clsx from "clsx"
import { MDXRemoteSerializeResult } from "next-mdx-remote"
import { serialize } from "next-mdx-remote/serialize"

const p: FunctionComponent<{ children?: ReactNode }> = ({
	children,
	...props
}) => <p {...props}>{children}</p>

const h1: FunctionComponent<{ children?: ReactNode }> = ({
	children,
	...props
}) => (
	<h1 {...props} className="text-h1 py-[50px] text-center">
		{children}
	</h1>
)

const h2: FunctionComponent<{ children?: ReactNode }> = ({
	children,
	...props
}) => (
	<h2
		{...props}
		className={clsx(
			"text-h2 py-8 text-center tablet:py-10 desktop:py-[50px]",
		)}>
		{children}
	</h2>
)

const h3: FunctionComponent<{ children?: ReactNode }> = ({
	children,
	...props
}) => (
	<h3
		{...props}
		className={clsx(
			"text-h3 py-6 text-center tablet:py-8 desktop:py-[40px]",
		)}>
		{children}
	</h3>
)

export const blogCdn = "https://filesw.zoomerang.info/blog"

const Image: FunctionComponent<{
	source?: string
	description?: string
	vertical?: boolean
	center?: boolean
	link?: string
}> = (props) => {
	const { source, description, vertical, center, link } =
		props
	const isSvg = source?.endsWith(".svg")
	// The ? symbol is a workaround for a bug in the blog CDN
	// September 2023
	const src = isSvg ? "" : `${blogCdn}/${source}?`

	const [url, setUrl] = useState<string | null>(null)

	useEffect(() => {
		if (!isSvg) {
			return
		}

		fetch(`${blogCdn}/${source}`).then(async (response) => {
			const blob = await response.blob()
			const svgBlob = new Blob([blob], {
				type: "image/svg+xml",
			})
			const url = URL.createObjectURL(svgBlob)
			setUrl(url)
		})
	}, [isSvg, source])

	if (link === undefined) {
		return (
			<div className={clsx("w-full", bottomMargin)}>
				<img
					src={src || url || ""}
					className={clsx(
						"max-h-[600px] rounded-[12px] object-cover tablet:rounded-[24px] desktop:rounded-[16px]",
						vertical ? "h-[600px]" : "w-full",
						description === undefined && "mb-2",
						center && "mx-auto",
					)}
					alt={`${description} image`}
				/>
			</div>
		)
	}

	return (
		<a href={link} className={clsx("w-full", bottomMargin)}>
			<img
				src={src || url || ""}
				className={clsx(
					"max-h-[600px] rounded-[12px] object-cover tablet:rounded-[24px] desktop:rounded-[16px]",
					vertical ? "h-[600px]" : "w-full",
					description === undefined && "mb-2",
					center && "mx-auto",
				)}
				alt={`${description} image`}
			/>
		</a>
	)
}

const SideBySide: FunctionComponent<{
	children?: ReactNode
	center?: boolean
}> = (props) => {
	const { children, center } = props

	return (
		<div
			className={clsx(
				"tablet:[&>*] flex w-full flex-col items-center gap-4 tablet:flex-row tablet:gap-5 tablet:[&>*]:!w-auto",
				center && "justify-center",
			)}>
			{children}
		</div>
	)
}

export const components: Components = {
	h1,
	h2,
	h3,
	p,
}

const bottomMargin = "mb-3 tablet:mb-4"

const ol: FunctionComponent<{ children?: ReactNode }> = ({
	children,
	...props
}) => (
	<ol
		{...props}
		className={clsx("last:mb-0", bottomMargin)}>
		{children}
	</ol>
)

const ul: FunctionComponent<{ children?: ReactNode }> = ({
	children,
	...props
}) => (
	<ul
		{...props}
		className={clsx("last:mb-0", bottomMargin)}>
		{children}
	</ul>
)

const li: FunctionComponent<{ children?: ReactNode }> = ({
	children,
	...props
}) => (
	<li {...props} className={clsx("mb-2 [&>p]:inline")}>
		{children}
	</li>
)

const a: FunctionComponent<{ children?: ReactNode }> = ({
	children,
	...props
}) => (
	<a
		{...props}
		target="_blank"
		rel="noopener noreferrer"
		className={clsx(
			"underline decoration-[transparent] duration-200",
			"text-[#2789FB] hover:decoration-[#2789FB]",
		)}>
		{children}
	</a>
)

const thirdSubheader: FunctionComponent<{
	children?: ReactNode
}> = ({ children, ...props }) => (
	<h3
		{...props}
		className="text-h6 mb-3 mt-8 text-center desktop:text-left">
		{children}
	</h3>
)

const secondSubheader: FunctionComponent<{
	children?: ReactNode
}> = ({ children, ...props }) => (
	<h2
		{...props}
		id={String(children)}
		className="text-h5 mb-3 mt-8 text-center desktop:text-left">
		{children}
	</h2>
)

const subheader: FunctionComponent<{
	children?: ReactNode
}> = ({ children, ...props }) => (
	<h2
		{...props}
		id={String(children)}
		className="text-h4 subheader mb-3 mt-[50px] text-center desktop:text-left">
		{children}
	</h2>
)

const paragraph: FunctionComponent<{
	children?: ReactNode
}> = ({ children, ...props }) => (
	<p {...props} className={clsx("text-base", bottomMargin)}>
		{children}
	</p>
)

export const withChatComponents: Components = {
	h1: subheader,
	h2: secondSubheader,
	h3: thirdSubheader,
	p: paragraph,
	a,
	ol,
	ul,
	li,
}

export const withComponents: Components = {
	h1: subheader,
	h2: secondSubheader,
	h3: thirdSubheader,
	p: paragraph,
	a,
	ol,
	ul,
	li,
	Image,
	SideBySide,
}

export async function compileMarkdown(
	source: string,
): Promise<CompiledMarkdown> {
	return await serialize(source)
}

export type CompiledMarkdown = MDXRemoteSerializeResult<
	Record<string, unknown>,
	Record<string, unknown>
>
