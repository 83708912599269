import { BranchError, SessionData } from "branch-sdk"
import { useRouter } from "next/router"
import { useCallback, useEffect } from "react"

export const appStoreFallback =
	"https://link.zoomerang.app/QnOr3F3HXvb"

export const appStoreConstant =
	"https://link.zoomerang.app/00obnVZRXvb"

export const playStoreConstant =
	"https://link.zoomerang.app/PMvbFm5RXvb"

export const inAppFallback =
	"https://link.zoomerang.app/NRGfjXcIXvb"

export const snapchatLink =
	"https://www.snapchat.com/add/zoomerang.app"
export const facebookLink =
	"https://www.facebook.com/zoomerangapp/"
export const pinterestLink =
	"https://www.pinterest.com/Zoomerang_App/"
export const youtubeLink =
	"https://www.youtube.com/@Zoomerang"
export const tiktokLink =
	"https://www.tiktok.com/@zoomerang_app"
export const instagramLink =
	"https://www.instagram.com/zoomerang.app"
export const discordLink = "https://discord.gg/hueHW8zK2r"

export type BranchLinkData =
	| {
			"~feature": "tutorial"
			tutorial: string
	  }
	| {
			"~feature": "share"
			username: string
	  }
	| {
			"~feature": "ai_art"
			ai_art_id: number
	  }
	| {
			"~feature": "video_gpt"
			video_gpt: {
				id: string
			}
	  }
	| {
			"~feature": "challenge"
	  }

interface BranchRedirectProps {
	users?: boolean
	templates?: boolean
	fallback?: string
}

const useBranchRedirect = (props?: BranchRedirectProps) => {
	const router = useRouter()

	const { fallback, users, templates } = props || {}

	const handleLink = useCallback(
		(error: BranchError, session: SessionData | null) => {
			if (error || !session) {
				if (fallback) router.replace(fallback)

				return
			}

			const data = session.data_parsed as BranchLinkData

			if (templates)
				if (data["~feature"] === "tutorial")
					return router.replace(
						"/template/" + data.tutorial,
					)

			if (data["~feature"] === "ai_art")
				return router.replace(
					"/ai-gallery/single/" + data.ai_art_id,
				)

			if (data["~feature"] === "video_gpt")
				return router.replace(
					"/ad-examples/single/" + data.video_gpt.id,
				)

			if (users)
				if (data["~feature"] === "share")
					return router.replace(appStoreConstant)

			if (data["~feature"] === undefined) {
				if (fallback) router.replace(fallback)

				return
			}
		},
		[templates, users, fallback, router],
	)

	useEffect(() => {
		import("branch-sdk").then((branch) => {
			branch.default.init(
				process.env.NEXT_PUBLIC_BRANCH_IO_LIVE_KEY || "",
				{},
				handleLink,
			)
		})
	}, [handleLink])
}

export const createAppLink = async (
	uid: string,
): Promise<string | null> => {
	if (typeof window === "undefined") {
		throw new Error(
			"Branch SDK can only be used in the browser.",
		)
	}

	const branch = await import("branch-sdk")

	const branchKey =
		process.env.NEXT_PUBLIC_BRANCH_IO_LIVE_KEY
	if (!branchKey) {
		throw new Error(
			"Branch SDK key is missing. Ensure it is set in environment variables.",
		)
	}

	branch.default.init(branchKey, {}, (err) => {
		if (err) {
			console.error("Branch SDK initialization error:", err)
			throw err
		}
	})

	const linkData = {
		data: { uid },
		feature: "user_profile",
	}

	return new Promise((resolve, reject) => {
		branch.default.link(linkData, (error, link) => {
			if (error) {
				console.error("Error creating link:", error)
				reject(error)
			} else {
				resolve(link)
			}
		})
	})
}

export default useBranchRedirect
