import Meta from "@/comps/meta"
import Player from "@/comps/player"
import { serialize } from "cookie"

import SidePanel from "@/comps/template/panel"

import { CustomThemedResource } from "@/comps/image"
import {
	getServerSideProps,
	TemplatesProps,
} from "@/ssr/single"
import { devices } from "@/utils/breakpoints"
import { assetUrl } from "@/utils/cdn"
import { PopupProvider } from "@/utils/popups"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { TutorialReportRequest } from "pages/api/tutorial-report"
import { useEffect, useRef, useState } from "react"
import { VideoObject, WithContext } from "schema-dts"
import { ReportPopup } from "sections/history/report-popup"
import styled, {
	createGlobalStyle,
} from "styled-components"
import { TaggedTextSlice } from "types/endpoints/tutorial"
import { z } from "zod"

const flattenDescription = (
	description: TaggedTextSlice[],
): string =>
	description.reduce((acc, tag) => {
		switch (tag.format) {
			case "text":
				return acc + tag.content
			case "hashtag":
				return acc + " #" + tag.content
			case "username":
				return acc + tag.label
		}
	}, "")

const GlobalStyle = createGlobalStyle`
	html, body {
		height: var(--vh);

		overscroll-behavior: none;
		background: var(--color-background);
	}
`

export const templateFetchingContextSchema = z
	.union([
		z.object({
			location: z.literal("single"),
		}),
		z.object({
			location: z.enum(["likes", "created"]),
			userId: z.string(),
			offset: z.number(),
		}),
	])
	.catch({ location: "single" })

export type TemplateFetchingContext = z.infer<
	typeof templateFetchingContextSchema
>

export const TEMPLATE_FETCHING_CONTEXT_COOKIE_KEY =
	"_template_fetching"

export function setTemplateFetchingContext(
	context: TemplateFetchingContext,
) {
	const cookieValue = JSON.stringify(context)

	document.cookie = serialize(
		TEMPLATE_FETCHING_CONTEXT_COOKIE_KEY,
		cookieValue,
		{
			sameSite: "lax",
		},
	)
}

const ScrollableContainer = styled.div`
	position: relative;
	display: flex;

	justify-content: flex-start;
	align-items: center;

	@media ${devices.desktop} {
		width: calc(100% - 473px);
		gap: 80px;
	}

	width: 100%;

	scroll-snap-type: y mandatory;
	overflow-y: scroll;

	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	overscroll-behavior: none;
	flex-direction: column;
`

function getJsonLdData(
	id: string,
	username: string,
	name: string,
	description: string,
	preview: string,
	uploadDate: string,
	video: string,
	views: number,
): WithContext<VideoObject>[] {
	return [
		{
			"@context": "https://schema.org",
			"@type": "VideoObject",
			name,
			description,
			thumbnailUrl: [preview],
			license: "https://zoomerang.app/privacy-policy",
			acquireLicensePage:
				"https://zoomerang.app/template/" + id,
			copyrightNotice: "Zoomerang",
			uploadDate,
			author: {
				"@type": "Person",
				name: username,
			},
			creator: {
				"@type": "Person",
				name: username,
			},
			contentUrl: video,
			interactionStatistic: {
				"@type": "InteractionCounter",
				interactionType: { "@type": "WatchAction" },
				userInteractionCount: views,
			},
		},
	]
}

const EPSILON_SCROLL_NUMBER = 100

export default function Templates(props: TemplatesProps) {
	const { templates, initialTemplate, context } = props

	const [currentTemplate, setCurrentTemplate] =
		useState(initialTemplate)
	const scroller = useRef<HTMLDivElement>(null)

	function getOffsetNumber(): number {
		const index = templates.findIndex(
			(template) => template.id === initialTemplate.id,
		)

		if (index === -1) {
			return 0
		}
		return index
	}
	const startIndex =
		context.location !== "single" && getOffsetNumber()

	const { t } = useTranslation()

	useEffect(() => {
		sessionStorage.setItem("last", currentTemplate.id)
	}, [currentTemplate])

	const scrollToTemplate = (templateIndex: number) => {
		const container = scroller.current
		if (!container || typeof window === undefined) {
			return
		}

		container.style.scrollSnapType = "y mandatory"

		container.scrollTo({
			top:
				templateIndex *
				(window.innerHeight + EPSILON_SCROLL_NUMBER),
			behavior: "smooth",
		})
	}

	const handleButtonClick = (direction: "up" | "down") => {
		const currentTemplateIndex = templates.findIndex(
			(template) => template.id === currentTemplate.id,
		)

		if (direction === "up") {
			if (currentTemplateIndex !== 0) {
				setCurrentTemplate(
					templates[currentTemplateIndex - 1],
				)
				scrollToTemplate(currentTemplateIndex - 1)
			}
		} else {
			if (currentTemplateIndex !== templates.length - 1) {
				setCurrentTemplate(
					templates[currentTemplateIndex + 1],
				)
				scrollToTemplate(currentTemplateIndex + 1)
			}
		}
	}

	useEffect(() => {
		const container = scroller.current

		if (!container) {
			return
		}

		if (startIndex !== false) {
			container.scrollTop =
				startIndex *
				(window.innerHeight + EPSILON_SCROLL_NUMBER)
		}

		const handleScroll = () => {
			if (container) {
				const containerRect =
					container.getBoundingClientRect()
				const templateElements = container.querySelectorAll(
					".template-element",
				)

				let firstVisibleTemplateIndex = -1
				templateElements.forEach(
					(templateElement, index) => {
						const templateRect =
							templateElement.getBoundingClientRect()
						if (
							templateRect.top >= containerRect.top &&
							templateRect.bottom <= containerRect.bottom
						) {
							firstVisibleTemplateIndex = index
						}
					},
				)

				if (firstVisibleTemplateIndex !== -1) {
					setCurrentTemplate(
						templates[firstVisibleTemplateIndex],
					)
				}
			}
		}

		container.addEventListener("scroll", handleScroll)

		handleScroll()

		return () => {
			container.removeEventListener("scroll", handleScroll)
		}
	}, [templates, startIndex])
	const router = useRouter()

	function closeFn() {
		if (templates.length > 1) {
			handleButtonClick("down")
		} else {
			router.back()
		}
	}

	return (
		<PopupProvider>
			<div
				className={clsx(
					"flex h-[100svh] w-full flex-col bg-color-black desktop:flex-row",
					"overflow-hidden",
				)}>
				<GlobalStyle />
				<Meta
					title={t("fs_template_title", {
						title: currentTemplate.name,
						creator: currentTemplate.creator.username,
					})}
					description={t("fs_template_description", {
						creator: currentTemplate.creator.username,
						description: flattenDescription(
							currentTemplate.description,
						),
					})}
					cover={currentTemplate.preview.jpg}
					width="540"
					height="960"
					jsonLdData={getJsonLdData(
						currentTemplate.id,
						currentTemplate.creator.username,
						t("fs_template_title", {
							title: currentTemplate.name,
							creator: currentTemplate.creator.username,
						}),
						t("fs_template_description", {
							creator: currentTemplate.creator.username,
							description: flattenDescription(
								currentTemplate.description,
							),
						}),
						currentTemplate.preview.jpg,
						new Date(currentTemplate.created).toISOString(),
						currentTemplate.video,
						currentTemplate.views,
					)}
				/>
				<img
					src={currentTemplate.preview.jpg}
					className={clsx(
						"fixed left-0 top-0 h-full w-full bg-center bg-no-repeat",
						"opacity-50 blur-[90px]",
					)}
					alt="template"
				/>
				<ScrollableContainer
					id="scrollable-container"
					ref={scroller}>
					{templates.map((template) => {
						return (
							<div
								className={clsx(
									"template-element",
									"min-h-screen",
								)}
								key={template.id}>
								<Player
									template={template}
									active={
										startIndex
											? currentTemplate.id === template.id
											: template.id === currentTemplate.id
									}
								/>
							</div>
						)
					})}
				</ScrollableContainer>
				<CloseButton />
				<MoreButton
					id={currentTemplate.id}
					closeFn={closeFn}
				/>
				<div
					className={clsx(
						context.location === "single"
							? "desktop:hidden"
							: "desktop:flex",
						"absolute right-[500px] top-1/2 z-10 hidden flex-col gap-[20px]",
					)}>
					<button
						className="rounded-full bg-color-white/20 p-[15px] hover:bg-color-white/40"
						onClick={() => handleButtonClick("up")}>
						<img
							src={assetUrl("/general/arrow-white.svg")}
							alt="arrow icon"
						/>
					</button>
					<button
						className="rounded-full bg-color-white/20 p-[15px] hover:bg-color-white/40"
						onClick={() => handleButtonClick("down")}>
						<img
							src={assetUrl("/general/arrow-white.svg")}
							alt="arrow icon"
							className="rotate-180"
						/>
					</button>
				</div>
				<SidePanel
					template={currentTemplate}
					closeFn={closeFn}
				/>
			</div>
		</PopupProvider>
	)
}

function MoreButton(props: {
	id: string
	closeFn: () => void
}) {
	const { id, closeFn } = props

	const { t } = useTranslation()
	const [reportPopup, setReportPopup] = useState(false)
	const [payload, setPayload] =
		useState<TutorialReportRequest>({
			tid: id,
			info: {
				reason: "",
				message: "",
			},
		})

	return (
		<div
			className={clsx(
				"absolute right-4 top-4 z-[99] desktop:left-[24px] desktop:top-[24px]",
				"cursor-pointer bg-color-white/20 transition-colors hover:bg-color-white/40",
				"flex h-12 w-12 items-center justify-center rounded-full desktop:hidden",
			)}>
			<div
				className={clsx(
					"group relative cursor-pointer",
					"-mb-[30px] pb-[30px]",
				)}>
				<img
					src={assetUrl("/general/more-white.svg")}
					alt="close"
					className="h-[20px] w-[20px] rotate-90"
				/>
				<button
					onClick={() => {
						setReportPopup(true)
					}}
					className={clsx(
						"absolute hidden w-[123px] rounded-[12px] px-[10px] py-[8px] group-hover:flex",
						"z-[200] items-center gap-[8px] shadow-xl hover:bg-blue-100",
						"-right-[10px] top-[40px] bg-color-popup-cell desktop:top-[80px]",
					)}>
					<CustomThemedResource
						source="/general/report"
						format="svg"
					/>
					<span className="text-[16px] font-500 text-blue-800">
						{t("txt_report")}
					</span>
				</button>
				{reportPopup && setReportPopup && (
					<ReportPopup
						payload={payload}
						setReportPopup={setReportPopup}
						setPayload={setPayload}
						payloadLocation="tutorial"
						endpoint="tutorial-report"
						galleryOrTemplate="template"
						closeFn={closeFn}
						className={clsx(
							"fixed z-[201] tablet:right-[20px] tablet:top-[70px]",
							"bottom-0 left-0 right-0 tablet:bottom-auto tablet:left-auto",
						)}
					/>
				)}
			</div>
		</div>
	)
}

function CloseButton() {
	const router = useRouter()
	const [clicked, setClicked] = useState(false)

	useEffect(() => {
		if (window.history.length <= 1 && clicked) {
			router.push("/")
		} else if (clicked) {
			router.back()
		}
	}, [clicked, router])

	return (
		<button
			onClick={() => {
				setClicked(true)
			}}
			className={clsx(
				"absolute left-4 top-4 z-[99] desktop:left-[24px] desktop:top-[24px]",
				"cursor-pointer bg-color-white/20 transition-colors hover:bg-color-white/40",
				"flex h-12 w-12 items-center justify-center rounded-full",
				clicked && "pointer-events-none opacity-50",
			)}>
			<img
				src={assetUrl("/player/close.svg")}
				alt="close"
			/>
		</button>
	)
}

export { getServerSideProps }
