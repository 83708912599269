import Footer from "@/comps/footer"
import Header from "@/comps/header"
import { CoverProps } from "@/comps/home/cover"
import Meta from "@/comps/meta"
import { fetchOfferingPrices } from "@/comps/subscription-popup"
import useBranchRedirect from "@/utils/branch"
import {
	SSRTemporaryRedirect,
	wrapSSRAuth,
} from "@/utils/ssr-auth"
import { extractResponse } from "@/utils/tutorial"
import useUtmIdentifiers from "@/utils/utm"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import dynamic from "next/dynamic"
import { useQuery } from "react-query"
import {
	Organization,
	SoftwareApplication,
	WithContext,
} from "schema-dts"
import { createGlobalStyle } from "styled-components"
import { parse } from "url"
import homeColumns from "../statics/home.json"
import templatesColumns from "../statics/templates.json"

const NewCover = dynamic(
	() => import("@/comps/home/cover"),
	{
		ssr: true,
	},
)

const Runner = dynamic(
	() => import("@/comps/home/platforms/runner"),
	{
		ssr: false,
		loading: () => <div className="h-[60px] w-full"></div>,
	},
)

const Achievements = dynamic(
	() => import("@/comps/home/achievements"),
	{
		ssr: false,
		loading: () => <div className="h-[288px] w-full"></div>,
	},
)

const VideoExamplesSection = dynamic(
	() => import("@/comps/home/video-examples"),
	{
		ssr: false,
		loading: () => <div className="h-[840px] w-full"></div>,
	},
)

const CreateBanner = dynamic(
	() => import("@/comps/home/banner"),
	{
		ssr: false,
		loading: () => <div className="h-[769px] w-full"></div>,
	},
)

const NewTemplates = dynamic(
	() => import("@/comps/home/templates"),
	{
		ssr: false,
		loading: () => (
			<div className="h-[1121px] w-full"></div>
		),
	},
)

const ToolsSection = dynamic(
	() => import("@/comps/home/tools-section"),
	{
		ssr: false,
		loading: () => (
			<div className="h-[1183px] w-full"></div>
		),
	},
)

const Packages = dynamic(
	() => import("@/comps/home/packages"),
	{
		ssr: false,
		loading: () => <div className="h-[803px] w-full"></div>,
	},
)

const Reviews = dynamic(
	() => import("@/comps/home/reviews"),
	{
		ssr: false,
		loading: () => <div className="h-[740px] w-full"></div>,
	},
)

const templatesResponse = extractResponse(templatesColumns)
const { templates } = templatesResponse

const columns = homeColumns.columns as CoverProps["columns"]

const GlobalStyle = createGlobalStyle`
	html {
		background: var(--color-background);
	}
`

const organizationJsonLd: WithContext<Organization> = {
	"@context": "https://schema.org",
	"@type": "Organization",
	url: "https://zoomerang.app/",
	logo: "https://filesw.zoomerang.info/Web/comps/logo-light.svg",
	name: "Zoomerang",
	email: "info@zoomerang.app",
	description:
		"Social media video and photo creating and editing platform for businesses and creators.",
	address:
		"2035 Sunset Lake Road, Suite B-2, Newark, 19702, United States, DE",
	brand: {
		"@type": "Brand",
	},
	sameAs: [
		"https://www.youtube.com/@Zoomerang",
		"https://www.linkedin.com/company/zoomerang-app/",
		"https://www.facebook.com/zoomerangapp/",
		"https://www.tiktok.com/@zoomerang_app",
		"https://www.instagram.com/zoomerang.app",
	],
	contactPoint: {
		"@type": "ContactPoint",
		contactType: "https://zoomerang.app/contact",
	},
	founder: {
		"@type": "Person",
		name: "David Grigoryan",
		image:
			"https://media.licdn.com/dms/image/C4D03AQHVkDMGmcp2-A/profile-displayphoto-shrink_800_800/0/1614498297830?e=1712188800&v=beta&t=ORL87aHLo8KJ0ujGcycQOM34ZVAaU1RWgIgdHZCvLTQ",
		url: "https://www.linkedin.com/in/grigoryandavid/",
		jobTitle: "Co-Founder and CEO",
	},
	legalName: "© 2024 Zoomerang Inc.",
	slogan: "Create Anything from Nothing With AI",
}

const softwareApplicationJsonLd: WithContext<SoftwareApplication> =
	{
		"@context": "https://schema.org",
		"@type": "SoftwareApplication",
		name: "Zoomerang AI Video Maker",
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "4.9",
			ratingCount: 355459,
			bestRating: "5",
			worstRating: "0",
		},
		applicationCategory: "MultimediaApplication",
		offers: {
			"@type": "Offer",
			price: "0",
			priceCurrency: "USD",
		},
		review: {
			"@type": "Review",
			author: {
				"@type": "Person",
				name: "Wayne Davis",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				worstRating: "0",
				bestRating: "5",
			},
		},
		operatingSystem:
			"Windows, Mac OS, Linux, Chrome OS, iOS, Android",
		url: "https://zoomerang.app/",
		applicationSubCategory: "Video Maker",
		featureList: "https://zoomerang.app/tools",
	}

const Home = () => {
	const { t } = useTranslation()
	useBranchRedirect({ users: true, templates: true })
	useUtmIdentifiers()

	const prices = useQuery({
		queryKey: ["offering-prices"],
		queryFn: fetchOfferingPrices(),
		staleTime: Infinity,
		cacheTime: Infinity,
	})

	return (
		<>
			<Meta
				title={t("txt_home_title")}
				description={t("txt_home_description")}
				jsonLdData={[
					organizationJsonLd,
					softwareApplicationJsonLd,
				]}
			/>
			<div
				className={clsx(
					"flex w-full flex-col items-center bg-color-cell",
				)}>
				<GlobalStyle />
				<Header />
				<NewCover columns={columns} />
				<Runner className="my-10" />
				<Achievements />
				<VideoExamplesSection />
				<CreateBanner />
				<NewTemplates templates={templates} />
				<ToolsSection />

				{prices.data && (
					<Packages
						basic={prices.data?.plans.basic}
						advanced={prices.data?.plans.advanced}
					/>
				)}
				<Reviews />
				{/* <Crisp percent={10} page="home" /> */}
				<Footer />
			</div>
		</>
	)
}

export const getServerSideProps = wrapSSRAuth(
	async (context, userInfo) => {
		const { req } = context

		let redirectUrl = "/home"
		if (req.url !== undefined) {
			const parsedUrl = parse(req.url, true)
			const query = parsedUrl.query

			const queryParams = new URLSearchParams()
			Object.keys(query).forEach((key) => {
				const value = query[key]
				if (Array.isArray(value)) {
					value.forEach((val) =>
						queryParams.append(key, val),
					)
				} else if (value !== undefined) {
					queryParams.append(key, value)
				}
			})

			const queryString = queryParams.toString()
			redirectUrl = `/home?${queryString}`
		}

		if (!userInfo.isAnonymous) {
			throw new SSRTemporaryRedirect(redirectUrl)
		}
	},
	[],
)

export default Home
