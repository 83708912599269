import {
	LockIcon,
	LockIconContainer,
} from "@/comps/advanced-profile"
import {
	aiAdsGenratorIcon,
	ButtonArrowIcon,
	ClearIcon,
	FilterIcon,
	galleryIcon,
	SearchIcon,
	templatesIcon,
} from "@/comps/ai-tools/icons"
import Button from "@/comps/button"
import { DropDown } from "@/comps/dropdown"
import Footer from "@/comps/footer"
import Header from "@/comps/header"
import Loader from "@/comps/loader"
import {
	ErrorMessage,
	SuccessMessage,
} from "@/comps/message"
import Meta, { GalleryMetaProps } from "@/comps/meta"
import { useProgressive } from "@/comps/progressive"
import { SizedSkeleton } from "@/comps/skeleton"
import Tooltip, { TextTooltip } from "@/comps/tooltip"
import { appStoreConstant } from "@/utils/branch"
import { assetUrl, HEADER_WEB_VERSION } from "@/utils/cdn"
import useAuth from "@/utils/client-auth"
import {
	AdExamplesViewProvider,
	arraysAreEqual,
	flattenAndFilterGallery,
	GalleryViewProvider,
	TemplatesViewProvider,
	useAdExamplesViewContext,
	useGalleryViewContext,
	useTemplatesViewContext,
} from "@/utils/discover"
import useInfiniteScroll from "@/utils/infinite"
import { redirectToLogin } from "@/utils/login-redirect"
import prettyMillions from "@/utils/millions"
import { NotificationContext } from "@/utils/notification"
import queryfy from "@/utils/queryfy"
import { Adsense } from "@ctrl/react-adsense"
import axios from "axios"
import clsx from "clsx"
import {
	createInputs,
	DEFORUM_STORAGE_KEY,
} from "comps/editors/deform"
import {
	AITool,
	DynamicEditorProps,
} from "controllers/editors"
import { serialize } from "cookie"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { indexFiltersAdExamples } from "pages/ad-examples"
import {
	AdExamplesType,
	AvailableFilters,
	AvailableFiltersTemplates,
	FilterStateAdExamples,
	FilterStateGallery,
	FilterStateTemplates,
	GalleryEntryType,
	galleryProducts,
	GalleryTools,
	indexFiltersGallery,
	MediaType,
	MetaLinks,
	PriceTypeTemplates,
	SortBy,
	SortByTemplates,
	TemplatesWithSections,
} from "pages/ai-gallery"
import { GalleryResponse } from "pages/api/gallery-all"
import { SuggestionsResponse } from "pages/api/gallery-suggestion"
import { ActionOptions } from "pages/api/gallery-view"
import { TEMPLATE_FETCHING_CONTEXT_COOKIE_KEY } from "pages/template/[slug]"
import {
	indexFiltersTemplates,
	Template,
} from "pages/video-templates"
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react"
import { useQuery, useQueryClient } from "react-query"
import Masonry, {
	ResponsiveMasonry,
} from "react-responsive-masonry"
import {
	ImageObject,
	Thing,
	VideoObject,
	WithContext,
} from "schema-dts"
import {
	AdExamplesGrid,
	FilterDialogAdExamples,
	RatioAdExamples,
} from "./ad-examples"
import {
	AdExamplesPopup,
	AdExamplesPopupProps,
} from "./ad-examples-popup"
import {
	GalleryPopup,
	GalleryPopupProps,
} from "./gallery-popup"

export const GALLERY_ITEMS_IN_PAGE = 50

export const sortLabels = {
	best: "lbl_most_relevant",
	newest: "lbl_newest",
	oldest: "lbl_oldest",
}

export const sortLabelsTemplates = {
	price_low: "lbl_price_low",
	price_high: "lbl_price_high",
	rec: "lbl_rec",
	most_recent: "lbl_most_recent",
	most_used: "lbl_most_used",
}

export const sortOptions = [
	"best",
	"newest",
	"oldest",
] as const

export const sortOptionsTemplates = [
	"rec",
	"most_recent",
	"most_used",
	"price_low",
	"price_high",
] as const

export const priceOptionsTemplates = [
	"all",
	"prime",
	"coin",
] as const

export const priceLablesTemplates = {
	prime: "lbl_prime",
	coin: "lbl_coin",
	all: "lbl_all",
}

function getSuggestions(
	query: string,
	isAdExamples: boolean,
) {
	return async function () {
		const endpoint = isAdExamples
			? "/api/ad-examples-suggestion"
			: "/api/gallery-suggestion"
		return await axios
			.post<SuggestionsResponse>(
				endpoint,
				{ query },
				{
					headers: {
						Authorization: "Bearer sdgdfsgsdfgsdfg",
						"web-version": HEADER_WEB_VERSION,
					},
				},
			)
			.then((response) => response.data)
	}
}

export function getUrlFromString(text: string) {
	return text
		.trim()
		.replace(/,/g, "-") // Remove all commas
		.replace(/&/g, "and") // Replace all "&" with "and"
		.replace(/\s\s*/g, "-") // Replace all spaces with hyphens
		.replace(/\//g, "-") // Replace all "/" with "-"
		.replace(/-{1,}/g, "-") // Replace sequences of two or more hyphens with a single hyphen
		.toLowerCase()
}

export type Pages =
	| "ai-gallery"
	| "video-templates"
	| "ad-examples"

function getJsonLDData(
	query: string,
	galleryJobs: GalleryResponse | null,
): (WithContext<Thing> | GalleryMetaProps)[] {
	const jsonLdData: (
		| WithContext<Thing>
		| GalleryMetaProps
	)[] = []

	if (query.trim().length !== 0) {
		jsonLdData.push({
			"@context": "https://schema.org",
			"@type": "WebSite",
			url: "https://zoomerang.app/ai-gallery/",
			potentialAction: {
				"@type": "SearchAction",
				target:
					"https://zoomerang.app/ai-gallery/{search_term_string}",
				"query-input": "required name=search_term_string",
			},
		})
	}

	if (galleryJobs) {
		galleryJobs.gallery.forEach((job) => {
			if (job.media_type === "video") {
				return
			}

			const imageObject: WithContext<ImageObject> = {
				"@context": "https://schema.org",
				"@type": "ImageObject",
				contentUrl: job.object_urls[0].object,
				license: "https://zoomerang.app/privacy-policy",
				acquireLicensePage:
					"https://zoomerang.app/ai-gallery/single/" +
					job.id,
				copyrightNotice: "Zoomerang",
				uploadDate: new Date(
					job.accepted_at ?? 0,
				).toISOString(),
				author: {
					"@type": "Person",
					name: job.owner.username,
				},
				creator: {
					"@type": "Person",
					name: job.owner.username,
				},
				description:
					job.prompt ?? job.style_name ?? "Custom",
				caption: job.prompt ?? job.style_name ?? "Custom",
				url:
					"https://zoomerang.app/ai-gallery/single/" +
					job.id,
			}
			jsonLdData.push(imageObject)
		})
	}

	return jsonLdData
}

function getJsonLDDataAdExamples(
	query: string,
	jobs: AdExamplesType | null,
): (WithContext<Thing> | GalleryMetaProps)[] {
	const jsonLdData: (
		| WithContext<Thing>
		| GalleryMetaProps
	)[] = []

	if (query.trim().length !== 0) {
		jsonLdData.push({
			"@context": "https://schema.org",
			"@type": "WebSite",
			url: "https://zoomerang.app/ad-examples/",
			potentialAction: {
				"@type": "SearchAction",
				target:
					"https://zoomerang.app/ad-examples/{search_term_string}",
				"query-input": "required name=search_term_string",
			},
		})
	}

	if (jobs) {
		if (jobs.type === "home") {
			jobs.content.forEach((page) => {
				page.results.forEach((job) => {
					if (!job.preview_video_url) return

					const imageObject: WithContext<VideoObject> = {
						"@context": "https://schema.org",
						"@type": "VideoObject",
						name: job.title ?? job.description ?? "",
						description: job.description ?? job.title ?? "",
						thumbnailUrl: job.preview_thumbnail_url,
						license: "https://zoomerang.app/privacy-policy",
						acquireLicensePage:
							"https://zoomerang.app/ad-examples/single/" +
							job.id,
						copyrightNotice: "Zoomerang",
						uploadDate: job.created_at,
						author: {
							"@type": "Person",
							name: job.owner?.username ?? "Zoomerang",
						},
						creator: {
							"@type": "Person",
							name: job.owner?.username ?? "Zoomerang",
						},
						contentUrl: job.preview_video_url,
					}
					jsonLdData.push(imageObject)
				})
			})
		} else {
			jobs.content.forEach((job) => {
				if (!job.preview_video_url) return

				const imageObject: WithContext<VideoObject> = {
					"@context": "https://schema.org",
					"@type": "VideoObject",
					name: job.title ?? job.description ?? "",
					description: job.description ?? job.title ?? "",
					thumbnailUrl: job.preview_thumbnail_url,
					license: "https://zoomerang.app/privacy-policy",
					acquireLicensePage:
						"https://zoomerang.app/ad-examples/single/" +
						job.id,
					copyrightNotice: "Zoomerang",
					uploadDate: job.created_at,
					author: {
						"@type": "Person",
						name: job.owner?.username,
					},
					creator: {
						"@type": "Person",
						name: job.owner?.username,
					},
					contentUrl: job.preview_video_url,
				}
				jsonLdData.push(imageObject)
			})
		}
	}

	return jsonLdData
}

interface GalleryProps {
	galleryJobs: GalleryResponse | null
	templatesJobs: TemplatesWithSections[] | null
	adExamplesJobs: AdExamplesType | null
	initialFiltersGallery: FilterStateGallery | null
	initialFiltersTemplates: FilterStateTemplates | null
	initialFiltersAdExamples: FilterStateAdExamples | null
	availableFiltersGallery: AvailableFilters
	availableFiltersTemplates: AvailableFiltersTemplates
	pageTitle: string | null
	initialPage: Pages
	metaLinks: MetaLinks | null
	pageNumber?: number
}

export default function GalleryAll(props: GalleryProps) {
	const [filterDialogOpen, setFilterDialogOpen] =
		useState(false)
	const [categoryDialogOpen, setCategoryDialogOpen] =
		useState(false)
	const [isFilterSelected, setIsFilterSelected] =
		useState(false)
	const { t } = useTranslation("common")
	const {
		initialFiltersGallery,
		initialFiltersTemplates,
		initialFiltersAdExamples,
		galleryJobs,
		templatesJobs,
		adExamplesJobs,
		availableFiltersGallery,
		availableFiltersTemplates,
		pageTitle: title,
		initialPage,
		metaLinks,
		pageNumber,
	} = props
	const [galleryPopupState, setGalleryPopupState] =
		useState<GalleryPopupProps | null>(null)
	const [adExamplesPopupState, setAdExamplesPopupState] =
		useState<AdExamplesPopupProps | null>(null)
	const [pageTitle, setPageTitle] = useState(title)
	const [active, setActive] = useState(false)
	const [page, setPage] = useState(initialPage)
	const [searchByPage, setSearchByPage] =
		useState(initialPage)
	const router = useRouter()

	const [queryInput, setQueryInput] = useState(
		router.asPath.split(`/${page}/`)[1]?.split("?")[0] ??
			"",
	)

	const [pageLinks, setPageLinks] = useState<{
		next: string | null
		prev: string | null
	}>(
		metaLinks ?? {
			next: `${router.asPath}?page=2`,
			prev: null,
		},
	)

	const prevUrlRef = useRef<string | null>(null)

	useEffect(() => {
		if (typeof window !== "undefined") {
			prevUrlRef.current = window.location.href

			const handlePopState = () => {
				if (window.location.href !== prevUrlRef.current) {
					window.location.reload()
				}
			}

			window.addEventListener("popstate", handlePopState)

			return () => {
				window.removeEventListener(
					"popstate",
					handlePopState,
				)
			}
		}
	}, [])

	useEffect(() => {
		if (typeof window !== "undefined") {
			prevUrlRef.current = window.location.href
		}
	})

	useEffect(() => {
		if (
			filterDialogOpen ||
			galleryPopupState !== null ||
			adExamplesPopupState !== null
		) {
			document.body.style.overflow = "hidden"
		} else {
			document.body.style.overflow = "unset"
		}
	}, [
		adExamplesPopupState,
		filterDialogOpen,
		galleryPopupState,
	])

	return (
		<GalleryViewProvider
			availableFilters={availableFiltersGallery}
			initialFilters={
				initialFiltersGallery ?? indexFiltersGallery
			}
			initialData={galleryJobs ?? undefined}
			page={page}
			pageNumber={
				initialPage === "ai-gallery" && pageNumber
					? pageNumber
					: 1
			}>
			<TemplatesViewProvider
				availableFilters={availableFiltersTemplates}
				initialFilters={
					initialFiltersTemplates ?? indexFiltersTemplates
				}
				initialData={templatesJobs ?? undefined}
				page={page}>
				<AdExamplesViewProvider
					initialFilters={
						initialFiltersAdExamples ??
						indexFiltersAdExamples
					}
					initialData={adExamplesJobs ?? undefined}
					page={page}
					pageNumber={
						initialPage === "ad-examples" && pageNumber
							? pageNumber
							: 1
					}>
					<Meta
						title={
							pageTitle
								? pageTitle +
								  (page === "ai-gallery"
										? " - " + t("txt_gallery_title")
										: page === "video-templates"
										? " - " + t("txt_templates_title")
										: " " + t("txt_ad_examples_zoomerang"))
								: page === "ai-gallery"
								? t("txt_gallery_title")
								: page === "video-templates"
								? t("txt_templates_title")
								: t("txt_ad_examples_title")
						}
						description={
							page === "ai-gallery"
								? t("txt_gallery_description")
								: page === "video-templates"
								? t("txt_templates_description")
								: t("txt_ad_examples_description")
						}
						cover={
							page === "ai-gallery"
								? assetUrl("/covers/gallery.jpg")
								: page === "video-templates"
								? assetUrl("/covers/templates.jpg")
								: assetUrl("/covers/ad-examples.jpg")
						}
						prevAndNextLinks={
							page === "ai-gallery" ||
							page === "ad-examples"
								? pageLinks
								: undefined
						}
						currentPage={router.asPath}
						jsonLdData={
							page === "ai-gallery"
								? getJsonLDData(queryInput, galleryJobs)
								: page === "ad-examples"
								? getJsonLDDataAdExamples(
										queryInput,
										adExamplesJobs,
								  )
								: []
						}
					/>
					<Header isStatic isSolid />
					<>
						<FilterDialogMobile
							filterDialogOpen={filterDialogOpen}
							setFilterDialogOpen={setFilterDialogOpen}
							setCategoryDialogOpen={setCategoryDialogOpen}
							setIsFilterSelected={setIsFilterSelected}
							page={page}
							initialFilters={
								initialFiltersTemplates ??
								indexFiltersTemplates
							}
						/>
					</>
					<>
						<CategoriesDialogMobile
							filterDialogOpen={categoryDialogOpen}
							setFilterDialogOpen={setCategoryDialogOpen}
						/>
					</>

					<div className="flex min-h-screen flex-col justify-between overflow-x-clip">
						<div className="sticky top-[-20px] z-50 bg-color-background tablet:top-[16px] desktop:top-[10px] ">
							<div
								style={{
									background:
										"linear-gradient(90deg, rgba(176, 191, 232, 0.18) 12.5%, rgba(229, 138, 88, 0.12) 51.98%, rgba(214, 51, 168, 0.11) 86.46%)",
								}}
								className={clsx(
									"flex h-[194px] w-full flex-col items-center justify-center gap-[14px] px-4",
									"tablet:h-[176px] desktop:h-[190px] desktop:px-8",
								)}>
								<div
									className={clsx(
										"z-[1] flex w-full flex-row items-start justify-between pt-[16px] tablet:pt-[18px] desktop:pt-0",
										"tablet:w-[768px] tablet:px-4 desktop:w-[1175px] desktop:px-0",
									)}>
									<div className="flex w-full flex-col justify-end gap-5 tablet:w-auto">
										{pageTitle ? (
											<PageTitle
												page={page}
												pageTitle={pageTitle}
												setPageTitle={setPageTitle}
											/>
										) : (
											<div className="flex flex-row items-center gap-[18px] tablet:gap-[22px] desktop:gap-8">
												<a
													href="/ai-gallery"
													onClick={(e) => {
														e.preventDefault()
														window.history.pushState(
															{ path: "/ai-gallery" },
															"",
															"/ai-gallery",
														)
														setPage("ai-gallery")
														setSearchByPage("ai-gallery")
													}}
													className={clsx(
														"truncate text-[18px] font-600 desktop:text-[21px]",
														"leading-[36px] transition-colors",
														"flex flex-row items-center gap-1",
														page === "ai-gallery"
															? "text-primary-500 [--icon-color:var(--color-primary-500)]"
															: "text-blue-700 [--icon-color:var(--color-blue-700)]",
													)}>
													{galleryIcon()}
													{t("lbl_ai_gallery")}
												</a>
												<a
													href="/video-templates"
													onClick={(e) => {
														e.preventDefault()
														window.history.pushState(
															{ path: "/video-templates" },
															"",
															"/video-templates",
														)
														setPage("video-templates")
														setSearchByPage(
															"video-templates",
														)
													}}
													className={clsx(
														"truncate text-[18px] font-600 desktop:text-[21px]",
														"leading-[36px] transition-colors",
														"flex flex-row items-center gap-1",
														page === "video-templates"
															? "text-primary-500 [--icon-color:var(--color-primary-500)]"
															: "text-blue-700 [--icon-color:var(--color-blue-700)]",
													)}>
													{templatesIcon()}
													{t("lbl_templates")}
												</a>
												<a
													href="/ad-examples"
													onClick={(e) => {
														e.preventDefault()
														window.history.pushState(
															{ path: "/ad-examples" },
															"",
															"/ad-examples",
														)
														setPage("ad-examples")
														setSearchByPage("ad-examples")
													}}
													className={clsx(
														"truncate text-[18px] font-600 desktop:text-[21px]",
														"leading-[36px] transition-colors",
														"flex flex-row items-center gap-1",
														page === "ad-examples"
															? "text-primary-500 [--icon-color:var(--color-primary-500)]"
															: "text-blue-700 [--icon-color:var(--color-blue-700)]",
													)}>
													{aiAdsGenratorIcon()}
													{t("lbl_ad_examples")}
												</a>
											</div>
										)}
										<div className="flex flex-row items-center gap-2">
											<div
												className={clsx(
													"relative flex h-11 w-full flex-row items-center",
													"gap-[6px] rounded-[6px] bg-color-cell px-3 py-[10px]",
													"dark:bg-color-background tablet:h-10 tablet:w-[394px]",
													"tablet:rounded-[8px] tablet:py-[7px] tablet:pl-0 tablet:pr-[14px]",
													"border transition-colors desktop:h-11 desktop:w-[744px]",
													"desktop:rounded-[10px] desktop:py-[10px]",
													active
														? " border-blue-400"
														: "border-color-cell dark:border-color-background",
												)}>
												<div
													className={clsx(
														" absolute -left-1 -top-1 -z-[1] h-[calc(100%+8px)] w-[calc(100%+8px)] bg-color-black/20 transition-opacity",
														"rounded-[9px] tablet:rounded-[11px] desktop:rounded-[13px]",
														active
															? "opacity-100"
															: "opacity-0",
													)}></div>

												<SearchBy
													searchByPage={searchByPage}
													setSearchByPage={setSearchByPage}
												/>

												<div
													className={clsx(
														"ml-[2px] hidden h-full w-[1px] border-l",
														"border-color-separator tablet:block",
													)}
												/>

												<div
													className={clsx(
														"flex h-[16px] w-[16px] items-center justify-center",
														"[--icon-color:var(--color-placeholder)] tablet:ml-[2px]",
													)}>
													<SearchIcon />
												</div>

												<SearchQuery
													setPageTitle={setPageTitle}
													setActive={setActive}
													searchByPage={searchByPage}
													setPage={setPage}
													setQueryInput={setQueryInput}
												/>
											</div>
											<div className="relative flex h-11 tablet:hidden">
												<button
													type="button"
													onClick={() =>
														setFilterDialogOpen(true)
													}
													className={clsx(
														"flex flex-row items-center justify-center",
														"[--icon-color:var(--color-blue-600)]",
														"h-full w-11 hover:bg-blue-100",
														"rounded-[6px] bg-color-cell dark:bg-color-background",
													)}>
													<FilterIcon />
												</button>
												<div
													className={clsx(
														"absolute right-[-3px] top-[-3px] h-[8px] w-[8px]",
														"rounded-full bg-primary-500 transition-opacity tablet:hidden",
														!isFilterSelected &&
															"opacity-0",
													)}
												/>
											</div>
										</div>
									</div>

									<div className="hidden h-full flex-row items-end gap-3 tablet:flex">
										{page === "ai-gallery" && (
											<SortByGallery />
										)}
										{page === "video-templates" && (
											<SortByTemplatesComponent />
										)}

										{page === "ad-examples" && (
											<RatioAdExamples />
										)}

										{page === "ai-gallery" && (
											<AvailableFiltersGallery />
										)}
										{page === "video-templates" && (
											<AvailableFiltersTemplatesComponent
												initialFilters={
													initialFiltersTemplates ??
													indexFiltersTemplates
												}
											/>
										)}
									</div>
								</div>

								{page === "ai-gallery" && (
									<GallerySuggestions
										setPageTitle={setPageTitle}
									/>
								)}

								{page === "ad-examples" && (
									<GallerySuggestions
										setPageTitle={setPageTitle}
										isAdExamples
									/>
								)}

								{page === "video-templates" && (
									<div className="h-[36px] w-full"></div>
								)}
							</div>
						</div>

						{page === "ai-gallery" && (
							<GalleryMasonry
								setGalleryPopupState={setGalleryPopupState}
								setPageLinks={setPageLinks}
							/>
						)}
						{page === "video-templates" && (
							<TemplatesGrid />
						)}

						{page === "ad-examples" && (
							<AdExamplesGrid
								setPopupState={setAdExamplesPopupState}
							/>
						)}

						<Footer />
					</div>

					{galleryPopupState !== null && (
						<GalleryPopup {...galleryPopupState} />
					)}

					{adExamplesPopupState !== null && (
						<AdExamplesPopup {...adExamplesPopupState} />
					)}
				</AdExamplesViewProvider>
			</TemplatesViewProvider>
		</GalleryViewProvider>
	)
}

interface PageTitleProps {
	pageTitle: string
	page: Pages
	setPageTitle: (v: string | null) => void
}

function PageTitle(props: PageTitleProps) {
	const { page, pageTitle, setPageTitle } = props
	const { t } = useTranslation()
	const { query } = useAdExamplesViewContext()
	const { query: gallery } = useGalleryViewContext()

	const [oldTitle, setOldTitle] = useState(pageTitle)
	const totalAdExamples =
		query.data?.pages[0].data.type === "searched"
			? query.data.pages[0].data.total
			: null
	const totalGallery =
		gallery.data?.pages[0].data.total ?? null

	const number =
		page === "ad-examples"
			? totalAdExamples
			: page === "ai-gallery"
			? totalGallery
			: null

	useEffect(() => {
		if (oldTitle !== pageTitle && number !== null) {
			const newTitle = number + " " + pageTitle
			setPageTitle(newTitle)
			setOldTitle(newTitle)
		}
	}, [number, oldTitle, page, pageTitle, setPageTitle])

	return (
		<h1
			className={clsx(
				"text-h5 w-full truncate text-center text-blue-800",
				"tablet:w-[482px] tablet:text-start tablet:leading-[24px] desktop:w-[750px] desktop:leading-[36px]",
			)}>
			{pageTitle +
				" " +
				(page === "ai-gallery"
					? t("txt_ai_generated_art")
					: page === "video-templates"
					? t("lbl_templates")
					: t("lbl_ad_examples"))}
		</h1>
	)
}

interface GallerySuggestionsProps {
	setPageTitle?: (id: string | null) => void
	isColorBackground?: boolean
	isAdExamples?: boolean
	changeDiscoverFilter?: (
		filter: Pages,
		q?: string,
		categoryId?: string,
	) => void
}

const MAX_ALLOWED_DISTANCE = 1
function getNextVisibleChild(
	container: HTMLDivElement,
): HTMLElement | null {
	const parentBounds = container.getBoundingClientRect()
	const parentX = parentBounds.x
	const parentWidth = parentBounds.width

	for (const child of container.childNodes) {
		if (child instanceof HTMLElement) {
			const { x, width } = child.getBoundingClientRect()
			if (
				x + width - MAX_ALLOWED_DISTANCE >
				parentX + parentWidth
			) {
				return child
			}
		}
	}

	return null
}

function getPrevVisibleChild(
	container: HTMLDivElement,
): HTMLButtonElement | null {
	const parentBounds = container.getBoundingClientRect()
	const parentX = parentBounds.x
	let last: HTMLButtonElement | null = null
	for (const child of container.childNodes) {
		if (child instanceof HTMLButtonElement) {
			const { x } = child.getBoundingClientRect()
			if (x + MAX_ALLOWED_DISTANCE > parentX) {
				return last
			}
			last = child
		}
	}

	return null
}

export function GallerySuggestions(
	props: GallerySuggestionsProps,
) {
	const {
		setPageTitle,
		isAdExamples,
		isColorBackground,
		changeDiscoverFilter,
	} = props
	const { state: galleryState, dispatch: galleryDispatch } =
		useGalleryViewContext()
	const {
		state: adExamplesState,
		dispatch: adExamplesDispatch,
	} = useAdExamplesViewContext()
	const { searchQuery } = isAdExamples
		? adExamplesState
		: galleryState

	const router = useRouter()
	const scrollContainerRef = useRef<HTMLDivElement>(null)
	const [prevDisabled, setPrevDisabled] = useState(true)
	const [nextDisabled, setNextDisabled] = useState(false)
	const scrollLeft = () => {
		if (!scrollContainerRef.current) {
			return
		}
		const prev = getPrevVisibleChild(
			scrollContainerRef.current,
		)
		if (!prev) {
			return
		}

		prev.scrollIntoView({
			block: "center",
			inline: "start",
			behavior: "smooth",
		})
	}

	const scrollRight = () => {
		if (!scrollContainerRef.current) {
			return
		}
		const next = getNextVisibleChild(
			scrollContainerRef.current,
		)
		if (!next) {
			return
		}

		next.scrollIntoView({
			block: "center",
			inline: "end",
			behavior: "smooth",
		})
	}

	const submitQuery = (suggestion: string) => {
		if (isAdExamples) {
			adExamplesDispatch({
				type: "set_search_query",
				searchQuery: suggestion,
			})
			suggestions.refetch()
			adExamplesDispatch({
				type: "apply_filters",
			})

			changeDiscoverFilter &&
				changeDiscoverFilter("ad-examples", suggestion)
		} else {
			galleryDispatch({
				type: "set_search_query",
				searchQuery: suggestion,
			})
			suggestions.refetch()
			galleryDispatch({
				type: "apply_filters",
				filters: galleryState,
			})
			changeDiscoverFilter &&
				changeDiscoverFilter("ai-gallery", suggestion)
		}
	}

	const updateButtonStatus = useCallback(() => {
		let nextDisabled = false
		if (
			scrollContainerRef.current &&
			scrollContainerRef.current.scrollWidth <=
				scrollContainerRef.current.clientWidth
		) {
			nextDisabled = true
		}

		if (
			scrollContainerRef.current &&
			getNextVisibleChild(scrollContainerRef.current)
		) {
			nextDisabled = false
		}

		if (
			scrollContainerRef.current &&
			!getNextVisibleChild(scrollContainerRef.current)
		) {
			nextDisabled = true
		}

		setNextDisabled(nextDisabled)

		let prevDisabled = true
		if (
			scrollContainerRef.current &&
			getPrevVisibleChild(scrollContainerRef.current)
		) {
			prevDisabled = false
		}

		setPrevDisabled(prevDisabled)
	}, [scrollContainerRef])

	const suggestions = useQuery({
		queryKey: [
			"suggestions",
			`query-${searchQuery}`,
			`is-ad-examples-${isAdExamples}`,
		],
		queryFn: getSuggestions(
			searchQuery ?? "",
			isAdExamples ?? false,
		),
		staleTime: Infinity,
	})

	useEffect(() => {
		updateButtonStatus()
	}, [searchQuery, updateButtonStatus, suggestions.status])

	return (
		<>
			<div
				className={clsx(
					"relative w-full",
					!isColorBackground && " desktop:w-[1175px]",
				)}>
				<div
					onScroll={updateButtonStatus}
					ref={scrollContainerRef}
					className={clsx(
						"no-scrollbar mx-auto flex w-full flex-row",
						"items-center gap-[10px] overflow-x-scroll",
						isColorBackground
							? "w-full"
							: "tablet:px-[calc(50%-368px)] desktop:w-[1175px] desktop:px-0",
						isColorBackground &&
							suggestions.data?.length === 0
							? "h-0"
							: "h-[36px]",
					)}>
					{suggestions.isFetching && (
						<SuggestionsSkeleton />
					)}
					{suggestions.isSuccess &&
						!suggestions.isFetching &&
						suggestions.data.map((suggestion) => {
							const searchUrl = getUrlFromString(suggestion)

							const href = isAdExamples
								? `/ad-examples/${encodeURIComponent(
										searchUrl,
								  )}`
								: `/ai-gallery/${encodeURIComponent(
										searchUrl,
								  )}`

							return (
								<a
									href={href}
									key={suggestion}
									onClick={(e) => {
										e.preventDefault()
										submitQuery(suggestion)

										if (setPageTitle) {
											setPageTitle(
												suggestion
													.replace("-", " ")
													.replace(/\b\w/g, (match) =>
														match.toUpperCase(),
													),
											)

											router.push(href, undefined, {
												shallow: true,
											})
										}
									}}
									className={clsx(
										"flex h-full shrink-0 flex-row items-center justify-center",
										"gap-[4px] rounded-[6px] p-3 text-[13px]",
										"font-400 tracking-wider text-blue-700 [--icon-color:var(--color-blue-600)]",
										isColorBackground
											? "bg-blue-50"
											: "bg-color-cell dark:bg-color-background",
									)}>
									<div className="flex h-[14px] w-[14px] items-center justify-center">
										{SearchIcon()}
									</div>
									{suggestion}
								</a>
							)
						})}
				</div>

				{/* Left Button */}

				<button
					disabled={prevDisabled}
					style={{
						filter:
							"drop-shadow(0px 2.353px 13.647px rgba(123, 128, 158, 0.12))",
					}}
					className={clsx(
						"absolute top-[-2px] hidden h-10 w-10",
						"items-center justify-center rounded-full bg-color-cell",
						"[--icon-color:var(--color-blue-600)] disabled:pointer-events-none",
						"disabled:opacity-0 desktop:flex",
						suggestions.isFetching &&
							"pointer-events-none opacity-0",
						isColorBackground
							? "left-[-10px]"
							: "left-[-20px]",
					)}
					onClick={scrollLeft}>
					{ButtonArrowIcon()}
				</button>

				{/* Right Button */}

				<button
					disabled={nextDisabled}
					style={{
						filter:
							"drop-shadow(0px 2.353px 13.647px rgba(123, 128, 158, 0.12))",
					}}
					className={clsx(
						"absolute top-[-2px] hidden h-10 w-10",
						"rotate-180 items-center justify-center rounded-full bg-color-cell",
						"[--icon-color:var(--color-blue-600)] disabled:pointer-events-none",
						"disabled:opacity-0 desktop:flex",
						suggestions.isFetching &&
							"pointer-events-none opacity-0",
						isColorBackground
							? "right-[-10px]"
							: "right-[-20px]",
					)}
					onClick={scrollRight}>
					{ButtonArrowIcon()}
				</button>
			</div>
		</>
	)
}

export function SuggestionsSkeleton() {
	return (
		<>
			<SizedSkeleton className="h-full w-[110px] shrink-0 rounded-[6px]"></SizedSkeleton>
			<SizedSkeleton className="h-full w-[80px] shrink-0 rounded-[6px]"></SizedSkeleton>
			<SizedSkeleton className="h-full w-[90px] shrink-0 rounded-[6px]"></SizedSkeleton>
			<SizedSkeleton className="h-full w-[70px] shrink-0 rounded-[6px]"></SizedSkeleton>
			<SizedSkeleton className="h-full w-[110px] shrink-0 rounded-[6px]"></SizedSkeleton>
			<SizedSkeleton className="h-full w-[90px] shrink-0 rounded-[6px]"></SizedSkeleton>
		</>
	)
}

export function TemplatesGrid(props: {
	isDashboard?: boolean
}) {
	const { isDashboard } = props
	const { query, state, dispatch, availableFilters } =
		useTemplatesViewContext()
	const lastRowRef = useRef<HTMLDivElement>(null)

	const categories = availableFilters.categories

	const { t } = useTranslation()

	const [name, setName] = useState<string | undefined>()
	const [category, setCategory] = useState<number | null>(
		null,
	)

	useInfiniteScroll(query, lastRowRef, 1000)

	const prevQueryKeyComponentsRef = useRef([
		state.applyFilters,
	])

	useEffect(() => {
		const currentQueryKeyComponents = [state.applyFilters]

		if (
			!arraysAreEqual(
				currentQueryKeyComponents,
				prevQueryKeyComponentsRef.current,
			)
		) {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			})

			prevQueryKeyComponentsRef.current =
				currentQueryKeyComponents
		}
	}, [state.applyFilters])

	const flat = useMemo(() => {
		const flatArray: TemplatesWithSections[] = []

		query.data?.pages.forEach((page) => {
			page.data.forEach((section) => {
				const flattenedSection: Template[] = []
				const seenIds = new Set<string>()

				section.data.forEach((template) => {
					if (!seenIds.has(template.id)) {
						seenIds.add(template.id)
						flattenedSection.push(template)
					}
				})

				flatArray.push({
					id: section.id,
					section: section.section,
					data: flattenedSection,
				})
			})
		})

		return flatArray
	}, [query.data])

	const flatSection = useMemo(() => {
		const flattenedSection: Template[] = []

		query.data?.pages.forEach((page) => {
			page.data.forEach((section) => {
				if (section.section !== "searched") {
					return flattenedSection
				}

				const seenIds = new Set<string>()

				section.data.forEach((template) => {
					if (!seenIds.has(template.id)) {
						seenIds.add(template.id)
						flattenedSection.push(template)
					}
				})
			})
		})

		return flattenedSection
	}, [query.data])

	const noTemplates =
		query.isSuccess &&
		!query.isFetching &&
		flat[0].data.length === 0

	function handleSeeMore(id: number) {
		dispatch({
			type: "select_category",
			id: id,
		})

		dispatch({
			type: "apply_filters",
		})

		const find =
			categories.find((pre) => pre.id === id)?.name ??
			t("lbl_featured")

		setName(find)
		setCategory(id)

		const params = queryfy({
			categoryId: id,
		})
		let newUrl = `/video-templates${params}`
		if (isDashboard) {
			newUrl = `/discover?filter=video-templates&categoryId=${id}`
		}
		window.history.pushState({ path: newUrl }, "", newUrl)
	}

	useEffect(() => {
		if (category === state.applyFilters.selectedCategory)
			return

		const selectedCategoryId =
			state.applyFilters.selectedCategory

		const find =
			categories.find(
				(pre) => pre.id === Number(selectedCategoryId),
			)?.name ?? t("lbl_featured")

		setName(find)
		setCategory(
			selectedCategoryId
				? Number(selectedCategoryId)
				: null,
		)
	}, [categories, category, state.applyFilters, t])

	return (
		<>
			{!noTemplates && (
				<div
					className={clsx(
						"mx-auto my-5 flex w-full flex-col gap-3",
						"tablet:gap-4 desktop:my-0 desktop:gap-5",
						!isDashboard &&
							"tablet:w-[768px] desktop:w-[1175px] desktop:px-0",
					)}>
					{category !== null &&
						Number(category) !== -2 &&
						name !== undefined && (
							<div
								className={clsx(
									"flex flex-row items-center gap-1 px-4 desktop:mt-5 desktop:px-0",
									isDashboard
										? "desktop:px-5"
										: "desktop:px-0",
								)}>
								<a
									href={
										isDashboard
											? "/discover?filter=video-templates"
											: "/video-templates"
									}
									className="cursor-pointer text-[17px] font-500 text-blue-600">
									{t("lbl_templates")}
								</a>
								<div className="flex [--icon-color:var(--color-blue-500)]">
									{BreadCrumb()}
								</div>
								<p className=" text-[17px] font-500 text-blue-800">
									{name}
								</p>
							</div>
						)}
					{Number(category) === -2 &&
						state.applyFilters.searchQuery && (
							<div
								className={clsx(
									"flex flex-row items-center gap-1 px-4 desktop:mt-5 desktop:px-0",
									isDashboard
										? "desktop:px-5"
										: "desktop:px-0",
								)}>
								<a
									href={
										isDashboard
											? "/discover?filter=video-templates"
											: "/video-templates"
									}
									className="cursor-pointer text-[17px] font-500 text-blue-600">
									{t("lbl_templates")}
								</a>
								<div className="flex [--icon-color:var(--color-blue-500)]">
									{BreadCrumb()}
								</div>
								<p className=" max-w-[200px] truncate text-[17px] font-500 text-blue-800">
									{state.applyFilters.searchQuery}
								</p>
							</div>
						)}
					{Number(category) === -2 &&
						!state.applyFilters.searchQuery &&
						state.applyFilters.price !== "all" && (
							<div
								className={clsx(
									"flex flex-row items-center gap-1 px-4 desktop:mt-5 desktop:px-0",
									isDashboard
										? "desktop:px-5"
										: "desktop:px-0",
								)}>
								<a
									href={
										isDashboard
											? "/discover?filter=video-templates"
											: "/video-templates"
									}
									className="cursor-pointer text-[17px] font-500 text-blue-600">
									{t("lbl_templates")}
								</a>
								<div className="flex [--icon-color:var(--color-blue-500)]">
									{BreadCrumb()}
								</div>
								<p className=" max-w-[200px] truncate text-[17px] font-500 text-blue-800">
									{state.applyFilters.price
										.charAt(0)
										.toUpperCase() +
										state.applyFilters.price.slice(1) +
										" " +
										t("txt_video_templates")}
								</p>
							</div>
						)}
					{flat.map((item, index) => {
						if (
							(item.section !== "searched" &&
								item.data.length < 5) ||
							item.section === "used" ||
							item.section === "rec"
						) {
							return
						} else if (item.section !== "searched") {
							return (
								<div
									key={item.section + index}
									className="flex w-full flex-col gap-3 tablet:px-4 desktop:px-0">
									<div
										className={clsx(
											"flex w-full flex-row justify-between px-4 tablet:px-0 desktop:mt-5",
											isDashboard
												? " desktop:px-5"
												: "desktop:px-0",
										)}>
										<span className=" text-[14px] font-700 text-blue-700 tablet:text-[18px]">
											{String(item.section).toUpperCase()}
										</span>
										<button
											onClick={() =>
												handleSeeMore(item.id ?? -1)
											}
											className="text-[14px] font-500 text-primary-600 tablet:text-[16px]">
											{t("txt_see_more")}
										</button>
									</div>
									<div
										className={clsx(
											"no-scrollbar flex w-full flex-row gap-3 overflow-x-scroll px-4",
											"tablet:gap-3",
											isDashboard
												? "px-5"
												: "tablet:overflow-hidden tablet:px-0 desktop:grid desktop:grid-cols-5 desktop:gap-3 desktop:px-0",
										)}>
										{isDashboard
											? item.data.map((template) => (
													<SingleTemplate
														key={template.id}
														template={template}
														isDashboard={isDashboard}
													/>
											  ))
											: item.data
													.slice(0, 5)
													.map((template) => (
														<SingleTemplate
															key={template.id}
															template={template}
														/>
													))}
									</div>
								</div>
							)
						}
					})}

					{flatSection.length !== 0 && (
						<div
							className={clsx(
								"flex w-full flex-col gap-3 desktop:px-0",
								!isDashboard && "px-4",
							)}>
							<div
								className={clsx(
									"grid w-full grid-cols-2 gap-3 tablet:grid-cols-4",
									isDashboard
										? "bg-color-cell px-5 desktop:grid-cols-5 desktop+:grid-cols-6"
										: "desktop:grid-cols-5",
								)}>
								{flatSection.map((template) => (
									<SingleTemplate
										key={template.id}
										template={template}
										grid
									/>
								))}
							</div>
						</div>
					)}
				</div>
			)}

			<div ref={lastRowRef} />

			<div className="flex w-full justify-center p-8">
				{query.isFetching && <Loader />}
			</div>

			{noTemplates && (
				<LockIconContainer className="pb-32">
					<LockIcon source={"/general/empty-templates"} />
					{t("txt_no_result_yet")}
				</LockIconContainer>
			)}
		</>
	)
}

interface SingleTemplateProps {
	template: Template
	grid?: boolean
	isDashboard?: boolean
}

function SingleTemplate(props: SingleTemplateProps) {
	const {
		preview,
		price,
		prime,
		likes,
		setups,
		creator,
		id,
	} = props.template

	const { grid, isDashboard } = props

	useEffect(() => {
		const cookieValue = {
			location: "single",
		}

		document.cookie = serialize(
			TEMPLATE_FETCHING_CONTEXT_COOKIE_KEY,
			JSON.stringify(cookieValue),
			{
				sameSite: "lax",
			},
		)
	}, [])

	const image =
		preview?.webp ?? preview?.gif ?? preview?.jpg
	return (
		<>
			<div
				style={{
					backgroundColor:
						skeletonColors[likes % skeletonColors.length],
				}}
				className={clsx(
					"relative aspect-[9/16] shrink-0 overflow-hidden rounded-[6px]",
					grid
						? " min-h-[268px] w-full"
						: "h-[268px] w-[164px] tablet:h-[280px] tablet:w-[172px] desktop:h-[349px]",
					isDashboard
						? "desktop:w-[215px]"
						: "desktop:w-full",
				)}>
				<a
					href={`/template/${id}`}
					className="h-full w-full">
					<img
						src={image}
						alt={`${creator.username} Video Template`}
						className="h-full w-full object-cover"
					/>
				</a>

				<div className="absolute bottom-0 left-0 h-[50px] w-full bg-gradient-to-t from-color-black/50 via-color-black/20 to-color-white/0" />
				<div className="absolute left-0 top-3 flex w-full flex-row items-center px-3">
					<div
						className={clsx(
							"flex w-[60px] flex-row items-center justify-center gap-[2px] rounded bg-color-black/30 px-[10px] py-[3px] text-[14px] font-600 text-color-white backdrop-blur-sm",
							!price && "pointer-events-none opacity-0",
						)}>
						{price ?? 0}
						<img
							src={assetUrl("/general/coin.svg")}
							alt="setups"
							className="h-[14px] w-[14px]"
						/>
					</div>
				</div>
				{prime && (
					<div className="absolute left-3 top-3 flex">
						<img
							src={assetUrl("/icons/prime-icon.svg")}
							alt="coin icon"
							className="h-[20px]"
						/>
					</div>
				)}
				<div className="absolute bottom-[10px] left-0 flex w-full flex-row items-center justify-between px-[10px]">
					<a
						href={`/${creator.username}`}
						className="flex flex-row items-center gap-[6px]">
						<img
							src={
								creator.picture ??
								assetUrl("/general/avatar.svg")
							}
							onError={(e) =>
								(e.currentTarget.src = assetUrl(
									"/general/avatar.svg",
								))
							}
							alt={`${creator.username} avatar`}
							className="h-[24px] w-[24px] rounded-full border border-color-separator bg-color-background object-cover"
						/>
						<p className="hidden w-[70px] truncate text-[12px] font-600 text-color-white desktop:block">
							{creator.username}
						</p>
					</a>
					<div className="flex flex-row items-center gap-[6px]">
						<div className="flex flex-row items-center gap-[2px] text-[12px] font-600 text-color-white">
							<img
								src={assetUrl("/player/likes.webp")}
								alt="likes"
								className="h-[14px] w-[14px]"
							/>
							{prettyMillions(likes)}
						</div>
						<div className="flex flex-row items-center gap-[2px] text-[12px] font-600 text-color-white">
							<img
								src={assetUrl("/player/setup-preview.webp")}
								alt="setups"
								className="h-[14px] w-[14px]"
							/>
							{prettyMillions(setups)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

interface GalleryMasonryProps {
	setGalleryPopupState: (
		gallery: GalleryPopupProps | null,
	) => void
	setPageLinks?: (links: {
		next: string | null
		prev: string | null
	}) => void
}

export function GalleryMasonry(props: GalleryMasonryProps) {
	const { setGalleryPopupState, setPageLinks } = props
	const { query, state } = useGalleryViewContext()
	const lastRowRef = useRef<HTMLDivElement>(null)

	const { t } = useTranslation()

	useInfiniteScroll(query, lastRowRef, 1000)

	const prevQueryKeyComponentsRef = useRef([
		state.applyFilters,
	])

	useEffect(() => {
		const currentQueryKeyComponents = [state.applyFilters]

		if (
			!arraysAreEqual(
				currentQueryKeyComponents,
				prevQueryKeyComponentsRef.current,
			)
		) {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			})

			prevQueryKeyComponentsRef.current =
				currentQueryKeyComponents
		}
	}, [state.applyFilters])

	const flat = useMemo(
		() => flattenAndFilterGallery(query.data?.pages || []),
		[query.data],
	)
	const noTemplates =
		query.isSuccess &&
		!query.isFetching &&
		flat.length === 0

	useEffect(() => {
		const pathname = window.location.pathname
		const urlParams = new URLSearchParams(
			window.location.search,
		)
		const pageParam = urlParams.get("page")
		let page = 1

		if (pageParam !== null) {
			const parsedPage = Number(pageParam)
			if (!isNaN(parsedPage) && parsedPage > 1) {
				page = parsedPage
			}
		}
		let nextPageLink: string | null = null
		let prevPageLink: string | null = null

		const length = query.data?.pages.length ?? 1
		const currentNumber = page - 1 + length

		if (query.hasNextPage) {
			nextPageLink =
				pathname + "?page=" + (currentNumber + 1)
		}

		if (currentNumber > 1) {
			prevPageLink =
				pathname + "?page=" + (currentNumber - 1)
		}

		setPageLinks &&
			setPageLinks({
				next: nextPageLink,
				prev: prevPageLink,
			})
	}, [
		query.data?.pages.length,
		query.hasNextPage,
		setPageLinks,
	])

	return (
		<>
			{query.isSuccess && !noTemplates && (
				<ResponsiveMasonry
					className="mx-auto w-full gap-3 p-4 tablet:w-[768px] desktop:w-[1175px] desktop:px-0 desktop:pt-4"
					columnsCountBreakPoints={{
						1: 2,
						760: 4,
						1280: 5,
					}}>
					<Masonry gutter="12px">
						{flat.map((single, index) => (
							<React.Fragment key={`item-${single.id}`}>
								<SingleGenerated
									entity={single}
									setGalleryPopupState={
										setGalleryPopupState
									}
								/>
								{/* {(index + 1) % 7 === 0 && (
									<div
										key={`ad-${index}`}
										className="w-full overflow-hidden rounded-[6px]">
										<Adsense
											className="block w-full"
											client="ca-pub-6377747033822089"
											slot="4080785237"
											format="auto"
											responsive="true"
											style={{ display: "block" }}
										/>
									</div>
								)} */}
							</React.Fragment>
						))}
					</Masonry>
				</ResponsiveMasonry>
			)}

			<div className="h-[1px] w-full" ref={lastRowRef} />

			<div className="flex w-full justify-center p-8">
				{query.isFetching && <Loader />}
			</div>

			{noTemplates && (
				<LockIconContainer className="pb-32">
					<LockIcon source={"/general/empty-gallery"} />
					{t("txt_no_result_yet")}
				</LockIconContainer>
			)}
		</>
	)
}

interface SearchQueryProps {
	setPageTitle: (id: string | null) => void
	setActive: (state: boolean) => void
	searchByPage: Pages
	setPage: (page: Pages) => void
	setQueryInput: (query: string) => void
}

function SearchQuery(props: SearchQueryProps) {
	const {
		setPageTitle,
		setActive,
		setPage,
		searchByPage,
		setQueryInput,
	} = props
	const { state: galleryState, dispatch: galleryDispatch } =
		useGalleryViewContext()
	const {
		state: templatesState,
		dispatch: templatesDispatch,
	} = useTemplatesViewContext()

	const {
		state: adExamplesState,
		dispatch: adExamplesDispatch,
	} = useAdExamplesViewContext()

	const state =
		searchByPage === "ai-gallery"
			? galleryState
			: searchByPage === "video-templates"
			? templatesState
			: adExamplesState

	const { searchQuery } = state
	const queryClient = useQueryClient()
	const [value, setValue] = useState("")

	useEffect(() => {
		if (searchQuery) {
			setValue(searchQuery)
		}
	}, [searchQuery])

	const submitQuery = (clear?: boolean) => {
		const text = clear ? "" : value

		galleryDispatch({
			type: "set_search_query",
			searchQuery: text.trim().length === 0 ? null : text,
		})
		templatesDispatch({
			type: "set_search_query",
			searchQuery: text.trim().length === 0 ? null : text,
		})
		adExamplesDispatch({
			type: "set_search_query",
			searchQuery: text.trim().length === 0 ? null : text,
		})
		queryClient.invalidateQueries("suggestions")

		if (searchByPage === "ai-gallery") {
			galleryDispatch({
				type: "apply_filters",
				filters: galleryState,
			})
		} else if (searchByPage === "video-templates") {
			templatesDispatch({
				type: "apply_filters",
			})
		} else if (searchByPage === "ad-examples") {
			adExamplesDispatch({
				type: "apply_filters",
			})
		}

		const url = new URL(window.location.href)
		const searchParams = new URLSearchParams(url.search)
		const category = searchParams.get("categoryId")

		const params = category
			? queryfy({
					categoryId: category,
			  })
			: ""

		if (text.trim().length === 0) {
			setPageTitle(null)

			const newUrl = `/${searchByPage}${params}`
			setQueryInput("")
			setPage(searchByPage)
			window.history.pushState({ path: newUrl }, "", newUrl)
		} else {
			setPageTitle(
				text
					.replace("-", " ")
					.replace(/\b\w/g, (match) => match.toUpperCase()),
			)

			const searchUrl = getUrlFromString(text)

			const newUrl = `/${searchByPage}/${encodeURIComponent(
				searchUrl,
			)}${params}`
			setQueryInput(searchUrl)
			setPage(searchByPage)

			window.history.pushState({ path: newUrl }, "", newUrl)
		}
	}

	return (
		<div className="relative flex-1">
			<input
				type="text"
				name="search art"
				id="search art"
				onChange={(e) => setValue(e.currentTarget.value)}
				onFocus={() => {
					setActive(true)
				}}
				onBlur={() => {
					setActive(false)
				}}
				value={value}
				placeholder={
					searchByPage === "ai-gallery"
						? "Search art"
						: searchByPage === "video-templates"
						? "Search templates"
						: "Search ads"
				}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						submitQuery()
					}
				}}
				className={clsx(
					"w-[calc(100%-23px)] border-none bg-[transparent] text-[16px] font-500 text-blue-900",
					"placeholder:text-color-placeholder focus:outline-none",
				)}
			/>

			<button
				onClick={() => {
					setValue("")
					if (searchQuery) {
						submitQuery(true)
					}
				}}
				className={clsx(
					"absolute right-0 top-[calc(50%-11px)] flex h-[22px] w-[22px] items-center justify-center",
					"rounded-full bg-blue-200 transition-opacity [--icon-color:var(--color-blue-600)] hover:bg-blue-300",
					value.trim().length !== 0
						? "opacity-100"
						: "pointer-events-none opacity-0",
				)}>
				{ClearIcon()}
			</button>
		</div>
	)
}

export function SortByTemplatesComponent(props: {
	isDashboard?: boolean
}) {
	const { isDashboard } = props
	const { state, dispatch } = useTemplatesViewContext()
	const { sortBy } = state

	const { t } = useTranslation()

	function setSortBy(sortBy: SortByTemplates) {
		dispatch({ type: "set_sort_by", sortBy })
		dispatch({
			type: "apply_filters",
		})
	}

	const currentLabel = sortLabelsTemplates[sortBy]

	const isFeatured = state.selectedCategory === -1
	const isPriceAll = state.price === "all"
	const isCategoryAll = state.selectedCategory === -2
	const isSearched = state.searchQuery !== null

	const canChange =
		isSearched ||
		(!isCategoryAll && !isFeatured) ||
		(isCategoryAll && !isPriceAll)

	return (
		<DropDown
			hover
			className={clsx(
				"group transition-opacity tablet:h-10 tablet:rounded-[6px] desktop:h-11 desktop:rounded-[10px]",
				!canChange &&
					"pointer-events-none cursor-not-allowed opacity-0",
				isDashboard
					? "bg-blue-50"
					: "bg-color-cell dark:bg-color-background",
			)}
			trigger={
				<div
					className={clsx(
						"flex flex-row items-center justify-between px-4",
						"tablet:h-10 tablet:w-[140px] tablet:rounded-[6px]",
						"desktop:h-11 desktop:w-[175px] desktop:rounded-[10px]",
					)}>
					<p className="text-[14px] font-500 text-blue-700">
						{t(currentLabel)}
					</p>
					<div
						className={clsx(
							"flex -rotate-90 items-center justify-center",
							"transition-all [--icon-color:var(--color-placeholder)] group-hover:-rotate-[270deg]",
						)}>
						<ButtonArrowIcon />
					</div>
				</div>
			}>
			<div className="absolute z-10 pt-2 tablet:w-[140px] desktop:w-[175px]">
				<div className="w-full rounded-[10px] bg-color-popup-cell px-[10px] py-[14px] [box-shadow:0px_0px_10px_rgba(0,0,0,0.13)]">
					<div className="flex w-full flex-col gap-1">
						{sortOptionsTemplates.map((option) => (
							<div
								key={option}
								onClick={() => setSortBy(option)}
								className={clsx(
									"flex w-full cursor-pointer items-center rounded-[8px] py-3 pl-4 text-[14px] font-500 text-blue-800 transition-all",
									sortBy === option
										? "bg-blue-100 hover:bg-blue-100"
										: "hover:bg-color-background",
								)}>
								{t(sortLabelsTemplates[option])}
							</div>
						))}
					</div>
				</div>
			</div>
		</DropDown>
	)
}

export function SortByGallery(props: {
	isColorBackground?: boolean
}) {
	const { isColorBackground } = props
	const { state, dispatch } = useGalleryViewContext()
	const { sortBy } = state

	const { t } = useTranslation()

	function setSortBy(sortBy: SortBy) {
		dispatch({ type: "set_sort_by", sortBy })
		dispatch({
			type: "apply_filters",
			filters: state,
		})
	}

	const currentLabel = sortLabels[sortBy]

	return (
		<DropDown
			hover
			className={clsx(
				"group",
				" tablet:h-10 tablet:rounded-[6px] desktop:h-11 desktop:rounded-[10px]",
				isColorBackground
					? " bg-blue-50"
					: "bg-color-cell dark:bg-color-background",
			)}
			trigger={
				<div
					className={clsx(
						"flex flex-row items-center justify-between px-4",
						"tablet:h-10 tablet:w-[140px] tablet:rounded-[6px]",
						"desktop:h-11 desktop:w-[175px] desktop:rounded-[10px]",
					)}>
					<p className="text-[14px] font-500 text-blue-700">
						{t(currentLabel)}
					</p>
					<div
						className={clsx(
							"flex -rotate-90 items-center justify-center",
							"transition-all [--icon-color:var(--color-placeholder)] group-hover:-rotate-[270deg]",
						)}>
						<ButtonArrowIcon />
					</div>
				</div>
			}>
			<div className="absolute z-10 pt-2 tablet:w-[140px] desktop:w-[175px]">
				<div className="w-full rounded-[10px] bg-color-popup-cell px-[10px] py-[14px] [box-shadow:0px_0px_10px_rgba(0,0,0,0.13)]">
					<div className="flex w-full flex-col gap-1">
						{sortOptions.map((option) => (
							<div
								key={option}
								onClick={() => setSortBy(option)}
								className={clsx(
									"flex w-full cursor-pointer items-center rounded-[8px] py-3 pl-4 text-[14px] font-500 text-blue-800 transition-all hover:bg-blue-100",
									sortBy === option && "bg-blue-100",
								)}>
								{t(sortLabels[option])}
							</div>
						))}
					</div>
				</div>
			</div>
		</DropDown>
	)
}

interface SearchByProps {
	setSearchByPage: (page: Pages) => void
	searchByPage: Pages
}

export function SearchBy(props: SearchByProps) {
	const { searchByPage, setSearchByPage } = props

	const { t } = useTranslation()

	return (
		<DropDown
			hover
			className="group hidden h-full tablet:block"
			trigger={
				<div
					className={clsx(
						"flex w-[120px] flex-row items-center justify-between gap-[6px] pl-[14px]",
					)}>
					<p className="flex-1 text-center text-[15px] font-500 text-blue-700">
						{t(`lbl_${searchByPage.replaceAll("-", "_")}`)}
					</p>
					<div
						className={clsx(
							"mr-1 flex -rotate-90 items-center justify-center",
							"transition-all [--icon-color:var(--color-placeholder)] group-hover:-rotate-[270deg]",
						)}>
						<ButtonArrowIcon />
					</div>
				</div>
			}>
			<div className="absolute z-10 w-[134px] pt-5">
				<div
					className={clsx(
						"flex flex-col items-center gap-1 rounded-[12px] bg-color-popup-cell",
						"px-[10px] py-[14px] [box-shadow:0px_0px_10px_rgba(0,0,0,0.13)]",
					)}>
					<div
						onClick={() => setSearchByPage("ai-gallery")}
						className={clsx(
							"flex w-full cursor-pointer py-2 pl-4",
							"rounded-[8px] text-[14px] font-500 text-blue-800 transition-all",
							searchByPage === "ai-gallery"
								? "bg-blue-100 hover:bg-blue-100"
								: "hover:bg-color-background",
						)}>
						{t("lbl_ai_gallery")}
					</div>
					<div
						onClick={() =>
							setSearchByPage("video-templates")
						}
						className={clsx(
							"flex w-full cursor-pointer py-2 pl-4",
							"rounded-[8px] text-[14px] font-500 text-blue-800 transition-all",
							searchByPage === "video-templates"
								? "bg-blue-100 hover:bg-blue-100"
								: "hover:bg-color-background",
						)}>
						{t("lbl_templates")}
					</div>
					<div
						onClick={() => setSearchByPage("ad-examples")}
						className={clsx(
							"flex w-full cursor-pointer py-2 pl-4",
							"rounded-[8px] text-[14px] font-500 text-blue-800 transition-all",
							searchByPage === "ad-examples"
								? "bg-blue-100 hover:bg-blue-100"
								: "hover:bg-color-background",
						)}>
						{t("lbl_ad_examples")}
					</div>
				</div>
			</div>
		</DropDown>
	)
}

export function AvailableFiltersGallery(props: {
	isColorBackground?: boolean
}) {
	const { isColorBackground } = props
	const { dispatch, state } = useGalleryViewContext()

	const { mediaType } = state

	const { t } = useTranslation()

	const { availableFilters } = useGalleryViewContext()

	const styles = availableFilters.styles

	const uniqueFilters = styles
		.filter((style) =>
			state.selectedTools.includes(style.tool),
		)
		.map((style) => style.name)

	const filteredStyles = Array.from(new Set(uniqueFilters))

	const uniqueTools = Array.from(
		new Set(styles.map((style) => style.tool)),
	)

	const commonTools = galleryProducts.filter((tool) =>
		uniqueTools.some((product) => product === tool.tool),
	)

	const setStyle = (name: string) => {
		if (
			state.selectedStyles.find(
				(style) => style.name === name,
			)
		) {
			dispatch({
				type: "remove_style",
				name: name,
			})

			return
		}
		dispatch({
			type: "select_style",
			name: name,
			tools: commonTools.map((tool) => tool.tool),
		})
	}

	const setTool = (tool: GalleryTools) => {
		if (
			state.selectedTools.find(
				(selected) => selected === tool,
			)
		) {
			dispatch({
				type: "remove_tool",
				tool,
			})

			return
		}
		dispatch({
			type: "select_tool",
			tool,
		})
	}

	const selectCustom = (custom: boolean) => {
		dispatch({ type: "set_include_custom", state: custom })
	}

	const clearStyle = () => {
		dispatch({ type: "clear_style" })
	}

	const clearAll = () => {
		dispatch({ type: "clear_all" })
	}

	const apply = () => {
		dispatch({
			type: "apply_filters",
			filters: state,
		})
	}
	const clearTools = () => {
		dispatch({ type: "clear_tools" })
	}

	function setMediaType(mediaType: MediaType) {
		dispatch({ type: "set_media_type", mediaType })
	}

	return (
		<DropDown
			closeOnClick
			className={clsx(
				"group",
				"tablet:h-10 tablet:rounded-[6px] desktop:h-11 desktop:rounded-[10px]",
				isColorBackground
					? "bg-blue-50"
					: "bg-color-cell dark:bg-color-background",
			)}
			trigger={
				<div
					className={clsx(
						"flex flex-row items-center justify-center [--icon-color:var(--color-blue-600)]",
						"tablet:h-10 tablet:w-10 tablet:rounded-[6px]",
						"hover:bg-blue-100 desktop:h-11 desktop:w-11 desktop:rounded-[10px]",
					)}>
					<FilterIcon />
				</div>
			}>
			<div className="absolute z-50 -translate-x-[calc(100%-44px)] pt-2 tablet:w-[349px]">
				<div className="flex w-full flex-col gap-[18px] rounded-[13px] bg-color-popup-cell px-4 py-3 [box-shadow:0px_0px_10px_rgba(0,0,0,0.13)]">
					{/* Media Type Section */}
					<div className="flex w-full flex-col gap-3">
						<div className="flex w-full flex-row items-center justify-between">
							<p className="text-[14px] font-600 text-blue-800">
								{t("lbl_media_type")}
							</p>
							<button
								onClick={(e) => {
									e.preventDefault()
									setMediaType("all")
								}}
								className="text-[12px] font-600 text-blue-500">
								{t("txt_clear")}
							</button>
						</div>
						<div className="flex w-full flex-wrap gap-[10px]">
							<button
								onClick={(e) => {
									e.preventDefault()
									setMediaType("all")
								}}
								className={clsx(
									"flex h-8 flex-row items-center justify-center gap-3 rounded-[6px] border pl-[7px] pr-[14px] text-[12px] font-500 transition-all",
									mediaType === "all"
										? "border-blue-100 bg-blue-100 text-blue-800"
										: "border-color-separator text-blue-700",
								)}>
								<div
									className={clsx(
										"flex h-4 w-4 items-center justify-center rounded-full border-[1.2px] transition-all",
										mediaType === "all"
											? "border-primary-500"
											: "border-[#9195AB]",
									)}>
									<div
										className={clsx(
											"h-2 w-2 rounded-full bg-primary-500 transition-opacity",
											mediaType === "all"
												? "opacity-100"
												: "opacity-0",
										)}></div>
								</div>
								{t("lbl_all")}
							</button>
							<button
								onClick={(e) => {
									e.preventDefault()
									setMediaType("image")
								}}
								className={clsx(
									"flex h-8 flex-row items-center justify-center gap-3 rounded-[6px] border pl-[7px] pr-[14px] text-[12px] font-500 transition-all",
									mediaType === "image"
										? "border-blue-100 bg-blue-100 text-blue-800"
										: "border-color-separator text-blue-700",
								)}>
								<div
									className={clsx(
										"flex h-4 w-4 items-center justify-center rounded-full border-[1.2px] transition-all",
										mediaType === "image"
											? "border-primary-500"
											: "border-[#9195AB]",
									)}>
									<div
										className={clsx(
											"h-2 w-2 rounded-full bg-primary-500 transition-opacity",
											mediaType === "image"
												? "opacity-100"
												: "opacity-0",
										)}></div>
								</div>
								{t("lbl_image")}
							</button>
							<button
								onClick={(e) => {
									e.preventDefault()
									setMediaType("video")
								}}
								className={clsx(
									"flex h-8 flex-row items-center justify-center gap-3 rounded-[6px] border pl-[7px] pr-[14px] text-[12px] font-500 transition-all",
									mediaType === "video"
										? "border-blue-100 bg-blue-100 text-blue-800"
										: "border-color-separator text-blue-700",
								)}>
								<div
									className={clsx(
										"flex h-4 w-4 items-center justify-center rounded-full border-[1.2px] transition-all",
										mediaType === "video"
											? "border-primary-500"
											: "border-[#9195AB]",
									)}>
									<div
										className={clsx(
											"h-2 w-2 rounded-full bg-primary-500 transition-opacity",
											mediaType === "video"
												? "opacity-100"
												: "opacity-0",
										)}></div>
								</div>
								{t("lbl_video")}
							</button>
						</div>
					</div>

					<div className="h-[1px] w-full border-b border-color-separator" />

					{/* Tools Section */}
					<div className="flex w-full flex-col gap-3">
						<div className="flex w-full flex-row items-center justify-between">
							<p className="text-[14px] font-600 text-blue-800">
								{t("lbl_ai_tools")}
							</p>
							<button
								onClick={(e) => {
									e.preventDefault()
									clearTools()
								}}
								className="text-[12px] font-600 text-blue-500">
								{t("txt_clear")}
							</button>
						</div>
						<div className="flex w-full flex-wrap gap-[10px]">
							{galleryProducts.map((tool, index) => (
								<button
									key={index}
									onClick={(e) => {
										e.preventDefault()
										setTool(tool.tool)
									}}
									className={clsx(
										" flex flex-row items-center gap-1 rounded-[6px] border px-3 py-[6px] text-[12px] font-500 [--icon-color:var(--color-blue-600)]",
										state.selectedTools.some(
											(selected) => selected === tool.tool,
										)
											? "border-blue-300 bg-blue-300 text-blue-800"
											: "border-color-separator text-blue-700",
									)}>
									{tool.icon}
									{t(tool.label)}
								</button>
							))}
						</div>
					</div>

					<div className="h-[1px] w-full border-b border-color-separator" />

					{/* Styles Section */}
					<div className="flex w-full flex-col gap-3">
						<div className="flex w-full flex-row items-center justify-between">
							<p className="text-[14px] font-600 text-blue-800">
								{t("lbl_style")}
							</p>
							<button
								onClick={() => clearStyle()}
								className="text-[12px] font-600 text-blue-500">
								{t("txt_clear")}
							</button>
						</div>
						<div className="no-scrollbar flex max-h-[142px] w-full flex-wrap gap-[10px] overflow-y-auto">
							<div
								onClick={(e) => {
									e.preventDefault()
									selectCustom(!state.includeCustoms)
								}}
								className={clsx(
									" flex cursor-pointer select-none flex-row items-center gap-1 rounded-[6px] border px-3 py-[6px] text-[12px] font-500 transition-all [--icon-color:var(--color-blue-700)]",
									state.includeCustoms
										? "border-blue-300 bg-blue-300 text-blue-800"
										: "border-color-separator text-blue-700",
								)}>
								{t("txt_custom")}
							</div>
							{filteredStyles.map((name, index) => (
								<div
									key={index}
									onClick={(e) => {
										e.preventDefault()
										setStyle(name)
									}}
									className={clsx(
										" flex cursor-pointer select-none flex-row items-center gap-1 rounded-[6px] border px-3 py-[6px] text-[12px] font-500 transition-all [--icon-color:var(--color-blue-700)]",
										state.selectedStyles.some(
											(style) => style.name === name,
										)
											? "border-blue-300 bg-blue-300 text-blue-800"
											: "border-color-separator text-blue-700",
									)}>
									{t(name)}
								</div>
							))}
						</div>
					</div>

					{/* Apply Section */}
					<div className="flex h-11 w-full flex-row gap-[10px]">
						<button
							onClick={(e) => {
								e.preventDefault()
								clearAll()
							}}
							className="h-full cursor-pointer px-[18px] py-3 text-[16px] font-600 text-blue-500">
							{t("txt_clear_all")}
						</button>
						<Button
							onClick={apply}
							text={t("lbl_apply")}
							className="h-full flex-1"></Button>
					</div>
				</div>
			</div>
		</DropDown>
	)
}

interface AvailableFiltersTemplatesProps {
	initialFilters: FilterStateTemplates
	isDashboard?: boolean
}

export function AvailableFiltersTemplatesComponent(
	props: AvailableFiltersTemplatesProps,
) {
	const { initialFilters, isDashboard } = props
	const { dispatch, state, availableFilters } =
		useTemplatesViewContext()

	const { selectedCategory, price } = state

	const categories = availableFilters.categories

	const { t } = useTranslation()

	const initialName =
		selectedCategory === -1
			? t("txt_featured")
			: selectedCategory === -2
			? t("txt_all")
			: categories.find(
					(cat) => cat.id === selectedCategory,
			  )?.name || "All"

	const [selectedName, setSelectedName] =
		useState(initialName)

	useEffect(() => {
		const newName =
			selectedCategory === -1
				? t("txt_featured")
				: selectedCategory === -2
				? t("txt_all")
				: categories.find(
						(cat) => cat.id === selectedCategory,
				  )?.name || "All"

		setSelectedName(newName)
	}, [selectedCategory, categories, t])

	const selectCategory = (id: number, name: string) => {
		setSelectedName(name)
		dispatch({ type: "select_category", id })
	}

	const setPrice = (price: PriceTypeTemplates) => {
		dispatch({ type: "select_price", price })
	}

	const clearAll = () => {
		dispatch({ type: "clear_all" })

		const category = initialFilters.selectedCategory

		const params = category
			? queryfy({
					categoryId: category,
			  })
			: ""

		if (!state.searchQuery) {
			if (!isDashboard) {
				const newUrl = `/video-templates${params}`
				window.history.pushState(
					{ path: newUrl },
					"",
					newUrl,
				)
			} else {
				const query = category
					? `&categoryId=${category}`
					: ""
				const newUrl = `/discover?filter=video-templates${query}`
				window.history.pushState(
					{ path: newUrl },
					"",
					newUrl,
				)
			}
		} else {
			if (!isDashboard) {
				const newUrl = `/video-templates/${getUrlFromString(
					state.searchQuery,
				)}${params}`

				window.history.pushState(
					{ path: newUrl },
					"",
					newUrl,
				)
			} else {
				const categoryQuery = category
					? `&categoryId=${category}`
					: ""
				const query = `&q=${getUrlFromString(
					state.searchQuery,
				)}`
				const newUrl = `/discover?filter=video-templates${query}${categoryQuery}`
				window.history.pushState(
					{ path: newUrl },
					"",
					newUrl,
				)
			}
		}
	}

	const apply = () => {
		dispatch({
			type: "apply_filters",
		})

		const category = state.selectedCategory

		const params = category
			? queryfy({
					categoryId: category,
			  })
			: ""

		if (!state.searchQuery) {
			if (!isDashboard) {
				const newUrl = `/video-templates${params}`
				window.history.pushState(
					{ path: newUrl },
					"",
					newUrl,
				)
			} else {
				const query = category
					? `&categoryId=${category}`
					: ""
				const newUrl = `/discover?filter=video-templates${query}`
				window.history.pushState(
					{ path: newUrl },
					"",
					newUrl,
				)
			}
		} else {
			if (!isDashboard) {
				const newUrl = `/video-templates/${getUrlFromString(
					state.searchQuery,
				)}${params}`

				window.history.pushState(
					{ path: newUrl },
					"",
					newUrl,
				)
			} else {
				const categoryQuery = category
					? `&categoryId=${category}`
					: ""
				const query = `&q=${getUrlFromString(
					state.searchQuery,
				)}`
				const newUrl = `/discover?filter=video-templates${query}${categoryQuery}`
				window.history.pushState(
					{ path: newUrl },
					"",
					newUrl,
				)
			}
		}
	}
	const clearCategories = () => {
		dispatch({ type: "select_category", id: -2 })
	}

	const isFilterSelected =
		initialFilters.selectedCategory !== selectedCategory ||
		initialFilters.price !== price

	return (
		<DropDown
			className={clsx(
				"tablet:h-10 tablet:rounded-[6px] desktop:h-11 desktop:rounded-[10px]",
				isDashboard
					? "bg-blue-50"
					: "bg-color-cell dark:bg-color-background",
			)}
			trigger={
				<div
					className={clsx(
						"relative flex flex-row items-center justify-center [--icon-color:var(--color-blue-600)]",
						"tablet:h-10 tablet:w-10 tablet:rounded-[6px]",
						"hover:bg-blue-100 desktop:h-11 desktop:w-11 desktop:rounded-[10px]",
					)}>
					<FilterIcon />
					<div
						className={clsx(
							"absolute right-[-2px] top-[-1px] h-[8px] w-[8px] rounded-full bg-primary-500 transition-opacity",
							!isFilterSelected && "opacity-0",
						)}
					/>
				</div>
			}>
			<div className="absolute z-50 -translate-x-[calc(100%-44px)] pt-2 tablet:w-[349px]">
				<div className="flex w-full flex-col gap-[18px] rounded-[13px] bg-color-popup-cell px-4 py-3 [box-shadow:0px_0px_10px_rgba(0,0,0,0.13)]">
					<div className="flex w-full flex-col gap-3">
						<div className="flex w-full flex-row items-center justify-between">
							<p className="text-[14px] font-600 text-blue-800">
								{t("lbl_categories")}
							</p>
							<button
								onClick={(e) => {
									e.preventDefault()
									clearCategories()
								}}
								className="text-[12px] font-600 text-blue-500">
								{t("txt_clear")}
							</button>
						</div>

						<DropDown
							closeOnClick
							className="group h-10 flex-1"
							trigger={
								<div
									className={clsx(
										"flex h-10 w-[317px] flex-row items-center justify-between gap-[6px] rounded-[6px] px-3 py-2",
										isDashboard
											? " bg-color-surface"
											: "bg-color-background",
									)}>
									<span className="flex-1 text-start text-[14px] font-400 text-blue-600">
										{selectedName}
									</span>
									<div
										className={clsx(
											"mr-1 flex -rotate-90 items-center justify-center",
											"transition-all [--icon-color:var(--color-placeholder)] group-hover:-rotate-[270deg]",
										)}>
										<ButtonArrowIcon />
									</div>
								</div>
							}>
							<div className="absolute z-10 w-[317px] pt-2">
								<div
									className={clsx(
										"no-scrollbar flex max-h-[200px] flex-col items-center gap-1 overflow-y-scroll rounded-[12px] bg-blue-100",
										"px-[10px] py-[14px]",
									)}>
									<button
										onClick={(e) => {
											e.preventDefault()
											selectCategory(-2, t("txt_all"))
										}}
										className={clsx(
											"flex w-full cursor-pointer py-2 pl-4",
											"rounded-[8px] text-[14px] font-500 text-blue-800 transition-all hover:bg-blue-200",
											selectedCategory === -2 &&
												"bg-blue-200",
										)}>
										{t("txt_all")}
									</button>
									<button
										onClick={(e) => {
											e.preventDefault()
											selectCategory(-1, t("txt_featured"))
										}}
										className={clsx(
											"flex w-full cursor-pointer py-2 pl-4",
											"rounded-[8px] text-[14px] font-500 text-blue-800 transition-all hover:bg-blue-200",
											selectedCategory === -1 &&
												"bg-blue-200",
										)}>
										{t("txt_featured")}
									</button>
									{categories.map((category) => (
										<button
											key={category.id}
											onClick={(e) => {
												e.preventDefault()
												selectCategory(
													category.id,
													category.name,
												)
											}}
											className={clsx(
												"flex w-full cursor-pointer py-2 pl-4",
												"rounded-[8px] text-[14px] font-500 text-blue-800 transition-all hover:bg-blue-200",
												selectedCategory === category.id &&
													"bg-blue-200",
											)}>
											{category.name}
										</button>
									))}
								</div>
							</div>
						</DropDown>
					</div>

					<div className="h-[1px] w-full border-b border-color-separator" />

					<div className="flex w-full flex-col gap-3">
						<div className="flex w-full flex-row items-center justify-between">
							<p className="text-[14px] font-600 text-blue-800">
								{t("lbl_price")}
							</p>
							<button
								onClick={(e) => {
									e.preventDefault()
									setPrice("all")
								}}
								className="text-[12px] font-600 text-blue-500">
								{t("txt_clear")}
							</button>
						</div>
						<div
							className={clsx(
								"flex w-full flex-wrap gap-[10px]",
								selectedCategory === -1 &&
									"pointer-events-none opacity-50",
							)}>
							{priceOptionsTemplates.map((option) => (
								<button
									key={option}
									onClick={(e) => {
										e.preventDefault()
										setPrice(option)
									}}
									className={clsx(
										"flex h-11 flex-row items-center justify-center gap-3 rounded-[6px] border pl-[7px] pr-[14px] text-[12px] font-500 transition-all",
										price === option
											? "border-blue-100 bg-blue-100 text-blue-800"
											: "border-color-separator text-blue-700",
									)}>
									<div
										className={clsx(
											"flex h-4 w-4 items-center justify-center rounded-full border-[1.2px] transition-all",
											price === option
												? "border-primary-500"
												: "border-[#9195AB]",
										)}>
										<div
											className={clsx(
												"h-2 w-2 rounded-full bg-primary-500 transition-opacity",
												price === option
													? "opacity-100"
													: "opacity-0",
											)}></div>
									</div>
									{t(priceLablesTemplates[option])}
								</button>
							))}
						</div>
					</div>

					<div className="flex h-11 w-full flex-row gap-[10px]">
						<button
							onClick={(e) => {
								e.preventDefault()
								clearAll()
							}}
							className="h-full cursor-pointer px-[18px] py-3 text-[16px] font-600 text-blue-500">
							{t("txt_clear_all")}
						</button>
						<Button
							onClick={apply}
							text={t("lbl_apply")}
							className="h-full flex-1"></Button>
					</div>
				</div>
			</div>
		</DropDown>
	)
}

interface FilterDialogMobileProps {
	filterDialogOpen: boolean
	setFilterDialogOpen: (open: boolean) => void
	setCategoryDialogOpen: (open: boolean) => void
	setIsFilterSelected: (selected: boolean) => void
	page: Pages
	initialFilters: FilterStateTemplates
}

export function FilterDialogMobile(
	props: FilterDialogMobileProps,
) {
	const {
		filterDialogOpen,
		setFilterDialogOpen,
		page,
		setCategoryDialogOpen,
		setIsFilterSelected,
		initialFilters,
	} = props
	if (page === "ai-gallery") {
		return (
			<FilterDialogGallery
				filterDialogOpen={filterDialogOpen}
				setFilterDialogOpen={setFilterDialogOpen}
			/>
		)
	} else if (page === "video-templates") {
		return (
			<FilterDialogTemplates
				filterDialogOpen={filterDialogOpen}
				setFilterDialogOpen={setFilterDialogOpen}
				setCategoryDialogOpen={setCategoryDialogOpen}
				setIsFilterSelected={setIsFilterSelected}
				initialFilters={initialFilters}
			/>
		)
	} else {
		return (
			<FilterDialogAdExamples
				isOpen={filterDialogOpen}
				setIsOpen={setFilterDialogOpen}
			/>
		)
	}
}

interface FilterDialogGalleryProps {
	filterDialogOpen: boolean
	setFilterDialogOpen: (open: boolean) => void
}

function FilterDialogGallery(
	props: FilterDialogGalleryProps,
) {
	const { filterDialogOpen, setFilterDialogOpen } = props
	const { dispatch, state } = useGalleryViewContext()

	const { t } = useTranslation()

	const { availableFilters } = useGalleryViewContext()
	const { mediaType, sortBy } = state

	const styles = availableFilters.styles

	const uniqueFilters = styles
		.filter((style) =>
			state.selectedTools.includes(style.tool),
		)
		.map((style) => style.name)

	const filteredStyles = Array.from(new Set(uniqueFilters))

	const uniqueTools = Array.from(
		new Set(styles.map((style) => style.tool)),
	)

	const commonTools = galleryProducts.filter((tool) =>
		uniqueTools.some((product) => product === tool.tool),
	)

	function setMediaType(mediaType: MediaType) {
		dispatch({ type: "set_media_type", mediaType })
	}

	function setSortBy(sortBy: SortBy) {
		dispatch({ type: "set_sort_by", sortBy })
	}

	const setStyle = (name: string) => {
		if (
			state.selectedStyles.find(
				(style) => style.name === name,
			)
		) {
			dispatch({
				type: "remove_style",
				name: name,
			})

			return
		}
		dispatch({
			type: "select_style",
			name: name,
			tools: commonTools.map((tool) => tool.tool),
		})
	}

	const setTool = (tool: GalleryTools) => {
		if (
			state.selectedTools.find(
				(selected) => selected === tool,
			)
		) {
			dispatch({
				type: "remove_tool",
				tool,
			})

			return
		}
		dispatch({
			type: "select_tool",
			tool,
		})
	}

	const selectCustom = (custom: boolean) => {
		dispatch({ type: "set_include_custom", state: custom })
	}

	const clearStyle = () => {
		dispatch({ type: "clear_style" })
	}

	const clearTools = () => {
		dispatch({ type: "clear_tools" })
	}

	const clearAll = () => {
		dispatch({ type: "clear_all" })
	}

	const apply = () => {
		dispatch({
			type: "apply_filters",
			filters: state,
		})
	}

	return (
		<>
			<div
				className={clsx(
					"fixed inset-0 flex items-end justify-start bg-color-popup tablet:hidden",
					!filterDialogOpen &&
						"pointer-events-none opacity-0",
					"z-[99] transition-opacity duration-300",
				)}
				id="filter-popup"
				onClick={(event) => {
					if (event.target instanceof Element) {
						if (
							event.target.id === event.currentTarget.id
						) {
							setFilterDialogOpen(false)
						}
					}
				}}>
				<div
					className={clsx(
						"relative h-[564px] w-full rounded-t-[12px] bg-color-popup-cell pt-3 transition-all",
						filterDialogOpen
							? "translate-y-0"
							: "translate-y-full",
					)}>
					<div className="no-scrollbar flex h-[487px] w-full flex-col gap-[18px] overflow-y-auto rounded-[13px] bg-color-popup-cell px-4 py-2">
						{/* Media Type Section */}
						<div className="flex w-full flex-col gap-3">
							<div className="flex w-full flex-row items-center justify-between">
								<p className="text-[14px] font-600 text-blue-800">
									{t("lbl_media_type")}
								</p>
								<button
									onClick={(e) => {
										e.preventDefault()
										setMediaType("all")
									}}
									className="text-[12px] font-600 text-blue-500">
									{t("txt_clear")}
								</button>
							</div>
							<div className="flex w-full flex-wrap gap-[10px]">
								<button
									onClick={(e) => {
										e.preventDefault()
										setMediaType("all")
									}}
									className={clsx(
										"flex h-11 flex-row items-center justify-center gap-3 rounded-[6px] border pl-[7px] pr-[14px] text-[12px] font-500 transition-all",
										mediaType === "all"
											? "border-blue-100 bg-blue-100 text-blue-800"
											: "border-color-separator text-blue-700",
									)}>
									<div
										className={clsx(
											"flex h-4 w-4 items-center justify-center rounded-full border-[1.2px] transition-all",
											mediaType === "all"
												? "border-primary-500"
												: "border-[#9195AB]",
										)}>
										<div
											className={clsx(
												"h-2 w-2 rounded-full bg-primary-500 transition-opacity",
												mediaType === "all"
													? "opacity-100"
													: "opacity-0",
											)}></div>
									</div>
									{t("lbl_all")}
								</button>
								<button
									id="image-media"
									onClick={(e) => {
										e.preventDefault()
										setMediaType("image")
									}}
									className={clsx(
										"flex h-11 flex-row items-center justify-center gap-3 rounded-[6px] border pl-[7px] pr-[14px] text-[12px] font-500 transition-all",
										mediaType === "image"
											? "border-blue-100 bg-blue-100 text-blue-800"
											: "border-color-separator text-blue-700",
									)}>
									<div
										className={clsx(
											"flex h-4 w-4 items-center justify-center rounded-full border-[1.2px] transition-all",
											mediaType === "image"
												? "border-primary-500"
												: "border-[#9195AB]",
										)}>
										<div
											className={clsx(
												"h-2 w-2 rounded-full bg-primary-500 transition-opacity",
												mediaType === "image"
													? "opacity-100"
													: "opacity-0",
											)}></div>
									</div>
									{t("lbl_image")}
								</button>
								<button
									id="video-media"
									onClick={(e) => {
										e.preventDefault()
										setMediaType("video")
									}}
									className={clsx(
										"flex h-11 flex-row items-center justify-center gap-3 rounded-[6px] border pl-[7px] pr-[14px] text-[12px] font-500 transition-all",
										mediaType === "video"
											? "border-blue-100 bg-blue-100 text-blue-800"
											: "border-color-separator text-blue-700",
									)}>
									<div
										className={clsx(
											"flex h-4 w-4 items-center justify-center rounded-full border-[1.2px] transition-all",
											mediaType === "video"
												? "border-primary-500"
												: "border-[#9195AB]",
										)}>
										<div
											className={clsx(
												"h-2 w-2 rounded-full bg-primary-500 transition-opacity",
												mediaType === "video"
													? "opacity-100"
													: "opacity-0",
											)}></div>
									</div>
									{t("lbl_video")}
								</button>
							</div>
						</div>

						{/* Sort By Section */}
						<div className="flex w-full flex-col gap-3">
							<div className="flex w-full flex-row items-center justify-between">
								<p className="text-[14px] font-600 text-blue-800">
									{t("lbl_sort_by")}
								</p>
								<button
									onClick={(e) => {
										e.preventDefault()
										setSortBy("newest")
									}}
									className="text-[12px] font-600 text-blue-500">
									{t("txt_clear")}
								</button>
							</div>
							<div className="flex w-full flex-wrap gap-[10px]">
								{sortOptions.map((option) => (
									<button
										id={option}
										key={option}
										onClick={(e) => {
											e.preventDefault()
											setSortBy(option)
										}}
										className={clsx(
											"flex h-11 flex-row items-center justify-center gap-3 rounded-[6px] border pl-[7px] pr-[14px] text-[12px] font-500 transition-all",
											sortBy === option
												? "border-blue-100 bg-blue-100 text-blue-800"
												: "border-color-separator text-blue-700",
										)}>
										<div
											className={clsx(
												"flex h-4 w-4 items-center justify-center rounded-full border-[1.2px] transition-all",
												sortBy === option
													? "border-primary-500"
													: "border-[#9195AB]",
											)}>
											<div
												className={clsx(
													"h-2 w-2 rounded-full bg-primary-500 transition-opacity",
													sortBy === option
														? "opacity-100"
														: "opacity-0",
												)}></div>
										</div>
										{t(sortLabels[option])}
									</button>
								))}
							</div>
						</div>

						{/* Tools Section */}
						<div className="flex w-full flex-col gap-3">
							<div className="flex w-full flex-row items-center justify-between">
								<p className="text-[14px] font-600 text-blue-800">
									{t("lbl_ai_tools")}
								</p>
								<button
									onClick={(e) => {
										e.preventDefault()
										clearTools()
									}}
									className="text-[12px] font-600 text-blue-500">
									{t("txt_clear")}
								</button>
							</div>
							<div className="flex w-full flex-wrap gap-[10px]">
								{galleryProducts.map((tool, index) => (
									<button
										id={tool.tool}
										key={index}
										onClick={(e) => {
											e.preventDefault()
											setTool(tool.tool)
										}}
										className={clsx(
											" flex flex-row items-center gap-1 rounded-[6px] border px-3 py-3 text-[12px] font-500 transition-all",
											state.selectedTools.some(
												(selected) =>
													selected === tool.tool,
											)
												? "border-blue-100 bg-blue-100 text-blue-800 [--icon-color:var(--color-blue-700)]"
												: "border-color-separator text-blue-700 [--icon-color:var(--color-blue-600)]",
										)}>
										{tool.icon}
										{t(tool.label)}
									</button>
								))}
							</div>
						</div>

						{/* Styles Section */}
						<div className="flex w-full flex-col gap-3">
							<div className="flex w-full flex-row items-center justify-between">
								<p className="text-[14px] font-600 text-blue-800">
									{t("lbl_style")}
								</p>
								<button
									onClick={() => clearStyle()}
									className="text-[12px] font-600 text-blue-500">
									{t("txt_clear")}
								</button>
							</div>
							<div className="no-scrollbar flex max-h-[184px] w-full flex-wrap gap-[10px] overflow-y-auto pb-2">
								<div
									onClick={(e) => {
										e.preventDefault()
										selectCustom(!state.includeCustoms)
									}}
									className={clsx(
										" flex cursor-pointer select-none flex-row items-center gap-1 rounded-[6px] border px-3 py-3 text-[12px] font-500 transition-all [--icon-color:var(--color-blue-700)]",
										state.includeCustoms
											? "border-blue-100 bg-blue-100 text-blue-800"
											: "border-color-separator text-blue-700",
									)}>
									{t("txt_custom")}
								</div>
								{filteredStyles.map((name, index) => (
									<div
										id={name}
										key={index}
										onClick={(e) => {
											e.preventDefault()
											setStyle(name)
										}}
										className={clsx(
											" flex cursor-pointer select-none flex-row items-center gap-1 rounded-[6px] border px-3 py-3 text-[12px] font-500 transition-all [--icon-color:var(--color-blue-700)]",
											state.selectedStyles.some(
												(style) => style.name === name,
											)
												? "border-blue-100 bg-blue-100 text-blue-800"
												: "border-color-separator text-blue-700",
										)}>
										{t(name)}
									</div>
								))}
							</div>
						</div>
					</div>

					<div className="fixed bottom-0 left-0 w-full border-t border-color-separator bg-color-popup-cell px-4 py-[14px]">
						<div className="flex h-11 w-full flex-row gap-[10px]">
							<button
								id="clear-all-filters"
								onClick={(e) => {
									e.preventDefault()
									clearAll()
								}}
								className="h-full px-[18px] py-3 text-[16px] font-600 text-blue-500">
								{t("txt_clear_all")}
							</button>
							<Button
								onClick={() => {
									apply()
									setFilterDialogOpen(false)
								}}
								text={t("lbl_apply")}
								className="h-full flex-1"></Button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

interface FilterDialogTemplatesProps {
	filterDialogOpen: boolean
	setFilterDialogOpen: (open: boolean) => void
	setCategoryDialogOpen: (open: boolean) => void
	setIsFilterSelected: (selected: boolean) => void
	initialFilters: FilterStateTemplates
}

function FilterDialogTemplates(
	props: FilterDialogTemplatesProps,
) {
	const {
		filterDialogOpen,
		setFilterDialogOpen,
		setCategoryDialogOpen,
		setIsFilterSelected,
		initialFilters,
	} = props
	const { dispatch, state, availableFilters } =
		useTemplatesViewContext()

	const { t } = useTranslation()

	const { sortBy, selectedCategory, price } = state
	const categories = availableFilters.categories

	const initialName =
		selectedCategory === -1
			? t("txt_featured")
			: selectedCategory === -2
			? t("txt_all")
			: categories.find(
					(cat) => cat.id === selectedCategory,
			  )?.name || "All"

	const [selectedName, setSelectedName] =
		useState(initialName)

	useEffect(() => {
		const newName =
			selectedCategory === -1
				? t("txt_featured")
				: selectedCategory === -2
				? t("txt_all")
				: categories.find(
						(cat) => cat.id === selectedCategory,
				  )?.name || "All"

		setSelectedName(newName)
	}, [selectedCategory, categories, t])

	function setSortBy(sortBy: SortByTemplates) {
		dispatch({ type: "set_sort_by", sortBy })
	}

	const setPrice = (price: PriceTypeTemplates) => {
		dispatch({ type: "select_price", price })
	}

	const selectCategory = (id: number, name: string) => {
		setSelectedName(name)
		dispatch({ type: "select_category", id })
	}

	const clearAll = () => {
		dispatch({ type: "clear_all" })

		const category = initialFilters.selectedCategory

		const params = category
			? queryfy({
					categoryId: category,
			  })
			: ""

		if (!state.searchQuery) {
			const newUrl = `/video-templates${params}`
			window.history.pushState({ path: newUrl }, "", newUrl)
		} else {
			const newUrl = `/video-templates/${getUrlFromString(
				state.searchQuery,
			)}${params}`

			window.history.pushState({ path: newUrl }, "", newUrl)
		}

		setIsFilterSelected(false)
	}

	const apply = () => {
		dispatch({
			type: "apply_filters",
		})

		const category = state.selectedCategory

		const params = category
			? queryfy({
					categoryId: category,
			  })
			: ""

		if (!state.searchQuery) {
			const newUrl = `/video-templates${params}`
			window.history.pushState({ path: newUrl }, "", newUrl)
		} else {
			const newUrl = `/video-templates/${getUrlFromString(
				state.searchQuery,
			)}${params}`

			window.history.pushState({ path: newUrl }, "", newUrl)
		}

		const isFilterSelected =
			initialFilters.selectedCategory !==
				selectedCategory ||
			initialFilters.price !== price ||
			initialFilters.sortBy !== sortBy

		setIsFilterSelected(isFilterSelected)
	}

	const isFeatured = state.selectedCategory === -1
	const isPriceAll = state.price === "all"
	const isCategoryAll = state.selectedCategory === -2
	const isSearched = state.searchQuery !== null

	const canChange =
		isSearched ||
		(!isCategoryAll && !isFeatured) ||
		(isCategoryAll && !isPriceAll)

	return (
		<>
			<div
				className={clsx(
					"fixed inset-0 flex items-end justify-start bg-color-popup tablet:hidden",
					!filterDialogOpen &&
						"pointer-events-none opacity-0",
					"z-[99] transition-opacity duration-300",
				)}
				id="filter-popup"
				onClick={(event) => {
					if (event.target instanceof Element) {
						if (
							event.target.id === event.currentTarget.id
						) {
							setFilterDialogOpen(false)
						}
					}
				}}>
				<div
					className={clsx(
						"relative h-[480px] w-full rounded-t-[12px] bg-color-popup-cell pt-3 transition-all",
						filterDialogOpen
							? "translate-y-0"
							: "translate-y-full",
					)}>
					<div className="no-scrollbar flex h-[487px] w-full flex-col gap-[18px] overflow-y-auto rounded-[13px] bg-color-popup-cell px-4 py-2">
						{/* Categories Section */}
						<div className="flex w-full flex-col gap-3">
							<div className="flex w-full flex-row items-center justify-between">
								<p className="text-[14px] font-600 text-blue-800">
									{t("lbl_categories")}
								</p>
								<button
									onClick={(e) => {
										e.preventDefault()
										selectCategory(-2, t("txt_all"))
									}}
									className="text-[12px] font-600 text-blue-500">
									{t("txt_clear")}
								</button>
							</div>
							<button
								onClick={(e) => {
									e.preventDefault()
									setCategoryDialogOpen(true)
								}}
								className={clsx(
									"flex h-10 w-full flex-row items-center justify-between gap-[6px] rounded-[6px] bg-color-background px-3 py-2",
								)}>
								<span className="flex-1 text-start text-[14px] font-400 text-blue-600">
									{selectedName}
								</span>
								<div
									className={clsx(
										"mr-1 flex -rotate-90 items-center justify-center",
										"transition-all [--icon-color:var(--color-placeholder)]",
									)}>
									<ButtonArrowIcon />
								</div>
							</button>
						</div>

						{/* Price Section */}
						<div className="flex w-full flex-col gap-3">
							<div className="flex w-full flex-row items-center justify-between">
								<p className="text-[14px] font-600 text-blue-800">
									{t("lbl_price")}
								</p>
								<button
									onClick={(e) => {
										e.preventDefault()
										setPrice("all")
									}}
									className="text-[12px] font-600 text-blue-500">
									{t("txt_clear")}
								</button>
							</div>
							<div
								className={clsx(
									"flex w-full flex-wrap gap-[10px]",
									selectedCategory === -1 &&
										"pointer-events-none opacity-50",
								)}>
								{priceOptionsTemplates.map((option) => (
									<button
										key={option}
										onClick={(e) => {
											e.preventDefault()
											setPrice(option)
										}}
										className={clsx(
											"flex h-11 flex-row items-center justify-center gap-3 rounded-[6px] border pl-[7px] pr-[14px] text-[12px] font-500 transition-all",
											price === option
												? "border-blue-100 bg-blue-100 text-blue-800"
												: "border-color-separator text-blue-700",
										)}>
										<div
											className={clsx(
												"flex h-4 w-4 items-center justify-center rounded-full border-[1.2px] transition-all",
												price === option
													? "border-primary-500"
													: "border-[#9195AB]",
											)}>
											<div
												className={clsx(
													"h-2 w-2 rounded-full bg-primary-500 transition-opacity",
													price === option
														? "opacity-100"
														: "opacity-0",
												)}></div>
										</div>
										{t(priceLablesTemplates[option])}
									</button>
								))}
							</div>
						</div>

						{/* Sort By Section */}
						<div className="flex w-full flex-col gap-3">
							<div className="flex w-full flex-row items-center justify-between">
								<p className="text-[14px] font-600 text-blue-800">
									{t("lbl_sort_by")}
								</p>
								<button
									onClick={(e) => {
										e.preventDefault()
										setSortBy("rec")
									}}
									className="text-[12px] font-600 text-blue-500">
									{t("txt_clear")}
								</button>
							</div>
							<div
								className={clsx(
									"flex w-full flex-wrap gap-[10px]",
									!canChange &&
										"pointer-events-none opacity-50",
								)}>
								{sortOptionsTemplates.map((option) => (
									<button
										key={option}
										onClick={(e) => {
											e.preventDefault()
											setSortBy(option)
										}}
										className={clsx(
											"flex h-11 flex-row items-center justify-center gap-3 rounded-[6px] border pl-[7px] pr-[14px] text-[12px] font-500 transition-all",
											sortBy === option
												? "border-blue-100 bg-blue-100 text-blue-800"
												: "border-color-separator text-blue-700",
										)}>
										<div
											className={clsx(
												"flex h-4 w-4 items-center justify-center rounded-full border-[1.2px] transition-all",
												sortBy === option
													? "border-primary-500"
													: "border-[#9195AB]",
											)}>
											<div
												className={clsx(
													"h-2 w-2 rounded-full bg-primary-500 transition-opacity",
													sortBy === option
														? "opacity-100"
														: "opacity-0",
												)}></div>
										</div>
										{t(sortLabelsTemplates[option])}
									</button>
								))}
							</div>
						</div>
					</div>

					<div className="fixed bottom-0 left-0 w-full border-t border-color-separator bg-color-popup-cell px-4 py-[14px]">
						<div className="flex h-11 w-full flex-row gap-[10px]">
							<button
								onClick={(e) => {
									e.preventDefault()
									clearAll()
								}}
								className="h-full px-[18px] py-3 text-[16px] font-600 text-blue-500">
								{t("txt_clear_all")}
							</button>
							<Button
								onClick={() => {
									apply()
									setFilterDialogOpen(false)
								}}
								text={t("lbl_apply")}
								className="h-full flex-1"></Button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

interface CategoriesDialogProps {
	filterDialogOpen: boolean
	setFilterDialogOpen: (open: boolean) => void
}

export function CategoriesDialogMobile(
	props: CategoriesDialogProps,
) {
	const { filterDialogOpen, setFilterDialogOpen } = props
	const { dispatch, state, availableFilters } =
		useTemplatesViewContext()

	const { t } = useTranslation()

	const { selectedCategory } = state
	const categories = availableFilters.categories

	const selectCategory = (id: number) => {
		dispatch({ type: "select_category", id })
		setFilterDialogOpen(false)
	}

	return (
		<>
			<div
				className={clsx(
					"fixed inset-0 flex items-end justify-start bg-color-popup tablet:hidden",
					!filterDialogOpen &&
						"pointer-events-none opacity-0",
					"z-[99] transition-opacity duration-300",
				)}
				id="filter-popup"
				onClick={(event) => {
					if (event.target instanceof Element) {
						if (
							event.target.id === event.currentTarget.id
						) {
							setFilterDialogOpen(false)
						}
					}
				}}>
				<div
					className={clsx(
						"relative h-[500px] w-full rounded-t-[12px] bg-color-popup-cell pt-3 transition-all",
						filterDialogOpen
							? "translate-y-0"
							: "translate-y-full",
					)}>
					<div className="no-scrollbar flex h-full w-full flex-col gap-[8px] overflow-y-auto rounded-[13px] bg-color-popup-cell px-[10px] py-[14px]">
						{/* Categories Section */}
						<button
							onClick={(e) => {
								e.preventDefault()
								selectCategory(-2)
							}}
							className={clsx(
								"flex w-full flex-row items-center rounded-[8px] px-4 py-3",
								selectedCategory === -2 && "bg-blue-100",
							)}>
							{t("txt_all")}
						</button>
						<button
							onClick={(e) => {
								e.preventDefault()
								selectCategory(-1)
							}}
							className={clsx(
								"flex w-full flex-row items-center rounded-[8px] px-4 py-3",
								selectedCategory === -1 && "bg-blue-100",
							)}>
							{t("txt_featured")}
						</button>
						{categories.map((cat) => (
							<button
								key={cat.id}
								onClick={(e) => {
									e.preventDefault()
									selectCategory(cat.id)
								}}
								className={clsx(
									"flex w-full flex-row items-center rounded-[8px] px-4 py-3",
									selectedCategory === cat.id &&
										"bg-blue-100",
								)}>
								{cat.name}
							</button>
						))}
					</div>
				</div>
			</div>
		</>
	)
}

const skeletonColors = [
	"#81E68526",
	"#81E6E326",
	"#81B7E626",
	"#E681A626",
	"#E6D08126",
	"#8185E626",
	"#B081E626",
	"#E381E626",
	"#E6818126",
]

interface SingleGeneratedProps {
	entity: GalleryEntryType
	setGalleryPopupState: (
		gallery: GalleryPopupProps | null,
	) => void
	closeLink?: string
	notShallow?: boolean
	selectCreatorTool?: (
		tool: AITool,
		content: DynamicEditorProps,
		changePage: boolean,
	) => void
}

export function SingleGenerated(
	props: SingleGeneratedProps,
) {
	const {
		setGalleryPopupState,
		notShallow,
		closeLink,
		selectCreatorTool,
	} = props
	const {
		id,
		object_urls,
		media_type,
		prompt,
		tool,
		accepted_at,
		style_name,
		style,
		model_id,
		model_name,
		likes,
		views,
	} = props.entity
	const { username, followers, uid, profile_pic } =
		props.entity.owner
	const ownerId = uid
	const { t } = useTranslation()
	const [isHovered, setIsHovered] = useState(false)
	const [offset, setOffset] = useState(0)
	const [videoPaused, setVideoPaused] = useState(true)

	const { userInfo } = useAuth()

	function setAsViewed() {
		const options: ActionOptions = {
			action: "view",
			gallery_id: id,
		}
		axios
			.post("/api/gallery-view", options)
			.catch((e) => console.error(e))
	}

	async function handleRetry() {
		if (userInfo.isAnonymous || !selectCreatorTool) {
			redirectToLogin()
			return
		}

		const promptType = prompt
		const inputs =
			promptType === "custom"
				? createInputs({ type: "custom", content: [""] })
				: createInputs({
						type: "predefined",
						id: promptType ?? "",
				  })

		switch (tool) {
			case "deform":
				selectCreatorTool(
					tool,
					{
						tool: "deform",
						id: DEFORUM_STORAGE_KEY,
						inputs,
						promptType: style ?? "custom",
						location: "local",
						blob: null,
					},
					true,
				)
				break

			case "ai_photo":
				selectCreatorTool(
					tool,
					{
						tool: "ai_photo",
						id: DEFORUM_STORAGE_KEY,
						input: prompt ?? "",
						promptType: style,
						location: "local",
						blob: null,
					},
					true,
				)
				break

			case "ai_restyle":
				selectCreatorTool(
					tool,
					{
						tool: "ai_restyle",
						id: DEFORUM_STORAGE_KEY,
						input: prompt ?? "",
						promptType: style,
						location: "local",
						blob: null,
					},
					true,
				)
				break

			case "text_to_image":
				selectCreatorTool(
					tool,
					{
						tool: "text_to_image",
						id: DEFORUM_STORAGE_KEY,
						promptType: style,
						text: prompt ?? "",
						location: "local",
						blob: null,
					},
					true,
				)
				break
			default:
				const link = document.createElement("a")
				link.href = appStoreConstant
				link.target = "_blank"
				link.click()
				break
		}

		setGalleryPopupState(null)
	}

	const scrollToImage = useCallback(
		function (index: number) {
			const nextImage = document
				.getElementsByClassName(`gallery-${id}-${index}`)
				.item(0)
			if (!nextImage) {
				return
			}
			const parent = nextImage.parentElement
			if (!parent) {
				return
			}

			const childX = nextImage.getBoundingClientRect().x
			const parentX = parent.getBoundingClientRect().x
			const childOffset = childX - parentX
			parent.scrollBy({
				behavior: "smooth",
				left: childOffset,
			})
		},
		[id],
	)

	useEffect(() => {
		scrollToImage(0)
	}, [scrollToImage, id])

	const router = useRouter()

	useEffect(() => {
		function handlePopState() {
			setGalleryPopupState(null)
		}

		window.addEventListener("popstate", handlePopState)
	}, [router, setGalleryPopupState])

	const { notify } = useContext(NotificationContext)

	const thumbReady = useProgressive(object_urls[0].thumb)

	async function handleCopyPrompt(text: string) {
		try {
			await navigator.clipboard.writeText(text)
			notify(
				<SuccessMessage>
					{t("common:txt_successfully_copied")}
				</SuccessMessage>,
			)
		} catch (e) {
			console.error(e)
			notify(
				<ErrorMessage>
					{t("common:txt_couldnt_copy")}
				</ErrorMessage>,
			)
		}
	}

	return (
		<div
			style={{
				backgroundColor:
					skeletonColors[id % skeletonColors.length],
			}}
			className="group relative min-h-[130px] w-full shrink-0 rounded-[6px] desktop:min-h-[180px]">
			<div className="absolute left-2 top-2">
				{media_type === "video" && (
					<img
						src={assetUrl(
							"/ai-tools/history/video-icon.svg",
						)}
						alt="video-icon"
						className={clsx(
							"h-4 w-4 transition-opacity",
							videoPaused ? "opacity-100" : "opacity-0",
						)}
					/>
				)}
			</div>

			{(prompt || model_name || style_name) && (
				<div
					className={clsx(
						"absolute bottom-0 left-0 z-10 hidden w-full pt-3 desktop:block",
						"pointer-events-none opacity-0 transition-all group-hover:pointer-events-auto group-hover:opacity-100",
						"rounded-b-[6px]",
						"bg-gradient-to-t from-color-black/80 to-[transparent]",
						"flex cursor-auto flex-col items-center",
					)}>
					{prompt && prompt.trim().length > 0 && (
						<div
							onMouseEnter={() => setIsHovered(true)}
							onMouseLeave={() => setIsHovered(false)}
							className={clsx(
								"peer relative flex w-full flex-row items-end justify-between p-3 pt-0",
							)}>
							<TextTooltip
								showAlways={prompt.length > 144}
								className={clsx("max-w-full pr-6")}
								title={prompt}>
								<p
									className={clsx(
										"text-sm font-400 text-color-white transition-[max-height]",
										isHovered
											? "line-clamp-4 max-h-[80px] whitespace-normal"
											: "max-h-[20px] truncate",
									)}>
									{prompt}
								</p>
							</TextTooltip>
						</div>
					)}

					<div className="flex w-full flex-row items-center justify-between px-3 pb-3">
						<div className="rounded-[100px] bg-[#212127A3] px-[13px] py-1 text-[13px] font-500 text-color-white">
							{style_name ?? model_name}
						</div>
						{prompt && (
							<Tooltip
								className="flex items-center justify-center"
								title={t("common:txt_copy")}
								showAlways>
								<div
									onClick={() => handleCopyPrompt(prompt)}
									className={clsx(
										"flex h-5 w-5 shrink-0 cursor-pointer items-center justify-center",
									)}>
									<img
										src={assetUrl(
											"/ai-tools/history/copy.svg",
										)}
										alt="copy"
										className="h-full"
									/>
								</div>
							</Tooltip>
						)}
					</div>
				</div>
			)}

			{object_urls.length > 1 && (
				<>
					<button
						disabled={offset === 0}
						onClick={() => scrollToImage(offset - 1)}
						className={clsx(
							"absolute left-2 top-[calc(50%-12px)] z-20 flex h-6 w-6 items-center justify-center",
							"rounded-full border border-[transparent] bg-[#00000052] opacity-0 backdrop-blur-[10px]",
							"transition-all hover:border-color-white/60 disabled:border-[transparent]",
							isHovered
								? "opacity-0"
								: "group-hover:opacity-100",
						)}>
						<img
							src={assetUrl(
								"/ai-tools/history/arrow-white.svg",
							)}
							alt="arrow"
						/>
					</button>
					<button
						disabled={offset === object_urls.length - 1}
						onClick={() => scrollToImage(offset + 1)}
						className={clsx(
							"absolute right-2 top-[calc(50%-12px)] z-20 flex h-6 w-6 items-center justify-center",
							"rounded-full border border-[transparent] bg-[#00000052] opacity-0 backdrop-blur-[10px]",
							"transition-all hover:border-color-white/60 disabled:border-[transparent]",
							isHovered
								? "opacity-0"
								: "group-hover:opacity-100",
						)}>
						<img
							src={assetUrl(
								"/ai-tools/history/arrow-white.svg",
							)}
							alt="arrow"
							className="rotate-180"
						/>
					</button>
				</>
			)}

			<a
				href={`/ai-gallery/single/${id}`}
				onClick={(e) => {
					function onClose() {
						if (!notShallow) {
							const newUrl = closeLink ?? `/ai-gallery`
							window.history.pushState(
								{ path: newUrl },
								"",
								newUrl,
							)
						}
						setGalleryPopupState(null)
					}
					setAsViewed()

					e.preventDefault()
					if (!notShallow) {
						const newUrl = `/ai-gallery/single/${id}`
						window.history.pushState(
							{ path: newUrl },
							"",
							newUrl,
						)
					}
					if (media_type === "image") {
						setAsViewed()
					} else {
						setTimeout(() => setAsViewed(), 1000)
					}

					const isVideo = media_type === "video"

					setGalleryPopupState({
						onClose,
						closeLink: closeLink ?? `/ai-gallery`,
						previewImage: object_urls[0].thumb,
						id,
						imageOrVideo:
							object_urls.length > 1
								? object_urls.map((single) => single.object)
								: isVideo
								? object_urls[0].object + "#t=0.001"
								: object_urls[0].object,
						tool: tool,
						createdAt: accepted_at ?? new Date().getDate(),
						customPrompt: prompt ?? "",
						privacy: "public",
						prompt: prompt ?? "",
						promptName: style_name ?? "Custom",
						username,
						followers,
						profile_pic: profile_pic?.original,
						handleRetry,
						notShallow: true,
						showRelated: true,
						model_id,
						ownerId,
						showFor: true,
						views: views,
						likes,
					})
				}}
				className="cursor-pointer">
				<div
					className={clsx(
						"w-full overflow-hidden object-cover",
						"rounded-[6px]",
					)}>
					<div
						onScroll={(event) => {
							const container = event.target
							if (container instanceof HTMLElement) {
								const snapIndex = Math.floor(
									container.scrollLeft /
										container.offsetWidth,
								)

								setOffset(snapIndex)
							}
						}}
						className="no-scrollbar top-0 flex h-full snap-x snap-mandatory flex-row gap-12 overflow-x-scroll transition-all ">
						{object_urls.map((single, index) =>
							media_type === "image" ? (
								<img
									key={index}
									src={single.thumb}
									alt={prompt ? prompt : style ?? "Custom"}
									className={clsx(
										" h-full min-h-[130px] w-full shrink-0 snap-center rounded-[6px] object-cover desktop:min-h-[180px]",
										`gallery-${id}-${index}`,
										!thumbReady && "opacity-0",
									)}
								/>
							) : (
								<video
									key={index}
									src={single.object + "#t=0.001"}
									poster={single.thumb}
									playsInline
									loop
									preload="none"
									muted
									onPointerEnter={(e) => {
										setVideoPaused(false)
										e.currentTarget
											.play()
											.catch(console.error)
									}}
									onPointerLeave={(e) => {
										setVideoPaused(true)
										e.currentTarget.pause()
										e.currentTarget.currentTime = 0.001
									}}
									className={clsx(
										"h-full w-full object-cover",
										"min-h-[130px] rounded-[6px] desktop:min-h-[180px]",
									)}
								/>
							),
						)}
					</div>
				</div>
			</a>
		</div>
	)
}

const BreadCrumb = () => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.19518 3.52827C5.93483 3.78862 5.93483 4.21073 6.19518 4.47108L9.72378 7.99967L6.19518 11.5283C5.93483 11.7886 5.93483 12.2107 6.19518 12.4711C6.45553 12.7314 6.87764 12.7314 7.13799 12.4711L11.138 8.47108C11.263 8.34605 11.3333 8.17649 11.3333 7.99967C11.3333 7.82286 11.263 7.65329 11.138 7.52827L7.13799 3.52827C6.87764 3.26792 6.45553 3.26792 6.19518 3.52827Z"
			fill="var(--icon-color)"
		/>
	</svg>
)
