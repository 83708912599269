import Text from "@/comps/text"

import { appStoreConstant } from "@/utils/branch"
import {
	Desktop,
	devices,
	Mobile,
	Tablet,
} from "@/utils/breakpoints"
import { getAnalytics, logEvent } from "firebase/analytics"

import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { Box, FlexBox } from "react-styled-flex"
import styled, { css } from "styled-components"

import useAuth from "@/utils/client-auth"
import clsx from "clsx"
import Link from "next/link"
import { useState } from "react"

const LinkColumns = styled.div`
	display: flex;
	flex-direction: row;
	flex: 2;

	margin-bottom: 20px;

	flex-wrap: wrap;
	row-gap: 10px;

	@media ${devices.tablet} {
		flex-wrap: nowrap;
		justify-content: space-between;
		margin-bottom: 24px;
		width: 90%;
	}

	@media ${devices.desktop} {
		margin-bottom: 33px;
		width: 1170px;
	}
`

const aiItems: FooterSectionItem[] = [
	{
		label: "txt_text_to_reel",
		url: "/chat",
	},
	{
		label: "txt_ai_photoshoot",
		url: "/tools/ai-photoshoot-generator",
	},
	{
		label: "txt_deforum_ai",
		url: "/tools/ai-video-transformation",
	},
	{
		label: "txt_restyle_ai",
		url: "/tools/ai-animation-generator",
	},
	{
		label: "txt_photo_ai",
		url: "/tools/ai-photo-editor",
	},
	{
		label: "txt_text_to_image",
		url: "/tools/text-to-image",
	},
	{
		label: "txt_text_to_video",
		url: "/tools/text-to-video",
	},
	{
		label: "txt_look_ai",
		url: "/tools/ai-portrait-generator",
	},
	{
		label: "txt_generative_fill",
		url: "/tools/ai-image-replacer",
	},
	{
		label: "txt_ai_music_generator",
		url: "/tools/royalty-free-music",
	},
	{
		label: "txt_video_bg_remover",
		url: "/tools/video-background-remover",
	},
	{
		label: "lbl_ai_gallery",
		url: "/ai-gallery",
	},
]

const productItems: FooterSectionItem[] = [
	{
		label: "Zoomerang App",
		raw: true,
		url: appStoreConstant,
	},
	{ label: "lbl_pricing", url: "/pricing" },
	{
		label: "lbl_templates",
		url: "/video-templates",
	},
	{
		label: "lbl_creators",
		url: "/creators",
	},
]

const companyItems: FooterSectionItem[] = [
	{ label: "lbl_contact_us", url: "/contact" },
	{ label: "lbl_careers", url: "/careers" },
	{ label: "lbl_reviews", url: "/#reviews" },
]

const otherItems: FooterSectionItem[] = [
	{
		label: "lbl_blog",
		url: "/blog",
	},
	{
		label: "txt_help_center",
		url: "https://help.zoomerang.app/en/",
		newTab: true,
	},
	{
		label: "txt_terms_and_privacy",
		url: "/privacy-policy",
	},
]

const Links = () => {
	const router = useRouter()
	const { t } = useTranslation()
	const [section, setSection] = useState<string | null>(
		null,
	)
	const { userInfo } = useAuth()

	const setOpen = (item: string) => {
		if (section === item) {
			setSection(null)
		} else {
			setSection(item)
		}
	}
	return (
		<>
			<Desktop>
				<LinkColumns>
					<FooterSection items={aiItems.slice(0, 6)}>
						lbl_ai_studio
					</FooterSection>
					<FooterSection
						items={aiItems.slice(6)}></FooterSection>
					<FooterSection items={productItems}>
						lbl_product
					</FooterSection>
					<FooterSection items={companyItems}>
						lbl_company
					</FooterSection>
					<FooterSection items={otherItems}>
						lbl_other
					</FooterSection>
				</LinkColumns>
			</Desktop>

			<Tablet className="flex w-full items-center justify-center">
				<LinkColumns>
					<FooterSection items={aiItems}>
						lbl_ai_studio
					</FooterSection>
					<FlexBox column gap={12}>
						<FooterSection items={companyItems}>
							lbl_company
						</FooterSection>
						<FooterSection items={otherItems}>
							lbl_other
						</FooterSection>
					</FlexBox>
					<FooterSection items={productItems}>
						lbl_product
					</FooterSection>
				</LinkColumns>
			</Tablet>

			<Mobile className="mb-2 mt-[10px] w-full">
				<div className="flex flex-col">
					<button
						onClick={() => setOpen("ai_studio")}
						className="flex w-full flex-row items-center justify-between py-[14px] text-[16px] font-500 text-blue-600">
						{t("lbl_ai_studio")}

						<div className="button relative max-h-[16px] min-h-[16px] min-w-[16px] max-w-[16px] cursor-pointer">
							<span
								className={clsx(
									section === "ai_studio" && "rotate-90",
									"before-line absolute bg-blue-600 transition-transform duration-[250ms] ease-out",
								)}></span>
							<span
								className={clsx(
									"bg-blue-600",
									"after-line absolute transition-transform duration-[250ms] ease-out",
								)}></span>
						</div>
					</button>

					{section === "ai_studio" && (
						<div className="flex w-full flex-col gap-5 pb-5">
							{aiItems.map((item, key) => {
								if (item.url !== undefined) {
									return (
										<Link key={key} href={item.url}>
											<a
												className="flex-1 shrink-0 text-[16px] font-400 text-blue-800"
												onClick={() =>
													logEvent(
														getAnalytics(),
														"web_footer",
														{
															page:
																router.route.slice(1) ||
																"home",
															link: item.url,
															uid: userInfo.userId,
														},
													)
												}
												target={
													item.newTab ? "_blank" : undefined
												}
												rel="noopener noreferrer">
												{item.raw
													? item.label
													: t(item.label)}
											</a>
										</Link>
									)
								}

								return (
									<button
										key={key}
										className="flex-1 shrink-0 text-[16px] font-400 text-blue-800"
										onClick={() => {
											item.action && item.action()
											logEvent(
												getAnalytics(),
												"web_footer",
												{
													page:
														router.route.slice(1) || "home",
													link: item.url,
													uid: userInfo.userId,
												},
											)
										}}>
										{item.raw ? item.label : t(item.label)}
									</button>
								)
							})}
						</div>
					)}
				</div>

				<div className="h-px w-full shrink-0 bg-blue-300"></div>

				<div className="flex flex-col">
					<button
						onClick={() => setOpen("product")}
						className="flex w-full flex-row items-center justify-between py-[14px] text-[16px] font-500 text-blue-600">
						{t("lbl_product")}

						<div className="button relative max-h-[16px] min-h-[16px] min-w-[16px] max-w-[16px] cursor-pointer">
							<span
								className={clsx(
									section === "product" && "rotate-90",
									"before-line absolute bg-blue-600 transition-transform duration-[250ms] ease-out",
								)}></span>
							<span
								className={clsx(
									"bg-blue-600",
									"after-line absolute transition-transform duration-[250ms] ease-out",
								)}></span>
						</div>
					</button>

					{section === "product" && (
						<div className="flex w-full flex-col gap-5 pb-5">
							{productItems.map((item, key) => {
								if (item.url !== undefined) {
									return (
										<Link key={key} href={item.url}>
											<a
												className="flex-1 shrink-0 text-[16px] font-400 text-blue-800"
												onClick={() =>
													logEvent(
														getAnalytics(),
														"web_footer",
														{
															page:
																router.route.slice(1) ||
																"home",
															link: item.url,
															uid: userInfo.userId,
														},
													)
												}
												target={
													item.newTab ? "_blank" : undefined
												}
												rel="noopener noreferrer">
												{item.raw
													? item.label
													: t(item.label)}
											</a>
										</Link>
									)
								}

								return (
									<button
										key={key}
										className="flex-1 shrink-0 text-[16px] font-400 text-blue-800"
										onClick={() => {
											item.action && item.action()
											logEvent(
												getAnalytics(),
												"web_footer",
												{
													page:
														router.route.slice(1) || "home",
													link: item.url,
													uid: userInfo.userId,
												},
											)
										}}>
										{item.raw ? item.label : t(item.label)}
									</button>
								)
							})}
						</div>
					)}
				</div>

				<div className="h-px w-full shrink-0 bg-blue-300"></div>

				<div className="flex flex-col">
					<button
						onClick={() => setOpen("company")}
						className="flex w-full flex-row items-center justify-between py-[14px] text-[16px] font-500 text-blue-600">
						{t("lbl_company")}

						<div className="button relative max-h-[16px] min-h-[16px] min-w-[16px] max-w-[16px] cursor-pointer">
							<span
								className={clsx(
									section === "company" && "rotate-90",
									"before-line absolute bg-blue-600 transition-transform duration-[250ms] ease-out",
								)}></span>
							<span
								className={clsx(
									"bg-blue-600",
									"after-line absolute transition-transform duration-[250ms] ease-out",
								)}></span>
						</div>
					</button>

					{section === "company" && (
						<div className="flex w-full flex-col gap-5 pb-5">
							{companyItems.map((item, key) => {
								if (item.url !== undefined) {
									return (
										<Link key={key} href={item.url}>
											<a
												className="flex-1 shrink-0 text-[16px] font-400 text-blue-800"
												onClick={() =>
													logEvent(
														getAnalytics(),
														"web_footer",
														{
															page:
																router.route.slice(1) ||
																"home",
															link: item.url,
															uid: userInfo.userId,
														},
													)
												}
												target={
													item.newTab ? "_blank" : undefined
												}
												rel="noopener noreferrer">
												{item.raw
													? item.label
													: t(item.label)}
											</a>
										</Link>
									)
								}

								return (
									<button
										key={key}
										className="flex-1 shrink-0 text-[16px] font-400 text-blue-800"
										onClick={() => {
											item.action && item.action()
											logEvent(
												getAnalytics(),
												"web_footer",
												{
													page:
														router.route.slice(1) || "home",
													link: item.url,
													uid: userInfo.userId,
												},
											)
										}}>
										{item.raw ? item.label : t(item.label)}
									</button>
								)
							})}
						</div>
					)}
				</div>

				<div className="h-px w-full shrink-0 bg-blue-300"></div>

				<div className="flex flex-col">
					<button
						onClick={() => setOpen("other")}
						className="flex w-full flex-row items-center justify-between py-[14px] text-[16px] font-500 text-blue-600">
						{t("lbl_other")}

						<div className="button relative max-h-[16px] min-h-[16px] min-w-[16px] max-w-[16px] cursor-pointer">
							<span
								className={clsx(
									section === "other" && "rotate-90",
									"before-line absolute bg-blue-600 transition-transform duration-[250ms] ease-out",
								)}></span>
							<span
								className={clsx(
									"bg-blue-600",
									"after-line absolute transition-transform duration-[250ms] ease-out",
								)}></span>
						</div>
					</button>

					{section === "other" && (
						<div className="flex w-full flex-col gap-5 pb-5">
							{otherItems.map((item, key) => {
								if (item.url !== undefined) {
									return (
										<Link key={key} href={item.url}>
											<a
												className="flex-1 shrink-0 text-[16px] font-400 text-blue-800"
												onClick={() =>
													logEvent(
														getAnalytics(),
														"web_footer",
														{
															page:
																router.route.slice(1) ||
																"home",
															link: item.url,
															uid: userInfo.userId,
														},
													)
												}
												target={
													item.newTab ? "_blank" : undefined
												}
												rel="noopener noreferrer">
												{item.raw
													? item.label
													: t(item.label)}
											</a>
										</Link>
									)
								}

								return (
									<button
										key={key}
										className="flex-1 shrink-0 text-[16px] font-400 text-blue-800"
										onClick={() => {
											item.action && item.action()
											logEvent(
												getAnalytics(),
												"web_footer",
												{
													page:
														router.route.slice(1) || "home",
													link: item.url,
													uid: userInfo.userId,
												},
											)
										}}>
										{item.raw ? item.label : t(item.label)}
									</button>
								)
							})}
						</div>
					)}
				</div>
			</Mobile>
		</>
	)
}

interface FooterSectionItem {
	label: string
	action?: () => void
	url?: string
	newTab?: boolean
	raw?: boolean
}

interface FooterSectionProps {
	children?: string
	items: FooterSectionItem[]
}

const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	width: 171px;

	@media screen and (min-width: 1225px) {
		flex: 1;
		padding: 0;
	}
`

const sectionText = css`
	font-size: 14px;
	font-weight: 400;
	color: var(--color-blue-800);
	text-transform: capitalize;
	text-align: left;
	flex: 1;

	cursor: pointer;

	transition: color 200ms ease-in-out;

	:hover {
		color: var(--color-blue-600);
	}
`

const SectionButton = styled.button`
	${sectionText};
`

const SectionAnchor = styled.a`
	${sectionText};
`

const FooterSection = (props: FooterSectionProps) => {
	const { t } = useTranslation()
	const { userInfo } = useAuth()

	const router = useRouter()

	return (
		<SectionContainer>
			{props.children && (
				<Box padding="33px 0px 20px 0px">
					<Text
						content={props.children}
						size="16"
						weight="500"
						color="600"
						capitalization="uppercase"
						letterSpacing="1.28"
					/>
				</Box>
			)}
			<FlexBox
				className={clsx(!props.children && "pt-[77px]")}
				column
				gap={12}>
				{props.items.map((item, key) => {
					if (item.url !== undefined)
						return (
							<Link key={key} href={item.url}>
								<SectionAnchor
									onClick={() =>
										logEvent(getAnalytics(), "web_footer", {
											page: router.route.slice(1) || "home",
											link: item.url,
											uid: userInfo.userId,
										})
									}
									target={
										item.newTab ? "_blank" : undefined
									}
									rel="noopener noreferrer">
									{item.raw ? item.label : t(item.label)}
								</SectionAnchor>
							</Link>
						)
					return (
						<SectionButton
							key={key}
							onClick={() => {
								item.action && item.action()
								logEvent(getAnalytics(), "web_footer", {
									page: router.route.slice(1) || "home",
									link: item.url,
									uid: userInfo.userId,
								})
							}}>
							{item.raw ? item.label : t(item.label)}
						</SectionButton>
					)
				})}
			</FlexBox>
		</SectionContainer>
	)
}

export default Links
