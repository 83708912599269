import { FollowButton } from "@/comps/advanced-profile"
import { moreIcon } from "@/comps/ai-tools/icons"
import { allProducts } from "@/comps/ai-tools/other-products-section"
import { WrappedFooter } from "@/comps/footer"
import Header from "@/comps/header"
import { CustomThemedResource } from "@/comps/image"
import {
  ErrorMessage,
  SuccessMessage,
} from "@/comps/message"
import Meta from "@/comps/meta"
import { PlayWithProvider, Timeline } from "@/comps/player"
import { useAdjustLogger } from "@/utils/adjust"
import { appStoreConstant } from "@/utils/branch"
import { assetUrl } from "@/utils/cdn"
import useAuth from "@/utils/client-auth"
import { AllTools } from "@/utils/indexed"
import { redirectToLogin } from "@/utils/login-redirect"
import prettyMillions from "@/utils/millions"
import { NotificationContext } from "@/utils/notification"
import { APP_HOST } from "@/utils/variables"
import axios from "axios"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { GalleryTools } from "pages/ai-gallery"
import { getFirstFiveWords } from "pages/ai-gallery/single/[id]"
import { GalleryReportRequest } from "pages/api/gallery-report"
import { SingleProduct } from "pages/tools"
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import {
  ImageObject,
  VideoObject,
  WithContext,
} from "schema-dts"
import {
  DialogProps,
  SocialButton,
  socials,
} from "sections/history/generations"
import { FollowStatus } from "types/endpoints/user-profile"
import { VideoProvider } from "video-provider"
import {
  formatTimes,
  SingleProductHistory,
  transformTool,
  transformToolToIcon,
} from "./history-popup"
import { MoreButton } from "./more-button"
import { ReportPopup } from "./report-popup"
import { ShareContent } from "./share-content"
import { RelatedSection } from "./single-generated"

export const RELATED_ART_LIMIT = 20

interface AISinglePageProps {
  privacy?: "public" | "private"
  imageOrVideo: string | string[]
  isVideo: boolean
  customPrompt: string | null
  tool: string
  id: number
  createdAt: number
  onClose: () => void
  handleMakePublic?: (
    privacy: "public" | "private",
    id: number,
  ) => Promise<void>
  style: string
  handleDelete?: (id: number) => Promise<void>
  shareDialog: DialogProps
  setShareDialog: (obj: DialogProps) => void
  handleRetry?: (prompt?: string) => void
  closeLink: string | null
  username: string | null
  profile_pic: string | null
  followers: number | null
  notShallow: boolean | null
  showRelated: boolean | null
  previewImage: string
  ownerId: string | null
  followStatus: FollowStatus | null
  setAsShared?: () => void
  cover?: string
  likes: number | null
  views: number | null
  jsonLdData?: WithContext<ImageObject | VideoObject>[]
}

export default function AISinglePage(
  props: AISinglePageProps,
) {
  const {
    id,
    tool,
    createdAt,
    shareDialog,
    setShareDialog,
    imageOrVideo,
    isVideo,
    customPrompt,
    onClose,
    handleRetry,
    closeLink,
    username,
    profile_pic,
    followers,
    style,
    notShallow,
    showRelated,
    ownerId,
    setAsShared,
    cover,
    views,
    previewImage,
    jsonLdData,
  } = props

  const auth = useAuth()

  const [showMediaDialog, setShowMediaDialog] =
    useState(false)
  const [offset, setOffset] = useState(0)
  const { t } = useTranslation()
  const router = useRouter()
  const [followStatus, setFollowStatus] =
    useState<FollowStatus | null>(
      props.followStatus ?? null,
    )
  const [showDropdown, setShowDropdown] =
    useState<boolean>(false)
  const [liked, setLiked] = useState(false)
  const [likes, setLikes] = useState(props.likes)
  const [isHovered, setIsHovered] = useState(false)
  const [payload, setPayload] =
    useState<GalleryReportRequest>({
      gallery_id: id,
      info: {
        reason: "",
        message: "",
      },
    })
  const [reportPopup, setReportPopup] = useState(false)

  const { notify } = useContext(NotificationContext)

  const isSelf =
    ownerId === auth.userInfo.userId &&
    !auth.userInfo.isAnonymous

  function handleRoute() {
    onClose()
    if (!notShallow) {
      router.push(
        closeLink ? `${closeLink}` : `/home`,
        undefined,
        {
          shallow: true,
        },
      )
    }
  }

  async function handleLike() {
    if (auth.userInfo && auth.userInfo.isAnonymous) {
      redirectToLogin()
      return
    }
    try {
      const endpoint = liked
        ? "/api/gallery-unlike"
        : "/api/gallery-like"
      await axios.post(endpoint, {
        gallery_id: id,
      })
      setLikes((c) => {
        if (c === null) {
          return c
        }
        if (liked) {
          return c - 1
        } else {
          return c + 1
        }
      })
      setLiked(!liked)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Meta
        title={
          "AI Art" +
          (username ? " by " + username + " - " : " - ") +
          getFirstFiveWords(
            customPrompt && customPrompt.trim().length !== 0
              ? customPrompt
              : style,
          )
        }
        description={
          customPrompt
            ? style + " - " + customPrompt
            : style + " AI Art By " + username
        }
        cover={cover}
        jsonLdData={jsonLdData}
      />
      <div
        className={clsx(
          "no-scrollbar h-full overflow-y-scroll",
          showRelated
            ? " bg-color-cell tablet:bg-color-background"
            : "",
        )}>
        <div className="hidden desktop:block">
          <Header />
        </div>

        <div
          className={clsx(
            "flex flex-col",
            "relative desktop:flex-row desktop:items-start desktop:justify-center",
            "desktop:gap-[120px] desktop:pt-[88px]",
          )}
          onClick={(e) => {
            const target = e.target
            if (target instanceof HTMLElement) {
              if (target.id !== "media-box") {
                setShowMediaDialog(false)
              }
            }
          }}>
          <div
            className={clsx(
              "hidden items-center justify-between desktop:flex",
              "absolute left-1/2 top-[20px] w-[1075px] -translate-x-1/2",
            )}>
            <button
              className={clsx("flex items-center")}
              onClick={handleRoute}>
              <img
                src={assetUrl(
                  "/ai-tools/history/arrow-left.svg",
                )}
                alt="arrow icon"
                className="h-[36px] w-[36px] shrink-0"
              />
              <span className="text-[18px] font-500 text-blue-800">
                {username
                  ? t("common:lbl_gallery")
                  : t("txt_history")}
              </span>
            </button>
          </div>

          <RenderImageSection
            imageOrVideo={imageOrVideo}
            previewImage={previewImage}
            isVideo={isVideo}
            offset={offset}
            style={style}
            setOffset={setOffset}
          />

          <button
            className={clsx(
              "absolute left-[17px] top-[17px] z-[101] desktop:hidden",
              "rounded-full bg-color-white/25 p-2",
            )}
            onClick={() => {
              onClose()
              if (!notShallow) {
                router.push(
                  closeLink ? `${closeLink}` : `/home`,
                  undefined,
                  {
                    shallow: true,
                  },
                )
              }
            }}>
            <img
              src={assetUrl("/comps/close-icon.svg")}
              alt="close icon"
            />
          </button>

          <div
            className={clsx(
              "absolute right-[17px] top-[17px] z-50 tablet:hidden",
              "flex items-center justify-center gap-[12px]",
            )}>
            <MoreButton
              setShareDialog={setShareDialog}
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
              id={id}
              href={
                typeof imageOrVideo === "string"
                  ? imageOrVideo
                  : imageOrVideo[0]
              }
              dark={false}
              isSinglePage={true}
              username={username ?? undefined}
              setReportPopup={setReportPopup}
              reportPopup={!isSelf && reportPopup}
              closeLink={closeLink ?? undefined}
            />
            {reportPopup && setReportPopup && !isSelf && (
              <ReportPopup
                payloadLocation="gallery"
                payload={payload}
                setPayload={setPayload}
                endpoint="gallery-report"
                setReportPopup={setReportPopup}
                galleryOrTemplate="gallery"
              />
            )}
          </div>
          {shareDialog.open && (
            <ShareContent
              shareDialog={shareDialog}
              setShareDialog={setShareDialog}
              isPublic={true}
              id={id}
            />
          )}

          <div className="hidden tablet:block">
            <RenderContentSection
              closeFn={onClose}
              tool={tool}
              id={id}
              showRelated={showRelated}
              shareDialog={shareDialog}
              setShareDialog={setShareDialog}
              ownerId={ownerId ?? null}
              setShowMediaDialog={setShowMediaDialog}
              followers={followers}
              createdAt={createdAt}
              customPrompt={customPrompt}
              offset={offset}
              setFollowStatus={setFollowStatus}
              imageOrVideo={imageOrVideo}
              closeLink={closeLink}
              showMediaDialog={showMediaDialog}
              handleRetry={handleRetry}
              username={username}
              profilePicture={profile_pic}
              followStatus={followStatus}
              style={style}
              setAsShared={setAsShared}
              handleLike={handleLike}
              liked={liked}
              likes={likes}
              views={views}
            />
          </div>

          {/*REGENERATE_BUTTON */}
          {showRelated ? (
            <div
              className={clsx(
                "fixed bottom-0 left-0 z-40 flex w-full flex-col gap-[19px] p-[12px] tablet:hidden",
                "border-t border-color-separator bg-color-white",
              )}>
              <a
                href={appStoreConstant}
                className={clsx(
                  "relative flex w-full items-center justify-center gap-[12px]",
                  "rounded-[10px] bg-primary-500 py-[12px] hover:opacity-80",
                )}>
                <div className="absolute inset-0 -z-10 h-full w-full rounded-[10px] bg-color-black/80"></div>
                <span className="text-[16px] font-[600] leading-[20px] tracking-[0.32px] text-color-white">
                  {t("txt_try_now")}
                </span>
              </a>
            </div>
          ) : (
            <div
              className={clsx(
                "fixed bottom-0 left-0 z-[10] flex w-full flex-col gap-[19px] px-[16px] pb-[46px] tablet:hidden",
                "bg-gradient-to-t from-color-black/75 via-color-black/40 to-color-black/5 backdrop-blur-[1px]",
              )}>
              <div className="flex flex-col gap-[8px]">
                <div
                  className={clsx(
                    "flex items-center justify-between",
                    "tablet:absolute tablet:-top-[360px] tablet:flex-col tablet:items-start",
                  )}>
                  <div className="flex items-center gap-[12px] [--icon-color:var(--color-white)]">
                    {transformToolToIcon(
                      tool as GalleryTools,
                    )}
                    <span
                      className={clsx(
                        " text-[18px] font-[500] leading-[19px] tracking-[0.36px] text-color-white ",
                        "tablet:text-blue-700",
                      )}>
                      {transformTool(tool as GalleryTools)}
                    </span>
                  </div>
                  <span
                    className={clsx(
                      "text-[14px] font-[500] leading-[26px] tracking-[0.28px] text-blue-400",
                    )}>
                    {formatTimes(createdAt)}
                  </span>
                </div>

                {tool === "text_to_image" ||
                tool === "text_to_video" ? (
                  <>
                    <div
                      className={clsx(
                        "w-full pr-3 tablet:hidden",
                        "transition-all group-hover:opacity-100",
                        "relative mt-[16px] flex cursor-auto items-start gap-[30px]",
                      )}>
                      <div className="no-scrollbar flex justify-between overflow-hidden overflow-y-scroll pr-3">
                        <p
                          className={clsx(
                            "cursor-text font-mono text-sm font-400 text-color-white transition-[max-height]",
                            isHovered
                              ? "max-h-[80px] min-h-[50px] max-w-[280px] whitespace-normal"
                              : "max-h-[20px] w-[260px] truncate",
                          )}>
                          {customPrompt}
                        </p>

                        <button
                          className={clsx(
                            isHovered
                              ? " absolute right-0 top-0"
                              : "hidden",
                            "h-8 w-8 shrink-0 cursor-pointer transition-colors hover:bg-blue-100",
                            "flex items-center justify-center rounded-[4px]",
                          )}
                          onClick={async () => {
                            try {
                              await navigator.clipboard.writeText(
                                customPrompt ?? "",
                              )
                              notify(
                                <SuccessMessage>
                                  {t(
                                    "common:txt_successfully_copied",
                                  )}
                                </SuccessMessage>,
                              )
                            } catch (e) {
                              console.error(e)
                              notify(
                                <ErrorMessage>
                                  {t(
                                    "common:txt_couldnt_copy",
                                  )}
                                </ErrorMessage>,
                              )
                            }
                          }}>
                          <img
                            src={assetUrl(
                              "/ai-tools/history/copy.svg",
                            )}
                            alt="copy"
                            className="h-[18px] w-[18px]"
                          />
                        </button>
                      </div>
                      <button
                        className={clsx(
                          isHovered
                            ? "-bottom-[2px]"
                            : "-top-[2px]",
                          "absolute right-0 w-[70px]",
                          "flex items-center justify-center rounded-[12px]",
                        )}
                        onClick={() =>
                          setIsHovered(!isHovered)
                        }>
                        {isHovered ? (
                          <span className="text-[14px] font-[500] leading-[26px] tracking-[0.28px] text-color-white">
                            {t("txt_less")}
                          </span>
                        ) : (
                          <span className="text-[14px] font-[500] leading-[26px] tracking-[0.28px] text-color-white">
                            {t("txt_more")}
                          </span>
                        )}
                      </button>
                    </div>

                    <div
                      className={clsx(
                        "mx-[16px] hidden justify-between gap-[8px] tablet:flex",
                        "relative pl-[12px] pr-[5px]",
                      )}>
                      <div
                        className={clsx(
                          "text-[13px] font-400 leading-[22px] tracking-[0.32px] text-blue-800",
                          "cursor-text -tracking-[0.5px] tablet:max-h-[130px]",
                          "no-scrollbar overflow-y-scroll desktop:max-h-[175px]",
                        )}>
                        <p
                          className={clsx(
                            "py-[9px] pr-[45px] font-mono",
                          )}>
                          {customPrompt}
                        </p>
                      </div>
                      <div className=" absolute right-4 top-2">
                        <button
                          className={clsx(
                            "relative h-8 w-8 shrink-0 cursor-pointer transition-colors",
                            "group flex items-center justify-center rounded-[4px] hover:bg-blue-200",
                          )}
                          onClick={async (e) => {
                            const target = e.currentTarget
                            target.disabled = true
                            setTimeout(() => {
                              target.disabled = false
                            }, 3000)
                            try {
                              await navigator.clipboard.writeText(
                                customPrompt ?? "",
                              )
                              notify(
                                <SuccessMessage>
                                  {t(
                                    "common:txt_successfully_copied",
                                  )}
                                </SuccessMessage>,
                              )
                            } catch (e) {
                              console.error(e)
                              notify(
                                <ErrorMessage>
                                  {t(
                                    "common:txt_couldnt_copy",
                                  )}
                                </ErrorMessage>,
                              )
                            }
                          }}>
                          <img
                            src={assetUrl(
                              "/ai-tools/history/copy.svg",
                            )}
                            alt="copy"
                            className="h-[20px] w-[20px]"
                          />
                          <div
                            className={clsx(
                              "absolute -bottom-7 opacity-0 hover:opacity-0 group-hover:opacity-100",
                              "rounded-[4px] px-3 py-1",
                              "bg-color-tooltip transition-all",
                              "text-xs font-500 text-blue-900",
                            )}>
                            {t("txt_copy")}
                          </div>
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex tablet:flex-col tablet:gap-[8px] tablet:px-[16px]">
                    <span className="hidden text-[16px] font-600 leading-[26px] tracking-[0.32px] text-blue-500 tablet:block">
                      {t("txt_style")}
                    </span>
                    <div className="flex">
                      <span
                        className={clsx(
                          "rounded-[100px] bg-color-success-15 px-[22px] text-[14px] font-[500] capitalize",
                          " leading-[26px] tracking-[0.28px] text-color-success",
                        )}>
                        {style}
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <a
                href={appStoreConstant}
                className={clsx(
                  "relative flex w-full items-center justify-center gap-[12px]",
                  "hover:bg-primary-600/80 rounded-[10px] bg-primary-500 py-[12px]",
                )}>
                <img
                  src={assetUrl(
                    "/general/regenerate-white.svg",
                  )}
                  alt="plus icon"
                />
                <span className="text-[16px] font-[600] leading-[20px] tracking-[0.32px] text-color-white">
                  {t("txt_regenerate")}
                </span>
              </a>
            </div>
          )}
        </div>

        <div className="w-full flex-col items-center justify-center gap-5 tablet:hidden desktop:mt-8 desktop:flex">
          {showRelated &&
            ownerId &&
            followStatus &&
            username &&
            followers !== null && (
              <RelatedSection
                customPrompt={customPrompt}
                ownerId={ownerId}
                setFollowStatus={setFollowStatus}
                followers={followers}
                followStatus={followStatus}
                createdAt={createdAt}
                tool={tool}
                showRelated={showRelated}
                id={id}
                username={username}
                profilePic={profile_pic}
                liked={liked}
                handleLike={handleLike}
                singlePageUse={false}
              />
            )}

          <span className="mb-[12px] mt-[56px] hidden text-center text-[16px] font-700 tracking-normal text-blue-700 desktop:block">
            {t("txt_other_ai_tools")}
          </span>
          <div className="hidden grid-cols-5 gap-[25px] desktop:grid desktop:w-[1175px]">
            {allProducts.map((product, index) => {
              return (
                <SingleProduct
                  product={product}
                  key={index}
                  isMobile={false}
                />
              )
            })}
          </div>
        </div>

        <div className="hidden tablet:block desktop:mt-[70px]">
          <WrappedFooter />
        </div>
      </div>
    </>
  )
}

interface RenderContentSectionProps {
  tool: string
  username: string | null
  profilePicture: string | null
  followers: number | null
  shareDialog: DialogProps
  setShareDialog: (obj: DialogProps) => void
  createdAt: number
  followStatus: FollowStatus | null
  ownerId: string | null
  id: number
  imageOrVideo: string | string[]
  setShowMediaDialog: (v: boolean) => void
  showMediaDialog: boolean
  customPrompt: string | null
  offset: number
  closeLink: string | null
  handleRetry?: (prompt?: string) => void
  showRelated: boolean | null
  setFollowStatus: (v: FollowStatus) => void
  style: string | null
  setAsShared?: () => void
  likes: number | null
  views: number | null
  handleLike: () => void
  liked: boolean
  closeFn: () => void
}

function RenderContentSection(
  props: RenderContentSectionProps,
) {
  const {
    tool,
    followStatus,
    setFollowStatus,
    id,
    username,
    profilePicture,
    followers,
    shareDialog,
    setShareDialog,
    createdAt,
    ownerId,
    imageOrVideo,
    setShowMediaDialog,
    showMediaDialog,
    customPrompt,
    offset,
    closeLink,
    showRelated,
    handleRetry,
    style,
    setAsShared,
    likes,
    handleLike,
    liked,
  } = props

  const { t } = useTranslation()
  const { notify } = useContext(NotificationContext)
  const logAdjust = useAdjustLogger()
  const [showDropdown, setShowDropdown] =
    useState<boolean>(false)
  const [payload, setPayload] =
    useState<GalleryReportRequest>({
      gallery_id: id,
      info: {
        reason: "",
        message: "",
      },
    })
  const [reportPopup, setReportPopup] = useState(false)
  const { userInfo } = useAuth()
  const isSelf =
    ownerId === userInfo.userId && !userInfo.isAnonymous

  async function handleSocialShare(
    desktop?: boolean,
    desktopFunction?: (link: string) => string,
    url?: (link: string) => string,
  ): Promise<string> {
    try {
      setShareDialog({
        open: false,
        isPublic: false,
        link: shareDialog.link,
        id: null,
      })

      if (desktop && desktopFunction)
        return desktopFunction(shareDialog.link)
      else if (url) return url(shareDialog.link)
      else throw Error("No function")
    } catch (e) {
      setShareDialog({
        open: false,
        isPublic: false,
        link: shareDialog.link,
        id: null,
      })
      console.error(e)
      notify(
        <ErrorMessage>
          {t("common:txt_couldnt_copy")}
        </ErrorMessage>,
      )
      return ""
    }
  }

  async function handleSaveAllMedia() {
    setShowMediaDialog(!showMediaDialog)
    if (typeof imageOrVideo === "string") {
      return
    }

    setAsShared && setAsShared()
    for (const url of imageOrVideo) {
      const anchor = document.createElement("a")
      anchor.href = url
      anchor.rel = "noopener noreferrer"
      anchor.download = url.substring(
        url.lastIndexOf("/") + 1,
      )
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)

      await new Promise((resolve) =>
        setTimeout(resolve, 500),
      )
    }
  }

  async function handleCopy(text: string) {
    try {
      await navigator.clipboard.writeText(text)
      notify(
        <SuccessMessage>
          {t("common:txt_successfully_copied")}
        </SuccessMessage>,
      )
    } catch (e) {
      console.error(e)
      notify(
        <ErrorMessage>
          {t("common:txt_couldnt_copy")}
        </ErrorMessage>,
      )
    }
  }

  return (
    <div
      className={clsx(
        "flex flex-col items-center justify-center",
        "relative z-50 h-full bg-color-cell pb-[60px] desktop:rounded-xl desktop:pb-0",
      )}>
      <div
        className={clsx(
          "flex w-[520px] flex-col gap-[12px] px-[16px] pt-[14px] ",
          "shrink-0 desktop:w-[431px] desktop:py-[16px] ",
        )}>
        {username && (
          <>
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center gap-[6px]">
                <a href={`/${username}`}>
                  <img
                    src={
                      profilePicture ??
                      assetUrl("/general/avatar.svg")
                    }
                    onError={(e) =>
                      e.currentTarget.setAttribute(
                        "src",
                        assetUrl(
                          "/icons/avatar-default.svg",
                        ),
                      )
                    }
                    alt="profile picture"
                    className="h-10 w-10 shrink-0 rounded-full border border-color-separator object-cover"
                  />
                </a>
                <div className="flex flex-col gap-[1px]">
                  <a href={`/${username}?active=2`}>
                    <p className="max-w-[130px] truncate text-[16px] font-700 text-blue-800">
                      {username}
                    </p>
                  </a>
                  <p className="text-[14px] font-500 text-blue-600">
                    {prettyMillions(followers ?? 0) +
                      " followers"}
                  </p>
                </div>
              </div>
              <div
                className={clsx(
                  isSelf && "hidden",
                  "flex items-center gap-[11px]",
                )}>
                <div
                  className={clsx(
                    "flex w-[150px] items-center justify-center",
                    "h-[44px] overflow-hidden rounded-[6px]",
                  )}>
                  {followStatus !== null &&
                    ownerId !== null && (
                      <FollowButton
                        followStatus={followStatus}
                        uid={ownerId}
                        setFollowStatus={setFollowStatus}
                        userPage={false}
                      />
                    )}
                </div>
                <div
                  className={clsx(
                    "group relative cursor-pointer",
                    "-mb-[30px] pb-[30px]",
                  )}>
                  <div
                    className={clsx(
                      "h-[44px] w-[44px] [--icon-color:var(--color-blue-900)] hover:bg-blue-100",
                      "flex items-center justify-center rounded-[10px]",
                    )}>
                    {moreIcon()}
                  </div>
                  <button
                    onClick={() => {
                      setReportPopup(true)
                    }}
                    className={clsx(
                      "absolute hidden w-[123px] rounded-[12px] px-[10px]",
                      "bg-color-popup-cell py-[8px] group-hover:flex",
                      "items-center gap-[8px] shadow-xl hover:bg-blue-100",
                      "left-1/2 top-[50px] -translate-x-1/2",
                    )}>
                    <CustomThemedResource
                      source="/general/report"
                      format="svg"
                    />
                    <span className="text-[16px] font-500 text-blue-800">
                      {t("txt_report")}
                    </span>
                  </button>
                  {reportPopup && setReportPopup && (
                    <ReportPopup
                      payloadLocation="gallery"
                      className={clsx(
                        "absolute left-1/2 top-[50px] z-[100] -translate-x-2/3",
                      )}
                      payload={payload}
                      setPayload={setPayload}
                      endpoint={"gallery-report"}
                      setReportPopup={setReportPopup}
                      galleryOrTemplate="gallery"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="mx-auto h-[1px] w-full bg-blue-300"></div>
          </>
        )}

        <div className="relative flex flex-col gap-[8px] desktop:w-[431px]">
          <div
            className={clsx("flex flex-col items-start")}>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-[12px] [--icon-color:var(--color-blue-600)]">
                {transformToolToIcon(tool as GalleryTools)}
                <span
                  className={clsx(
                    "text-[18px] font-[500] leading-[19px] tracking-[0.36px] text-blue-700",
                  )}>
                  {transformTool(tool as GalleryTools)}
                </span>
              </div>
            </div>
            <span
              className={clsx(
                " text-[16px] font-[500] leading-[26px] tracking-[0.28px] text-blue-500",
              )}>
              {formatTimes(createdAt)}
            </span>
          </div>
        </div>

        <div className="h-[1px] w-full bg-blue-300"></div>
        <div
          className={clsx(
            "relative z-50 flex items-start gap-[10px]",
            "no-scrollbar -mb-[30px] pb-[30px] desktop:overflow-x-auto",
          )}>
          {typeof imageOrVideo !== "string" &&
          imageOrVideo.length > 1 ? (
            <div
              onClick={() => {
                setShowMediaDialog(!showMediaDialog)
              }}
              className={clsx(
                "group relative flex items-center justify-center",
                "cursor-pointer transition-colors hover:bg-blue-300",
                "disabled:hover:bg-transparent disabled:opacity-50",
                "h-[52px] w-[52px] shrink-0 rounded-full bg-color-background",
              )}
              id="media-box">
              <img
                src={assetUrl(
                  "/ai-tools/history/save-primary.svg",
                )}
                alt="save icon"
                className="h-[20px] w-[20px]"
                id="media-box"
              />

              <div
                className={clsx(
                  "opacity-0 hover:opacity-0 group-hover:opacity-100",
                  "pointer-events-none absolute -bottom-[30px]",
                  "rounded-[4px] px-3 py-1",
                  "bg-color-tooltip transition-all",
                  "text-xs font-500 text-blue-900",
                )}>
                {t("txt_save")}
              </div>
              {showMediaDialog && (
                <div
                  className={clsx(
                    "fixed z-[103] w-[200px] translate-x-[970px] translate-y-[100px] shadow-xl",
                    "shadow-color-black/30 tablet:left-0",
                    "flex flex-col gap-[4px] rounded-xl bg-color-cell px-[10px] py-[14px]",
                  )}
                  id="media-box">
                  <a
                    onClick={() => {
                      logAdjust?.logEvent(
                        "deform_ai_video_save",
                      )
                      setAsShared && setAsShared()
                      setShowDropdown(!showDropdown)
                    }}
                    href={imageOrVideo[offset]}
                    download={true}
                    className="rounded-lg px-[16px] py-[12px] text-start text-blue-800 hover:bg-blue-100">
                    {t("txt_save_media")}
                  </a>
                  <button
                    className="rounded-lg px-[16px] py-[12px] text-start text-blue-800 hover:bg-blue-100"
                    onClick={handleSaveAllMedia}>
                    {t("txt_save_all_media")}
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div
              className={clsx(
                "group relative flex items-center justify-center",
                "cursor-pointer",
              )}
              id="media-box">
              <a
                href={imageOrVideo[offset]}
                onClick={() => {
                  logAdjust?.logEvent(
                    "deform_ai_video_save",
                  )
                  setAsShared && setAsShared()

                  setShowDropdown(!showDropdown)
                }}
                className={clsx(
                  "h-[52px] w-[52px] shrink-0 rounded-full bg-color-surface",
                  " disabled:hover:bg-transparent transition-colors hover:bg-blue-300 disabled:opacity-50",
                  "flex items-center justify-center",
                )}>
                <img
                  src={assetUrl(
                    "/ai-tools/history/save-primary.svg",
                  )}
                  alt="save icon"
                  className="h-[20px] w-[20px]"
                  id="media-box"
                />
              </a>

              <div
                className={clsx(
                  "opacity-0 hover:opacity-0 group-hover:opacity-100",
                  "pointer-events-none absolute -bottom-[30px]",
                  "rounded-[4px] px-3 py-1",
                  "bg-color-tooltip transition-all",
                  "text-xs font-500 text-blue-900",
                )}>
                {t("txt_save")}
              </div>
            </div>
          )}
          <div className="h-[50px] w-[1px] shrink-0 bg-blue-300"></div>
          <button
            onClick={async (e) => {
              const target = e.currentTarget
              target.disabled = true
              setTimeout(() => {
                target.disabled = false
              }, 3000)
              await handleCopy(
                closeLink
                  ? `${APP_HOST}${closeLink}/single/${id}`
                  : `${APP_HOST}/profile/generations/${id}`,
              )
            }}
            className={clsx(
              "group relative flex items-center justify-center",
              "transition-colors hover:bg-blue-300",
              "disabled:hover:bg-transparent disabled:opacity-50",
              "h-[52px] w-[52px] shrink-0 rounded-full bg-color-background",
            )}>
            <img
              src={assetUrl(
                "/ai-tools/history/copy-primary.svg",
              )}
              alt="copy icon"
              className="h-[20px] w-[20px]"
            />
            <div
              className={clsx(
                "pointer-events-none absolute -bottom-[30px] w-[80px]",
                "rounded-[4px] px-3 py-1 opacity-0 hover:opacity-0 group-hover:opacity-100",
                "pointer-events-none bg-color-tooltip transition-all",
                "text-xs font-500 text-blue-900",
              )}>
              {t("txt_copy_link")}
            </div>
          </button>
          {socials.map((props) => (
            <SocialButton
              {...props}
              title=""
              tooltipTitle={props.title}
              handleSocialShare={handleSocialShare}
              dialogContent={shareDialog}
              key={props.icon ?? props.light}
            />
          ))}
        </div>
        {likes !== null && (
          <div
            className={clsx(
              "flex items-center justify-start border-b border-t border-color-separator",
              "gap-[18px] py-[16px] tablet:w-full",
            )}>
            <button
              onClick={handleLike}
              className={clsx(
                "rounded-[4px] border border-color-separator",
                "flex items-center gap-[6px] bg-color-background px-[14px] py-[10px]",
              )}>
              {liked ? (
                <img
                  src={assetUrl(
                    "/general/heart-primary.svg",
                  )}
                  alt="heart image primary"
                  className="animate-appear"
                />
              ) : (
                <img
                  src={assetUrl("/general/heart.svg")}
                  alt="heart image primary"
                />
              )}
              <span className="text-[14px] font-400 text-blue-700">
                {likes}
              </span>
            </button>
          </div>
        )}

        <RenderStyleOrPrompt
          tool={tool as AllTools}
          customPrompt={customPrompt}
          style={style}
          handleCopy={handleCopy}
        />

        <button
          onClick={() => {
            handleRetry &&
              handleRetry(customPrompt ?? undefined)
          }}
          className={clsx(
            "my-[4px] hidden w-full items-center justify-center gap-[12px]",
            "rounded-[10px] bg-primary-500 py-[12px] hover:opacity-80 disabled:opacity-60",
            "desktop:my-[10px] desktop:flex",
          )}>
          <img
            src={assetUrl("/general/regenerate-white.svg")}
            alt="plus icon"
          />
          <span className="text-[16px] font-[600] leading-[20px] tracking-[0.32px] text-color-white">
            {!username
              ? t("txt_regenerate")
              : t("txt_try_now")}
          </span>
        </button>
        <a
          href={appStoreConstant}
          className={clsx(
            "relative mx-auto flex w-full items-center justify-center gap-[12px]",
            "rounded-[10px] bg-primary-500 py-[12px]  hover:opacity-80 desktop:hidden",
          )}>
          <img
            src={assetUrl("/general/regenerate-white.svg")}
            alt="plus icon"
          />
          <span className="text-[16px] font-[600] leading-[20px] tracking-[0.32px] text-color-white">
            {!username
              ? t("txt_regenerate")
              : t("txt_try_now")}
          </span>
        </a>
      </div>

      {showRelated &&
        ownerId &&
        followStatus &&
        username &&
        followers !== null && (
          <div className="w-full desktop:hidden">
            <RelatedSection
              customPrompt={customPrompt}
              ownerId={ownerId}
              setFollowStatus={setFollowStatus}
              followers={followers}
              followStatus={followStatus}
              createdAt={createdAt}
              tool={tool}
              showRelated={showRelated}
              id={id}
              username={username}
              profilePic={profilePicture}
              liked={liked}
              handleLike={handleLike}
              singlePageUse={true}
            />
          </div>
        )}

      <span className="mb-[12px] mt-[56px] hidden text-center text-[16px] font-700 tracking-normal text-blue-700 tablet:block desktop:hidden">
        {t("txt_other_ai_tools")}
      </span>

      <div
        className={clsx(
          "no-scrollbar hidden w-full gap-2 overflow-x-scroll px-4 tablet:flex",
          "desktop:hidden",
        )}>
        {allProducts.map((product, index) => {
          return (
            <SingleProductHistory
              product={product}
              key={index}
            />
          )
        })}
      </div>
    </div>
  )
}

interface RenderStyleOrPromptProps {
  customPrompt: string | null
  tool: AllTools
  style: string | null
  handleCopy: (text: string) => Promise<void>
}

function RenderStyleOrPrompt(
  props: RenderStyleOrPromptProps,
) {
  const { tool, customPrompt, style, handleCopy } = props

  const { t } = useTranslation()
  switch (tool) {
    case "deform":
    case "ai_photo":
    case "ai_restyle":
    case "model_drafts":
      return (
        <div className="flex flex-col gap-[8px]">
          <span className="text-[16px] font-600 leading-[26px] tracking-[0.32px] text-blue-500 desktop:hidden">
            {t("txt_style")}
          </span>
          <div className="flex">
            <span
              className={clsx(
                "rounded-[100px] bg-color-success-15 px-[22px] text-[14px] font-[500] capitalize",
                " leading-[26px] tracking-[0.28px] text-color-success",
              )}>
              {style}
            </span>
          </div>
        </div>
      )

    case "text_to_image":
    case "text_to_video":
      return (
        <div
          className={clsx(
            "hidden justify-between gap-[8px] tablet:flex",
            "relative rounded-[5px] bg-color-background pl-[12px] pr-[5px]",
          )}>
          <div
            className={clsx(
              "text-[13px] font-400 leading-[22px] tracking-[0.32px] text-blue-800",
              "cursor-text -tracking-[0.5px] tablet:max-h-[130px]",
              "scrollbar overflow-y-scroll desktop:max-h-[175px]",
            )}>
            <p
              className={clsx(
                "py-[9px] pr-[45px] font-mono",
              )}>
              {customPrompt}
            </p>
          </div>
          <div className=" absolute right-4 top-2">
            <button
              className={clsx(
                "relative h-8 w-8 shrink-0 cursor-pointer transition-colors",
                "group flex items-center justify-center rounded-[4px] hover:bg-blue-200",
              )}
              onClick={async (e) => {
                const target = e.currentTarget
                target.disabled = true
                setTimeout(() => {
                  target.disabled = false
                }, 3000)
                await handleCopy(customPrompt ?? "")
              }}>
              <img
                src={assetUrl("/ai-tools/history/copy.svg")}
                alt="copy"
                className="h-[20px] w-[20px]"
              />
              <div
                className={clsx(
                  "absolute -bottom-7 opacity-0 hover:opacity-0 group-hover:opacity-100",
                  "rounded-[4px] px-3 py-1",
                  "bg-color-tooltip transition-all",
                  "text-xs font-500 text-blue-900",
                )}>
                {t("txt_copy")}
              </div>
            </button>
          </div>
        </div>
      )
  }
}

interface RenderImageSectionProps {
  imageOrVideo: string | string[]
  isVideo: boolean
  customPrompt?: string
  style: string
  previewImage: string
  offset: number
  setOffset: (v: number) => void
}

function RenderImageSection(
  props: RenderImageSectionProps,
) {
  const {
    imageOrVideo,
    isVideo,
    customPrompt,
    previewImage,
    offset,
    style,
    setOffset,
  } = props

  const videoRef = useRef<HTMLVideoElement>(null)

  const scrollToImage = useCallback(
    function (index: number) {
      const isImage = typeof imageOrVideo === "string"
      if (isImage) {
        return
      }

      const nextImage = document
        .getElementsByClassName(
          `ai-art-generation-${imageOrVideo}-${index}`,
        )
        .item(0)
      if (!nextImage) {
        return
      }
      const parent = nextImage.parentElement
      if (!parent) {
        return
      }

      const childX = nextImage.getBoundingClientRect().x
      const parentX = parent.getBoundingClientRect().x
      const childOffset = childX - parentX
      parent.scrollBy({
        behavior: "smooth",
        left: childOffset,
      })
    },
    [imageOrVideo],
  )

  useEffect(() => {
    scrollToImage(0)
  }, [scrollToImage, imageOrVideo])

  const typeContent = typeof imageOrVideo === "string"
  let display: "image" | "video" | "images" = "image"

  if (typeContent || imageOrVideo.length === 1) {
    if (isVideo) {
      display = "video"
    } else {
      display = "image"
    }
  } else {
    display = "images"
  }

  if (typeContent || imageOrVideo.length === 1) {
    if (display === "image") {
      return (
        <div
          className={clsx(
            "no-scrollbar flex justify-start overflow-y-scroll tablet:justify-center",
            "relative z-10 tablet:pb-[100px] tablet:pt-[140px]",
            "desktop:p-0",
          )}>
          <div
            className={clsx(
              "absolute left-0 top-0 z-10 hidden h-full w-full bg-cover bg-center bg-no-repeat tablet:block",
              "opacity-100 blur-3xl filter desktop:hidden",
            )}
            style={{
              backgroundImage: `url(${imageOrVideo})`,
            }}></div>
          <div
            className={clsx(
              "relative z-40",
              "overflow-hidden tablet:rounded-[19px]",
            )}>
            <img
              src={
                typeof imageOrVideo === "string"
                  ? imageOrVideo
                  : imageOrVideo[0]
              }
              alt={getFirstFiveWords(
                customPrompt &&
                  customPrompt.trim().length !== 0
                  ? customPrompt
                  : style,
              )}
              className={clsx(
                "tablet:max-h-[533px] tablet:max-w-[322px]",
                "tablet:rounded-[19px] desktop:max-h-[637px] desktop:max-w-[436px]",
              )}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div
          className={clsx(
            "no-scrollbar flex max-h-[100svh] justify-center overflow-y-scroll tablet:h-auto",
            "relative z-10 pb-[100px] pt-[140px]",
            "desktop:p-0",
          )}>
          <div
            className={clsx(
              "absolute left-0 top-0 z-10 h-full w-full overflow-hidden bg-color-black filter",
              "overflow-hidden desktop:hidden desktop:h-[857px] desktop:w-[775px] desktop:rounded-r-none",
            )}>
            <img
              src={previewImage}
              alt="preview image"
              className="absolute h-full w-full scale-110 object-cover opacity-30 blur-lg"
            />
          </div>
          <div
            className={clsx(
              "relative z-40",
              "overflow-hidden tablet:rounded-[19px]",
            )}>
            <video
              loop
              autoPlay
              playsInline
              preload="metadata"
              src={
                typeof imageOrVideo === "string"
                  ? imageOrVideo + "#t=0.001"
                  : imageOrVideo[0] + "#t=0.001"
              }
              ref={videoRef}
              poster={previewImage}
              className={clsx(
                "w-full tablet:max-h-[533px] tablet:max-w-[322px]",
                "tablet:rounded-[19px] desktop:max-h-[637px] desktop:max-w-[436px]",
              )}
            />
            <VideoProvider remember={[]} element={videoRef}>
              <div className="absolute bottom-0 left-0 z-50 w-full text-color-white">
                <Timeline />
              </div>
              <PlayWithProvider />
            </VideoProvider>
          </div>
        </div>
      )
    }
  } else {
    return (
      <div
        className={clsx(
          "no-scrollbar flex justify-center overflow-y-scroll bg-color-cell tablet:bg-[transparent]",
          "relative z-10",
          "tablet:px-[30px]",
          "desktop:p-0",
        )}>
        <div
          className={clsx(
            "fixed left-0 top-0 z-10 hidden h-full w-full bg-cover bg-center bg-no-repeat tablet:block",
            "opacity-100 blur-3xl filter desktop:hidden",
          )}
          style={{
            backgroundImage: `url(${imageOrVideo[0]})`,
          }}></div>
        <div
          className={clsx(
            "ah-[533px] relative w-[400px] object-cover tablet:rounded-xl",
            "tablet:h-[550px] tablet:w-full",
            "group desktop:h-full desktop:w-[550px]",
            "absolute inset-0 z-40 flex items-center justify-center",
          )}>
          <div
            onScroll={(event) => {
              const container = event.target
              if (container instanceof HTMLElement) {
                const snapIndex = Math.floor(
                  container.scrollLeft /
                    container.offsetWidth,
                )

                setOffset(snapIndex)
              }
            }}
            className={clsx(
              "no-scrollbar flex max-w-[400px] snap-x snap-mandatory gap-5 overflow-x-scroll transition-all",
              "tablet:rounded-xl",
            )}>
            {imageOrVideo.map((thumb, index) => (
              <img
                key={thumb}
                src={thumb}
                alt={getFirstFiveWords(
                  customPrompt &&
                    customPrompt.trim().length !== 0
                    ? customPrompt
                    : style,
                )}
                className={clsx(
                  "w-full max-w-[400px] shrink-0 snap-center object-cover tablet:rounded-xl",
                  `ai-art-generation-${imageOrVideo}-${index}`,
                )}
              />
            ))}
          </div>
          {/*BOOKMARK*/}
          {imageOrVideo.length > 1 && (
            <div
              className={clsx(
                "flex flex-row gap-[5px] rounded-[40px] bg-[#00000066]",
                "px-[10px] py-1 opacity-100 transition-all",
                "absolute bottom-2 left-1/2 -translate-x-1/2",
              )}>
              {imageOrVideo.map((_, index) => (
                <div
                  key={index}
                  onClick={() => scrollToImage(index)}
                  className={clsx(
                    "h-[6px] w-[6px] cursor-pointer rounded-full transition-all",
                    offset === index
                      ? "scale-[1.4] bg-primary-500"
                      : "scale-100 bg-color-white",
                  )}></div>
              ))}
            </div>
          )}

          {/*LEFT_RIGHT_BUTTONS */}
          {imageOrVideo.length > 1 && (
            <>
              <button
                disabled={offset === 0}
                onClick={() => scrollToImage(offset - 1)}
                className={clsx(
                  "absolute left-2 top-[calc(50%-12px)] z-50 flex h-6 w-6 items-center justify-center rounded-full",
                  " border border-[transparent] bg-[#00000052] opacity-0 backdrop-blur-[10px] transition-all",
                  " hover:border-color-white/60 disabled:border-[transparent]",
                  "hover:opacity-100 group-hover:opacity-100",
                )}>
                <img
                  src={assetUrl(
                    "/ai-tools/history/arrow-white.svg",
                  )}
                  alt="arrow"
                />
              </button>
              <button
                disabled={
                  offset === imageOrVideo.length - 1
                }
                onClick={() => scrollToImage(offset + 1)}
                className={clsx(
                  "absolute right-2 top-[calc(50%-12px)] z-50 flex h-6 w-6 items-center",
                  "justify-center rounded-full",
                  " border border-[transparent] bg-[#00000052] opacity-0",
                  "backdrop-blur-[10px] transition-all",
                  " hover:border-color-white/60 disabled:border-[transparent]",
                  "hover:opacity-100 group-hover:opacity-100",
                )}>
                <img
                  src={assetUrl(
                    "/ai-tools/history/arrow-white.svg",
                  )}
                  alt="arrow"
                  className="rotate-180"
                />
              </button>
            </>
          )}
        </div>
      </div>
    )
  }
}
