import { SizedSkeleton } from "@/comps/skeleton"
import { assetUrl } from "@/utils/cdn"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import type { GalleryRelatedResponse } from "pages/api/gallery-related-art"
import React, { useRef, useState } from "react"
import { useQuery } from "react-query"
import Masonry from "react-responsive-masonry"
import { SuggestionsSkeleton } from "./all-gallery"
import {
	GalleryPopupProps,
	getKeywords,
	getRelatedArt,
} from "./gallery-popup"

const RELATED_ART_LIMIT = 10
const skeletonList = [150, 200, 136, 120, 130, 180]

interface RelatedMasonryProps {
	initialProps: GalleryPopupProps
	setProps: (props: GalleryPopupProps) => void
}

export function RelatedMasonry(
	relatedProps: RelatedMasonryProps,
) {
	const { initialProps: props, setProps } = relatedProps

	const [offset, setOffset] = useState(0)

	const {
		id,
		showRelated,
		imageOrVideo,
		onClose,
		closeLink,
		handleRetry,
		model_id,
		showFor,
		likes,
		views,
	} = props

	const { t } = useTranslation()

	const scrollRef = useRef<HTMLDivElement>(null)

	const keywords = useQuery({
		queryKey: ["keywords", `id-${id}`],
		queryFn: getKeywords(id),
		staleTime: Infinity,
		enabled: showRelated,
	})

	const relatedArt = useQuery({
		queryKey: ["related", `art-${id}`],
		queryFn: getRelatedArt(id, RELATED_ART_LIMIT),
		staleTime: Infinity,
		enabled: showRelated,
	})

	function scrollToImage(index: number) {
		const nextImage = scrollRef.current
			?.getElementsByClassName(`gallery-${id}-${index}`)
			.item(0)

		if (!nextImage) {
			return
		}
		const parent = nextImage.parentElement
		if (!parent) {
			return
		}
		const childX = nextImage.getBoundingClientRect().x
		const parentX = parent.getBoundingClientRect().x
		const childOffset = childX - parentX
		parent.scrollBy({
			behavior: "smooth",
			left: childOffset,
		})
	}

	function handleSetProps(
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		single: GalleryRelatedResponse[number],
	) {
		e.preventDefault()
		const newUrl = `/ai-gallery/single/${single.id}`
		window.history.pushState({ path: newUrl }, "", newUrl)

		const isVideo =
			typeof imageOrVideo === "string" &&
			imageOrVideo.split(".").pop() === "mp4"

		setProps({
			onClose: onClose,
			closeLink: closeLink ?? `/ai-gallery`,
			id: single.id,
			imageOrVideo:
				typeof imageOrVideo === "string"
					? isVideo
						? single.object_urls[0].object + "#t=0.001"
						: single.object_urls[0].object
					: single.object_urls.map(
							(single) => single.object,
					  ),
			previewImage: single.object_urls[0].thumb,
			tool: single.tool,
			createdAt: single.accepted_at ?? new Date().getDate(),
			customPrompt: single.prompt ?? "",
			privacy: "public",
			prompt: single.prompt ?? "",
			promptName: single.style_name ?? "Custom",
			username: single.owner.username,
			followers: single.owner.followers,
			profile_pic: single.owner.profile_pic?.original,
			handleRetry: handleRetry,
			notShallow: true,
			showRelated: true,
			model_id,
			ownerId: single.owner.uid,
			showFor,
			likes,
			views,
		})
	}

	return (
		<>
			<div className="mx-auto h-[1px] w-[calc(100%-32px)] shrink-0 border-t border-color-separator"></div>
			{!keywords.isError && keywords.data?.length !== 0 && (
				<span className="px-4 text-[14px] font-500 tracking-normal text-blue-600">
					{t("txt_related_keywords")}
				</span>
			)}
			{(keywords.isFetching || keywords.isSuccess) &&
				keywords.data?.length !== 0 && (
					<div className="no-scrollbar flex h-[36px] w-full shrink-0 flex-row gap-[10px] overflow-x-scroll px-4">
						{keywords.isFetching && <SuggestionsSkeleton />}
						{keywords.isSuccess &&
							keywords.data.map((keyword) => (
								<a
									key={keyword}
									href={`/ai-gallery/${keyword}`}
									className={clsx(
										"flex h-full shrink-0 flex-row items-center",
										"justify-center rounded-[6px] bg-color-surface p-3",
										"text-[13px] font-400 tracking-wider text-blue-700",
									)}>
									{keyword}
								</a>
							))}
					</div>
				)}

			<span className="px-4 pt-3 text-[14px] font-500 tracking-normal text-blue-600">
				{t("txt_relevant_art")}
			</span>

			<Masonry
				columnsCount={3}
				gutter="8px"
				className="px-4">
				{relatedArt.isFetching &&
					skeletonList.map((height, index) => (
						<div
							key={index}
							style={{ height }}
							className="w-full overflow-hidden rounded-[12px] desktop:rounded-[14px]">
							<SizedSkeleton className="h-full w-full" />
						</div>
					))}
				{relatedArt.isSuccess &&
					relatedArt.data.map((single, index) => (
						<a
							key={`item-${single.id}`}
							href={`/ai-gallery/single/${single.id}`}
							onClick={(e) => handleSetProps(e, single)}
							className="cursor-pointer">
							{/*LEFT_RIGHT_BUTTONS */}
							{single.object_urls.length > 1 && (
								<>
									<button
										disabled={offset === 0}
										onClick={() => {
											scrollToImage(offset - 1)
										}}
										className={clsx(
											"absolute left-2 top-[calc(50%-12px)] flex h-6 w-6 items-center justify-center rounded-full",
											"border border-[transparent] bg-[#00000052] opacity-0 backdrop-blur-[10px] transition-all hover:border-color-white/60 disabled:border-[transparent]",
											"hover:opacity-0 group-hover:opacity-100",
											"z-[999] desktop:-left-[73px]",
										)}>
										<img
											src={assetUrl(
												"/ai-tools/history/arrow-white.svg",
											)}
											alt="arrow"
										/>
									</button>
									<button
										disabled={
											offset ===
											single.object_urls.length - 1
										}
										onClick={() => {
											scrollToImage(offset + 1)
										}}
										className={clsx(
											"absolute right-2 top-[calc(50%-12px)] flex h-6 w-6 items-center justify-center rounded-full",
											" border border-[transparent] bg-[#00000052] opacity-0 backdrop-blur-[10px] transition-all",
											" hover:border-color-white/60 disabled:border-[transparent]",
											"hover:opacity-0 group-hover:opacity-100",
											"z-[999] desktop:-right-[73px]",
										)}>
										<img
											src={assetUrl(
												"/ai-tools/history/arrow-white.svg",
											)}
											alt="arrow"
											className="rotate-180"
										/>
									</button>
								</>
							)}
							<div
								className={clsx(
									"w-full overflow-hidden object-cover",
									"rounded-[12px] desktop:rounded-[14px]",
								)}>
								<div
									onScroll={(event) => {
										const container = event.target
										if (container instanceof HTMLElement) {
											const snapIndex = Math.floor(
												container.scrollLeft /
													container.offsetWidth,
											)

											setOffset(snapIndex)
										}
									}}
									ref={scrollRef}
									className={clsx(
										"no-scrollbar top-0 flex h-full snap-x",
										"snap-mandatory flex-row gap-12 overflow-x-scroll transition-all",
									)}>
									{single.object_urls.map((image, index) =>
										single.media_type === "image" ? (
											<img
												key={index}
												src={image.thumb}
												alt={
													single.prompt
														? single.prompt
														: single.style ?? "Custom"
												}
												className={clsx(
													" h-full min-h-[130px] w-full shrink-0 snap-center",
													"rounded-[12px] object-cover desktop:min-h-[180px]",
													"desktop:rounded-[14px]",
													`gallery-${id}-${index}`,
												)}
											/>
										) : (
											<video
												key={index}
												src={image.object + "#t=0.001"}
												poster={image.thumb}
												playsInline
												loop
												preload="none"
												muted
												onPointerEnter={(e) => {
													e.currentTarget
														.play()
														.catch(console.error)
												}}
												onPointerLeave={(e) => {
													e.currentTarget.pause()
													e.currentTarget.currentTime = 0.001
												}}
												className={clsx(
													"h-full w-full object-cover",
													"min-h-[130px] rounded-[12px] desktop:min-h-[180px] desktop:rounded-[14px]",
												)}
											/>
										),
									)}
								</div>
							</div>
						</a>
					))}
			</Masonry>
		</>
	)
}
