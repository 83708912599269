import Bubble from "@/comps/bubble"
import Floating from "@/comps/floating"
import {
	CustomThemedResource,
	ThemedImage,
} from "@/comps/image"
import Text from "@/comps/text"

import {
	Desktop,
	onDesktop,
	TabletOrMobile,
} from "@/utils/breakpoints"
import { assetUrl } from "@/utils/cdn"

import { Box, FlexBox, FlexItem } from "react-styled-flex"
import styled, { css, keyframes } from "styled-components"

import clsx from "clsx"
import styles from "./styles.module.css"

const logos = [
	{
		images: [
			assetUrl("/icons/home/platforms/runner/pod-ml.webp"),
			assetUrl("/icons/home/platforms/runner/pod-cl.webp"),
			assetUrl("/icons/home/platforms/runner/pod-md.webp"),
			assetUrl("/icons/home/platforms/runner/pod-cd.webp"),
		],
		url: "https://podcastle.ai/",
	},
	{
		images: [
			assetUrl("/icons/home/platforms/runner/web-ml.webp"),
			assetUrl("/icons/home/platforms/runner/web-cl.webp"),
			assetUrl("/icons/home/platforms/runner/web-md.webp"),
			assetUrl("/icons/home/platforms/runner/web-cd.webp"),
		],
		url: "https://10web.io/",
	},
	{
		images: [
			assetUrl("/icons/home/platforms/runner/code-ml.webp"),
			assetUrl("/icons/home/platforms/runner/code-cl.webp"),
			assetUrl("/icons/home/platforms/runner/code-md.webp"),
			assetUrl("/icons/home/platforms/runner/code-cd.webp"),
		],
		url: "https://codesignal.com/",
	},
	{
		images: [
			assetUrl("/icons/home/platforms/runner/coin-ml.webp"),
			assetUrl("/icons/home/platforms/runner/coin-cl.webp"),
			assetUrl("/icons/home/platforms/runner/coin-md.webp"),
			assetUrl("/icons/home/platforms/runner/coin-cd.webp"),
		],
		url: "https://coinstats.app/",
	},
	{
		images: [
			assetUrl("/icons/home/platforms/runner/easy-ml.webp"),
			assetUrl("/icons/home/platforms/runner/easy-cl.webp"),
			assetUrl("/icons/home/platforms/runner/easy-md.webp"),
			assetUrl("/icons/home/platforms/runner/easy-cd.webp"),
		],
		url: "https://easydmarc.com/",
	},
	{
		images: [
			assetUrl(
				"/icons/home/platforms/runner/krisp-ml.webp",
			),
			assetUrl(
				"/icons/home/platforms/runner/krisp-cl.webp",
			),
			assetUrl(
				"/icons/home/platforms/runner/krisp-md.webp",
			),
			assetUrl(
				"/icons/home/platforms/runner/krisp-cd.webp",
			),
		],
		url: "https://krisp.ai/",
	},
	{
		images: [
			assetUrl("/icons/home/platforms/runner/sc-ml.webp"),
			assetUrl("/icons/home/platforms/runner/sc-cl.webp"),
			assetUrl("/icons/home/platforms/runner/sc-md.webp"),
			assetUrl("/icons/home/platforms/runner/sc-cd.webp"),
		],
		url: "https://smartclick.ai/",
	},
	{
		images: [
			assetUrl("/icons/home/platforms/runner/tcff-ml.webp"),
			assetUrl("/icons/home/platforms/runner/tcff-cl.webp"),
			assetUrl("/icons/home/platforms/runner/tcff-md.webp"),
			assetUrl("/icons/home/platforms/runner/tcff-cd.webp"),
		],
		url: "https://thecrowdfundingformula.com/",
	},
	{
		images: [
			assetUrl("/icons/home/platforms/runner/zero-ml.webp"),
			assetUrl("/icons/home/platforms/runner/zero-cl.webp"),
			assetUrl("/icons/home/platforms/runner/zero-md.webp"),
			assetUrl("/icons/home/platforms/runner/zero-cd.webp"),
		],
		url: "https://zerosystems.com/",
	},
]

const Colorful = styled.img`
	opacity: 0;
	transition: opacity 300ms;
	transition-timing-function: ease-in-out;

	&:hover {
		opacity: 1;
	}
`

const runnerAnim = keyframes`
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-1831px);
	}
`

const RunnerContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 42px;

	width: max-content;

	animation: ${runnerAnim} 30s linear -0s infinite normal forwards;

	&:hover {
		animation-play-state: paused;
	}

	height: 60px;

	zoom: 0.8;
	scale: 0.5;
	margin-bottom: 0px;

	${onDesktop(css`
		zoom: 1;
		scale: 0.6;
	`)}
`

const PartnersContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
	background: var(--color-cell);

	padding-top: 12px;

	${onDesktop(css`
		padding-top: unset;
		gap: 24px;
	`)}
`

const Anchor = styled.a`
	position: relative;
`

export const RunnerOld = () => {
	return (
		<PartnersContainer id="partners">
			<div className={styles["partners-title"]}>
				<FlexItem box center>
					<Bubble text="txt_our_partners" color="tan" />
				</FlexItem>
				<Desktop>
					<FlexBox alignItems="flex-end">
						<Text
							content="txt_published_on"
							size="44"
							weight="800"
						/>
						<Box marginBottom={16}>
							<img
								src={assetUrl(
									"/icons/home/platforms/trusted-dot.svg",
								)}
								alt="trusted dot icon"
							/>
						</Box>
					</FlexBox>
				</Desktop>
				<TabletOrMobile>
					<FlexBox gap={4} alignItems="flex-end">
						<Text
							content="txt_published_on"
							size="28"
							weight="800"
						/>
						<Box marginBottom={8}>
							<img
								src={assetUrl(
									"/icons/home/platforms/trusted-dot.svg",
								)}
								alt="trusted dot icon"
							/>
						</Box>
					</FlexBox>
				</TabletOrMobile>
			</div>
			<RunnerContainer>
				{logos.map(
					(
						{
							images: [
								greyLight,
								colorfulLight,
								greyDark,
								colorfulDark,
							],
							url,
						},
						i,
					) => (
						<Anchor key={i} href={url}>
							<CustomThemedResource
								source=""
								format="webp"
							/>
							<ThemedImage
								light={greyLight}
								dark={greyDark}
							/>
							<Floating position={{ top: 0, left: 0 }}>
								<Colorful
									data-hide-on-theme="dark"
									src={colorfulLight}
									alt="runner logo light"
								/>
								<Colorful
									data-hide-on-theme="light"
									src={colorfulDark}
									alt="runner logo light"
								/>
							</Floating>
						</Anchor>
					),
				)}
				{logos.map(
					(
						{
							images: [
								greyLight,
								colorfulLight,
								greyDark,
								colorfulDark,
							],
							url,
						},
						i,
					) => (
						<Anchor key={i} href={url}>
							<ThemedImage
								light={greyLight}
								dark={greyDark}
							/>
							<Floating position={{ top: 0, left: 0 }}>
								<Colorful
									data-hide-on-theme="dark"
									alt="runner logo light"
									src={colorfulLight}
								/>
								<Colorful
									data-hide-on-theme="light"
									src={colorfulDark}
									alt="runner logo dark"
								/>
							</Floating>
						</Anchor>
					),
				)}
				{logos.map(
					(
						{
							images: [
								greyLight,
								colorfulLight,
								greyDark,
								colorfulDark,
							],
							url,
						},
						i,
					) => (
						<Anchor key={i} href={url}>
							<ThemedImage
								light={greyLight}
								dark={greyDark}
							/>
							<Floating position={{ top: 0, left: 0 }}>
								<Colorful
									data-hide-on-theme="dark"
									src={colorfulLight}
									alt="runner logo dark"
								/>
								<Colorful
									data-hide-on-theme="light"
									src={colorfulDark}
									alt="runner logo dark"
								/>
							</Floating>
						</Anchor>
					),
				)}
				{logos.map(
					(
						{
							images: [
								greyLight,
								colorfulLight,
								greyDark,
								colorfulDark,
							],
							url,
						},
						i,
					) => (
						<Anchor key={i} href={url}>
							<ThemedImage
								light={greyLight}
								dark={greyDark}
							/>
							<Floating position={{ top: 0, left: 0 }}>
								<Colorful
									data-hide-on-theme="dark"
									src={colorfulLight}
									alt="runner logo dark"
								/>
								<Colorful
									data-hide-on-theme="light"
									src={colorfulDark}
									alt="runner logo dark"
								/>
							</Floating>
						</Anchor>
					),
				)}
			</RunnerContainer>
		</PartnersContainer>
	)
}

interface CompanyReference {
	name: string
	link: string
}

const companyReferences: CompanyReference[] = [
	{
		name: "backstage",
		link: "https://www.backstage.com",
	},
	{
		name: "forbes",
		link: "https://www.forbes.com/sites/cindygordon/2023/06/25/zoomerang-short-form-ai-video-platform-is-a-creative-innovation/amp/",
	},
	{
		name: "google",
		link: "https://developers.googleblog.com/2023/04/weareplay-meet-maria-annmaria-and-dennis-from-the-usa-more-stories-from-around-the-world.html",
	},
	{
		name: "imh",
		link: "https://influencermarketinghub.com",
	},
	{
		name: "meta",
		link: "https://developers.facebook.com/success-stories/zoomerang/",
	},
	{
		name: "msn",
		link: "https://www.msn.com/",
	},
	{
		name: "trend",
		link: "https://www.trendhunter.com",
	},
	{
		name: "yahoo",
		link: "https://finance.yahoo.com/",
	},
]
interface RunnerProps {
	component?: "runner"
	className?: string
	bgColor?: string
}

const Runner = (props: RunnerProps) => {
	const { className, bgColor } = props
	return (
		<div
			className={clsx(
				"w-full overflow-hidden ",
				className,
			)}>
			<PartnersContainer id="partners">
				<div className="relative">
					<div
						className={clsx(
							bgColor
								? `bg-color-${bgColor}`
								: "bg-color-cell",
							"absolute left-[calc(50%-50vw)] top-0 z-[1] h-[60px] desktop:w-[calc(50vw-587px)]",
						)}></div>
					<div
						className={clsx(
							bgColor
								? `bg-color-${bgColor}`
								: "bg-color-cell",
							"absolute right-[calc(50%-50vw)] top-0 z-[1] h-[60px] desktop:w-[calc(50vw-587px)]",
						)}></div>
					<div
						className={clsx(
							bgColor
								? `from-color-${bgColor}`
								: "from-color-cell",
							"pointer-events-none absolute left-[calc(50%-50vw)] top-0 z-[1] h-[60px] w-20 bg-gradient-to-r to-color-white/0 desktop:left-[calc(50%-587px)] desktop:w-40",
						)}></div>
					<div
						className={clsx(
							bgColor
								? `from-color-${bgColor}`
								: "from-color-cell",
							"pointer-events-none absolute right-[calc(50%-50vw)] top-0 z-[1] h-[60px] w-20 bg-gradient-to-l to-color-white/0 desktop:right-[calc(50%-587px)] desktop:w-40",
						)}></div>
					<RunnerContainer>
						{companyReferences.map(({ name, link }, i) => {
							const greyLight = assetUrl(
								"/published/" + name + "-grey-light.webp",
							)
							const greyDark = assetUrl(
								"/published/" + name + "-dark-grey.webp",
							)

							const colorsLight = assetUrl(
								"/published/" + name + "-light-colors.webp",
							)
							const colorsDark = assetUrl(
								"/published/" + name + "-dark-colors.webp",
							)

							return (
								<Anchor
									key={i}
									href={link}
									target="_blank"
									rel="noreferrer">
									<img
										data-hide-on-theme="dark"
										src={greyLight}
										alt="anchor icon"
									/>
									<img
										data-hide-on-theme="light"
										src={greyDark}
										alt="anchor icon"
									/>
									<Floating position={{ top: 0, left: 0 }}>
										<Colorful
											data-hide-on-theme="dark"
											src={colorsLight}
											alt="runner logo dark"
										/>
										<Colorful
											data-hide-on-theme="light"
											src={colorsDark}
											alt="runner logo dark"
										/>
									</Floating>
								</Anchor>
							)
						})}
						{companyReferences.map(({ name, link }, i) => {
							const greyLight = assetUrl(
								"/published/" + name + "-grey-light.webp",
							)
							const greyDark = assetUrl(
								"/published/" + name + "-dark-grey.webp",
							)

							const colorsLight = assetUrl(
								"/published/" + name + "-light-colors.webp",
							)
							const colorsDark = assetUrl(
								"/published/" + name + "-dark-colors.webp",
							)

							return (
								<Anchor key={i} href={link}>
									<img
										data-hide-on-theme="dark"
										src={greyLight}
										alt="anchor icon"
									/>
									<img
										data-hide-on-theme="light"
										src={greyDark}
										alt="anchor icon"
									/>
									<Floating position={{ top: 0, left: 0 }}>
										<Colorful
											data-hide-on-theme="dark"
											src={colorsLight}
											alt="runner logo dark"
										/>
										<Colorful
											data-hide-on-theme="light"
											src={colorsDark}
											alt="runner logo dark"
										/>
									</Floating>
								</Anchor>
							)
						})}
						{companyReferences.map(({ name, link }, i) => {
							const greyLight = assetUrl(
								"/published/" + name + "-grey-light.webp",
							)
							const greyDark = assetUrl(
								"/published/" + name + "-dark-grey.webp",
							)

							const colorsLight = assetUrl(
								"/published/" + name + "-light-colors.webp",
							)
							const colorsDark = assetUrl(
								"/published/" + name + "-dark-colors.webp",
							)

							return (
								<Anchor key={i} href={link}>
									<img
										data-hide-on-theme="dark"
										src={greyLight}
										alt="anchor icon"
									/>
									<img
										data-hide-on-theme="light"
										src={greyDark}
										alt="anchor icon"
									/>
									<Floating position={{ top: 0, left: 0 }}>
										<Colorful
											data-hide-on-theme="dark"
											src={colorsLight}
											alt="runner logo dark"
										/>
										<Colorful
											data-hide-on-theme="light"
											src={colorsDark}
											alt="runner logo dark"
										/>
									</Floating>
								</Anchor>
							)
						})}
						{companyReferences.map(({ name, link }, i) => {
							const greyLight = assetUrl(
								"/published/" + name + "-grey-light.webp",
							)
							const greyDark = assetUrl(
								"/published/" + name + "-dark-grey.webp",
							)

							const colorsLight = assetUrl(
								"/published/" + name + "-light-colors.webp",
							)
							const colorsDark = assetUrl(
								"/published/" + name + "-dark-colors.webp",
							)

							return (
								<Anchor key={i} href={link}>
									<img
										data-hide-on-theme="dark"
										src={greyLight}
										alt="anchor icon"
									/>
									<img
										data-hide-on-theme="light"
										src={greyDark}
										alt="anchor icon"
									/>
									<Floating position={{ top: 0, left: 0 }}>
										<Colorful
											data-hide-on-theme="dark"
											src={colorsLight}
											alt="runner logo dark"
										/>
										<Colorful
											data-hide-on-theme="light"
											src={colorsDark}
											alt="runner logo dark"
										/>
									</Floating>
								</Anchor>
							)
						})}
					</RunnerContainer>
				</div>
			</PartnersContainer>
		</div>
	)
}

export default Runner
