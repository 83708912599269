import {
	PaymentSessionData,
	PAYMENT_DATA_KEY,
	useAdjustLogger,
} from "@/utils/adjust"
import { assetUrl } from "@/utils/cdn"
import { useClientAuth } from "@/utils/client-auth"
import { redirectToLogin } from "@/utils/login-redirect"
import { NotificationContext } from "@/utils/notification"
import { useShortCut } from "@kartyom/shared-components"
import axios from "axios"
import clsx from "clsx"
import { createStore, values } from "idb-keyval"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import { useRouter } from "next/router"
import {
	PaymentStripeUrlRequest,
	PaymentStripeUrlResponse,
} from "pages/api/payment-stripe-url"
import { PricingPacksResponse } from "pages/api/pricing-packs"
import { useContext, useEffect, useState } from "react"
import { useQuery } from "react-query"
import priceJson from "statics/offering-prices.json"
import { v4 } from "uuid"
import { z } from "zod"
import Button, { GradientGrayscaleButton } from "./button"
import { CustomThemedResource } from "./image"
import { ErrorMessage } from "./message"
import { SquareSwitch } from "./toggle"

export const TOKENS_TO_VIDEOS_DIVISOR = 2.5

const PackageSchema = z.object({
	id: z.string(),
	title: z.string(),
	best_for_ai: z.boolean().default(false),
})

const FeatureSchema = z.object({
	title: z.string(),
	package_info: z.object({
		free: z.string().default("x"),
		basic: z.string().default("x"),
		advanced: z.string().default("x"),
		enterprise: z.string().default("x"),
	}),
	tooltip: z.string().optional(),
})

const SectionSchema = z.object({
	title: z.string(),
	features: z.array(FeatureSchema),
})

const PackageCompareSchema = z.object({
	packages: z.array(PackageSchema),
	sections: z.array(SectionSchema),
})

const AvailablePlansSchema = z.array(
	z.object({
		id: z.string(),
		title: z.string(),
		weight: z.number(),
	}),
)

export type AvailablePlans = z.infer<
	typeof AvailablePlansSchema
>

export const plansSchema = z.object({
	packages: z.array(PackageSchema),
	package_compare: PackageCompareSchema,
	available_plans: AvailablePlansSchema,
})

export type Plans = z.infer<typeof plansSchema>

// Prices Schema
const variantSchema = z.object({
	pack_id: z.string(),
	monthly_price: z.number(),
	yearly_price: z.number(),
	monthly_tokens: z.number(),
	yearly_tokens: z.number(),
	monthly_entitlement: z.string(),
	yearly_entitlement: z.string(),
})

const planSchema = z.object({
	free: z.object({
		id: z.string(),
		points: z.array(z.string()),
	}),
	basic: z.object({
		id: z.string(),
		points: z.array(z.string()),
		variants: z.array(variantSchema).optional(),
	}),
	advanced: z.object({
		id: z.string(),
		points: z.array(z.string()),
		variants: z.array(variantSchema).optional(),
	}),
})

export const pricesSchema = z.object({
	save_percent: z.number(),
	plans: planSchema,
	faq: z.array(
		z.object({
			q: z.string(),
			a: z.string(),
		}),
	),
	reviews: z
		.object({
			image: z.string(),
			name: z.string(),
			stars: z.number(),
			text: z.string(),
		})
		.array(),
})

export type Prices = z.infer<typeof pricesSchema>

const prices = pricesSchema.parse(priceJson)

export function fetchOfferingPrices() {
	return async function (): Promise<PricingPacksResponse> {
		const result = await axios
			.get<PricingPacksResponse>("/api/pricing-packs")
			.then((res) => res.data)

		return result
	}
}

const BASIC_ENTITLEMENT = "plan_basic_5"
const ADVANCED_ENTITLEMENT = "plan_advanced_10"

export const getCurrentPlan = (
	userEntitlements: string[],
) => {
	let plan = "FREE"
	userEntitlements.forEach((a) => {
		const isBasicUser = a === BASIC_ENTITLEMENT
		const isAdvancedUser = a === ADVANCED_ENTITLEMENT

		if (isAdvancedUser) {
			plan = "ADVANCED"
			return
		}

		if (isBasicUser) {
			plan = "PRO"
			return
		}
	})

	return plan
}

export const getCurrentPackId = (
	userEntitlements: string[],
) => {
	let packId = "free"

	const basicPackId = "basic_pro_tire_1"
	const advancedPackId = "pro_tire_1"

	userEntitlements.forEach((a) => {
		const isBasicUser = a === BASIC_ENTITLEMENT
		const isAdvancedUser = a === ADVANCED_ENTITLEMENT

		if (isAdvancedUser) {
			packId = advancedPackId
			return
		}

		if (isBasicUser) {
			packId = basicPackId
			return
		}
	})

	return { packId }
}

export function getBasicButtonText(currentPackId: string) {
	if (currentPackId === "basic_pro_tire_1") {
		return "txt_current_plan"
	} else if (currentPackId === "pro_tire_1") {
		return "txt_downgrade"
	} else {
		return "txt_upgrade"
	}
}

export function getAdvancedButtonText(
	currentPackId: string,
) {
	if (currentPackId === "basic_pro_tire_1") {
		return "txt_upgrade"
	} else if (currentPackId === "pro_tire_1") {
		return "txt_current_plan"
	} else {
		return "txt_upgrade"
	}
}

export interface SubscriptionPopupProps {
	isOpen: boolean
	close: () => void
	location?: string
	addToStorage?: () => Promise<void>
}
export function SubscriptionPopup(
	props: SubscriptionPopupProps,
) {
	const { isOpen, close, location, addToStorage } = props

	const { userInfo } = useClientAuth()

	const { packId: currentPackId } = userInfo
		? getCurrentPackId(userInfo.entitlements)
		: { packId: "free" }

	const [isYearly, setIsYearly] = useState(true)

	const { t } = useTranslation("common")
	const { notify } = useContext(NotificationContext)

	const router = useRouter()

	const plans = useQuery({
		queryKey: ["offering-prices"],
		queryFn: fetchOfferingPrices(),
		staleTime: Infinity,
		cacheTime: Infinity,
	})

	async function handlePurchaseClick(packId: string) {
		if (userInfo && !userInfo.isAnonymous) {
			fetchPaymentLink(packId)
		} else {
			redirectToLogin()
		}
	}

	async function fetchPaymentLink(packId: string) {
		try {
			const sessionId = v4()
			const path = window.location.pathname
			const search = window.location.search
			const payload: PaymentStripeUrlRequest = {
				location:
					location === undefined ? path + search : location,
				period: isYearly ? "yearly" : "monthly",
				adId: await getAdId(),
				sessionId,
				offering: packId,
			}

			const { url, currency, value } = await axios
				.post<PaymentStripeUrlResponse>(
					"/api/payment-stripe-url",
					payload,
				)
				.then((res) => res.data)

			logAdjust?.logEvent("initiate_checkout")

			addToStorage && (await addToStorage())

			const paymentData: PaymentSessionData = {
				currency,
				value,
				session: sessionId,
			}

			localStorage.setItem(
				PAYMENT_DATA_KEY,
				JSON.stringify(paymentData),
			)

			router.push(url)
		} catch (error) {
			console.error(error)
			notify(
				<ErrorMessage>
					{t("txt_could_not_purchase_now")}
				</ErrorMessage>,
			)
		}
		window.blur()
	}

	const logAdjust = useAdjustLogger()
	useEffect(() => {
		if (!logAdjust) return
		if (!isOpen) return

		logAdjust.logEvent("pricing_page_view")
	}, [isOpen, logAdjust])

	const yearlyPricePro =
		plans.data?.plans.basic.variants[0].periods.find(
			(a) => a.period === "yearly",
		)?.price || 0
	const yearlyPriceDividedPro = yearlyPricePro / 12
	const yearlyPriceFormattedPro = Math.floor(
		yearlyPriceDividedPro,
	)

	const yearlyPriceAdvanced =
		plans.data?.plans.advanced.variants[0].periods.find(
			(a) => a.period === "yearly",
		)?.price || 0
	const yearlyPriceDividedAdvanced =
		yearlyPriceAdvanced / 12
	const yearlyPriceFormattedAdvanced = Math.floor(
		yearlyPriceDividedAdvanced,
	)

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden"
		} else {
			document.body.style.overflow = "unset"
		}
	}, [isOpen])

	useShortCut({
		shortCut: "Escape",
		handler: () => {
			close()
		},
	})

	return (
		<div
			onClick={(event) => {
				if (event.target === event.currentTarget) {
					close()
				}
			}}
			className={clsx(
				isOpen ? "block" : "hidden",
				"fixed left-0 top-0 h-full w-full",
				"flex items-center justify-center",
				"z-[999] bg-color-popup",
			)}>
			<div
				className={clsx(
					"relative h-[100svh] w-full overflow-y-scroll bg-color-background tablet:h-auto tablet:w-[658px] tablet:overflow-visible tablet:py-4 desktop:w-[703px]",
					"flex flex-col items-center gap-4 tablet:rounded-[10px]",
				)}>
				<button
					className={clsx(
						"absolute -right-12 top-0",
						"h-[34px] w-[34px] rounded-full",
						"hidden items-center justify-center tablet:flex",
						"bg-color-cell hover:bg-blue-100",
						"transition-colors",
					)}
					onClick={close}>
					<img
						src={assetUrl("/general/close.svg")}
						alt="close popup"
					/>
				</button>
				<div
					className={clsx(
						"flex w-full flex-col items-center",
					)}>
					<div className="mt-4 flex w-full flex-row justify-between px-4 tablet:mt-0 tablet:justify-center">
						<p className="text-[20px] font-700 text-blue-900 tablet:text-[24px]">
							{t("txt_pricing_title")}
						</p>
						<button
							className={clsx(
								"h-[34px] w-[34px] rounded-full",
								"flex items-center justify-center",
								"bg-color-cell hover:bg-blue-100",
								"transition-colors tablet:hidden",
							)}
							onClick={close}>
							<img
								src={assetUrl("/general/close.svg")}
								alt="close popup"
							/>
						</button>
					</div>
					<div className="mt-2 flex w-full justify-start gap-[10px] px-4 tablet:justify-center tablet:px-0">
						<span
							className={clsx(
								isYearly
									? "text-blue-500"
									: "text-blue-900",
								"text-[18px] font-500 transition-colors",
							)}>
							{t("txt_monthly")}
						</span>
						<SquareSwitch
							onChange={setIsYearly}
							enabled={isYearly}
							activeColor="var(--color-green-600)"
						/>
						<span
							className={clsx(
								isYearly
									? "text-blue-900"
									: "text-blue-500",
								"text-[18px] font-500 transition-colors",
							)}>
							{t("txt_yearly")}
						</span>
						<span
							className={clsx(
								"text-[18px] font-500 transition-colors",
								isYearly
									? "text-blue-900"
									: "text-blue-500",
							)}>
							{t("fs_save_percent", {
								percent: String(plans.data?.save_percent),
							})}
						</span>
					</div>

					<div className="mb-4 mt-4 flex w-full flex-col items-center justify-center gap-6 px-4 tablet:mb-0 tablet:px-5 desktop:flex-row desktop:gap-[27px]">
						{/*PRIME */}

						<div
							className={clsx(
								"flex w-full flex-col overflow-hidden rounded-[8px] border-2 border-color-separator",
								"tablet:flex-row desktop:h-[468px] desktop:w-[310px] desktop:flex-col",
							)}>
							<div className="flex w-full flex-1 flex-col justify-between bg-color-surface-cell p-[18px]">
								<div className="flex flex-col">
									<p className="text-[24px] font-600 text-blue-900">
										{"Pro + AI"}
									</p>
									<p className="font-300 text-[14px] text-blue-600">
										{"For pro video creation"}
									</p>
									<div className="relative mt-3 h-[50px] w-full overflow-hidden">
										<div
											className={clsx(
												"absolute inset-0",
												"flex h-[50px] w-full flex-row items-end justify-start gap-[2px]",
												isYearly && "-top-16",
												"transition-all duration-500",
											)}>
											<span
												className={clsx(
													"font-700 text-blue-900",
													"text-[38px]",
												)}>
												$
												{String(
													plans.data?.plans.basic.variants[0].periods.find(
														(a) => a.period === "monthly",
													)?.price,
												).slice(0, -2)}
												.
												<small>
													{String(
														plans.data?.plans.basic.variants[0].periods.find(
															(a) => a.period === "monthly",
														)?.price,
													).slice(-2)}
												</small>
											</span>
											<span className="font-300 mb-[10px] text-[14px] lowercase text-blue-600">
												/{t("txt_month")}
											</span>
										</div>

										<div
											className={clsx(
												"absolute inset-0",
												"flex h-[50px] w-full flex-row items-end justify-start gap-[2px]",
												!isYearly && "top-16",
												"transition-all duration-500",
											)}>
											<span
												className={clsx(
													"font-700 text-blue-900",
													"text-[38px]",
												)}>
												$
												{String(
													yearlyPriceFormattedPro,
												).slice(0, -2).length !== 0
													? String(
															yearlyPriceFormattedPro,
													  ).slice(0, -2)
													: 0}
												.
												<small>
													{String(
														yearlyPriceFormattedPro,
													).slice(-2)}
												</small>
											</span>
											<span className="font-300 mb-[10px] text-[14px] lowercase text-blue-600">
												/{t("txt_month")}*
											</span>
										</div>
									</div>
								</div>

								<div className="flex w-full">
									<GradientGrayscaleButton
										grayscale
										disabled={
											getBasicButtonText(currentPackId) ===
											"txt_current_plan"
										}
										onClick={() =>
											handlePurchaseClick(
												plans.data?.plans.basic.variants[0]
													.pack_id || "",
											)
										}
										className="flex h-[52px] w-full items-center justify-center !font-500 disabled:!text-color-inactive-text"
										text={getBasicButtonText(currentPackId)}
									/>
								</div>
							</div>
							<div className="h-[1px] w-full bg-blue-100 tablet:h-full tablet:w-[1px] desktop:h-[1px] desktop:w-full" />
							<div className="flex flex-col gap-2 bg-blue-50 p-4 tablet:w-[310px] tablet:gap-4 desktop:h-[160px] desktop:w-full desktop:gap-1">
								{prices.plans.basic.points.map((point) => {
									return (
										<div
											className="flex items-center gap-[10px] "
											key={point}>
											<CustomThemedResource
												format="svg"
												source="/pricing/check"
											/>
											<span className="text-[14px] font-400 text-blue-800">
												{point}
											</span>
										</div>
									)
								})}
							</div>
						</div>

						{/* Enterprise */}

						<div
							className={clsx(
								"relative flex w-full flex-col rounded-[8px]",
								"tablet:flex-row desktop:h-[468px] desktop:w-[310px] desktop:flex-col",
							)}>
							<div
								style={{
									background:
										"linear-gradient(120deg, rgba(134, 54, 248, 1), rgba(240, 32, 179, 1), rgba(248, 71, 94, 1), rgba(255, 148, 33, 1) )",
								}}
								className="absolute left-[-2px] top-[-2px] h-[calc(100%+4px)] w-[calc(100%+4px)] rounded-[10px]"></div>

							<div className="z-10 flex w-full flex-1 flex-col justify-between rounded-t-[8px] bg-color-surface-cell p-[18px] tablet:rounded-l-[8px] tablet:rounded-r-[0px] desktop:rounded-b-[0px] desktop:rounded-t-[8px]">
								<div className="flex flex-col">
									<p className="text-[24px] font-600 text-blue-900">
										{"Advanced + AI"}
									</p>
									<p className="font-300 text-[14px] text-blue-600">
										{t("txt_pro_subtitle")}
									</p>
									<div className="relative mt-3 h-[50px] w-full overflow-hidden">
										<div
											className={clsx(
												"absolute inset-0",
												"flex h-[50px] w-full flex-row items-end justify-start gap-[2px]",
												isYearly && "-top-16",
												"transition-all duration-500",
											)}>
											<span
												className={clsx(
													"font-700 text-blue-900",
													"text-[38px]",
												)}>
												$
												{String(
													plans.data?.plans.advanced.variants[0].periods.find(
														(a) => a.period === "monthly",
													)?.price,
												).slice(0, -2)}
												.
												<small>
													{String(
														plans.data?.plans.advanced.variants[0].periods.find(
															(a) => a.period === "monthly",
														)?.price,
													).slice(-2)}
												</small>
											</span>
											<span className="font-300 mb-[10px] text-[14px] lowercase text-blue-600">
												/{t("txt_month")}
											</span>
										</div>

										<div
											className={clsx(
												"absolute inset-0",
												"flex h-[50px] w-full flex-row items-end justify-start gap-[2px]",
												!isYearly && "top-16",
												"transition-all duration-500",
											)}>
											<span
												className={clsx(
													"font-700 text-blue-900",
													"text-[38px]",
												)}>
												$
												{String(
													yearlyPriceFormattedAdvanced,
												).slice(0, -2).length !== 0
													? String(
															yearlyPriceFormattedAdvanced,
													  ).slice(0, -2)
													: 0}
												.
												<small>
													{String(
														yearlyPriceFormattedAdvanced,
													).slice(-2)}
												</small>
											</span>
											<span className="font-300 mb-[10px] text-[14px] lowercase text-blue-600">
												/{t("txt_month")}*
											</span>
										</div>
									</div>
								</div>
								<div className="mt-8 flex w-full tablet:mt-12 desktop:mt-0">
									<Button
										gradient
										disabled={
											getAdvancedButtonText(
												currentPackId,
											) === "txt_current_plan"
										}
										onClick={() =>
											handlePurchaseClick(
												plans.data?.plans.advanced
													.variants[0].pack_id || "",
											)
										}
										className="flex h-[52px] w-full items-center justify-center !font-500"
										text={getAdvancedButtonText(
											currentPackId,
										)}
									/>
								</div>
							</div>
							<div className="z-10 h-[1px] w-full bg-blue-100 tablet:h-full tablet:w-[1px] desktop:h-[1px] desktop:w-full" />
							<div className="z-10 flex flex-col gap-2 rounded-b-[8px] bg-blue-50 p-4 tablet:w-[310px] tablet:gap-4 tablet:rounded-l-none tablet:rounded-r-[8px] desktop:h-[160px] desktop:w-full desktop:gap-1 desktop:rounded-b-[8px] desktop:rounded-t-[0px]">
								{prices.plans.advanced.points.map(
									(point) => {
										return (
											<div
												className="flex items-center gap-[10px] "
												key={point}>
												<CustomThemedResource
													format="svg"
													source="/pricing/check"
												/>
												<span className="text-[14px] font-400 text-blue-800">
													{point}
												</span>
											</div>
										)
									},
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="flex w-full items-center justify-between px-4 tablet:px-7">
					<p
						className={clsx(
							"text-[16px] font-400 text-blue-500 transition-opacity",
							isYearly ? "opacity-100" : "opacity-0",
						)}>
						*{t("txt_billed_yearly")}
					</p>

					<Link href="/pricing">
						<a className="text-[17px] font-400 text-blue-700 transition-all hover:underline">
							{"Compare plans"}
						</a>
					</Link>
				</div>
			</div>
		</div>
	)
}

const adjustStoreSchema = z.object({
	at: z.object({
		a: z.string(),
	}),
})

export async function getAdId(): Promise<string | null> {
	const adjustStore = createStore("adjust-sdk", "as")
	const [storeValue] = await values<unknown>(adjustStore)
	if (!storeValue) {
		// For IOS devices

		const localStorageValue =
			localStorage.getItem("adjust-sdk.as")

		if (!localStorageValue) {
			return null
		}

		try {
			const parsedLocalStorageValue = JSON.parse(
				localStorageValue,
			)
			if (
				Array.isArray(parsedLocalStorageValue) &&
				parsedLocalStorageValue.length > 0
			) {
				const localParsed = adjustStoreSchema.parse(
					parsedLocalStorageValue[0],
				)
				return localParsed.at.a
			} else {
				return null
			}
		} catch (e) {
			console.error("Error parsing local storage value", e)
			return null
		}
	}

	try {
		const parsedStoreValue =
			adjustStoreSchema.parse(storeValue)
		return parsedStoreValue.at.a
	} catch (e) {
		console.error("Error parsing store value", e)
		return null
	}
}
